import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import RoutesPrivate from "./private";

import Login from "../Forms/login.js";
import Ajuda from "../pages/Ajuda";
import Home from "../pages/Home";
import Versao from "../pages/Versao";

// Diagnostico
import CadastroDiagnostico from "../Forms/Diagnosticos/index.js";
import Diagnosticos from "../pages/Diagnosticos";

//Consultório
import CancelarAgendamento from "../Forms/Agendas/cancelarAgendamento.js";
import CadastroAgendaConsultorio from "../Forms/Agendas/Consultorio.js";
import AgendaConsultorio from "../pages/Agendas/agendaConsultorio.js";

// Eventos Cancelados
import EventosCancelados from "../pages/EventosCancelados/index.js";
import EventosCanceladosView from "../pages/EventosCancelados/view.js";

// Lista Tags
import CadastroListaTags from "../Forms/ListasTags/";
import listaTags from "../pages/ListasTags/";

// Lista Procedimentos
import CadastroListaProcedimentos from "../Forms/ListasProcedimentos/";
import listaProcedimentos from "../pages/ListasProcedimentos/";

// Guias
import Guias from "../pages/ListaGuias";

// Lista Espera
import CadastroListaEspera from "../Forms/ListasEspera/";
import ListaEspera from "../pages/ListasEspera/";

//Pessoas
import CadastroPessoa from "../Forms/Pessoas/";
import CadastroNotaFiscal from "../Forms/Pessoas/Notas/nota.js";
import Pessoas from "../pages/Pessoas";
import NotasFiscais from "../pages/Pessoas/notasFiscais";

import CadastroFornecedor from "../Forms/Fornecedores/";
import Fornecedores from "../pages/Fornecedor";

//Financeiro
import CadastroCaixa from "../Forms/Financeiro/caixa";
import CadastroContasPagar from "../Forms/Financeiro/contasPagar";
import CadastroContasReceber from "../Forms/Financeiro/contasReceber.js";
import CadastroFormaPagamento from "../Forms/Financeiro/formasPagamento.js";
import CadastroMovimento from "../Forms/Financeiro/movimento";
import Pagamento from "../Forms/Financeiro/pagamento.js";
import ContasReceber from "../pages/Financeiro/contasReceber";
import FormasPagamento from "../pages/Financeiro/formasPagamento.js";
import Financeiro from "../pages/Financeiro/index";

//Usuário
import CadastroUsuario from "../Forms/usuarios.js";
import Usuarios from "../pages/Usuarios";

//Configurações
import Configuracoes from "../pages/Configuracoes";

//Perfil
import CadastroPerfil from "../Forms/perfis.js";
import Perfis from "../pages/Perfis";

// Mensagens usuários
import Quadro from "../components/Quadro/index";
import PagamentoContasPagar from "../Forms/Financeiro/pagamentoContasPagar";
import CaixasFinanceiro from "../pages/Financeiro/caixas";
import ContasAPagarFinanceiro from "../pages/Financeiro/contasAPagar";
import ContasAReceberFinanceiro from "../pages/Financeiro/contasAReceber";
import FormasDePagamentoFinanceiro from "../pages/Financeiro/formasDePagamento";
import MovimentacoesFinanceiro from "../pages/Financeiro/movimentacoes";
import ResumoFinanceiro from "../pages/Financeiro/resumo";
import MensagensUsuarios from "../pages/MensagensUsuarios";
import Comissoes from "../pages/Financeiro/comissoes";
import PagamentoComissao from "../Forms/Financeiro/pagamentoComissao";

export default function () {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login" component={Login} />

        {/* Pages */}
        <RoutesPrivate path="/" exact component={Home} />
        <RoutesPrivate
          path="/consultorio/agenda"
          exact
          component={AgendaConsultorio}
        />
        <RoutesPrivate
          path="/listaEspera/:tipo"
          exact
          component={ListaEspera}
        />
        <RoutesPrivate path="/listaTag/:tipo" exact component={listaTags} />
        <RoutesPrivate path="/guias/:tipo" exact component={Guias} />
        <RoutesPrivate
          path="/eventosCancelados/:tipo"
          exact
          component={EventosCancelados}
        />
        <RoutesPrivate path="/listaTags/:tipo" exact component={listaTags} />
        <RoutesPrivate path="/listaProcedimentos/:tipo" exact component={listaProcedimentos} />
        <RoutesPrivate
          path="/eventosCancelados/view/:tipo/:id"
          exact
          component={EventosCanceladosView}
        />
        <RoutesPrivate path="/pessoas" exact component={Pessoas} />
        <RoutesPrivate
          path="/pessoas/notasFiscais/:id_pessoa"
          exact
          component={NotasFiscais}
        />
        <RoutesPrivate path="/diagnosticos" exact component={Diagnosticos} />
        <RoutesPrivate
          path="/financeiro/contasReceber"
          exact
          component={ContasReceber}
        />
        <RoutesPrivate
          path="/formasPagamento"
          exact
          component={FormasPagamento}
        />
        <RoutesPrivate path="/usuarios" exact component={Usuarios} />
        <RoutesPrivate path="/perfis" exact component={Perfis} />
        <RoutesPrivate path="/configuracoes" exact component={Configuracoes} />
        <RoutesPrivate path="/versao" exact component={Versao} />
        <RoutesPrivate path="/ajuda" exact component={Ajuda} />

        {/* Cadastros (Forms) */}

        {/* Usuário */}
        <RoutesPrivate
          path="/cadastro_usuario"
          exact
          component={CadastroUsuario}
        />
        <RoutesPrivate
          path="/editar_usuario/:id"
          exact
          component={CadastroUsuario}
        />

        {/* Quadro */}
        <RoutesPrivate
          path="/consultorio/quadro/:dataParam"
          exact
          component={Quadro}
        />

        {/* Perfil */}
        <RoutesPrivate
          path="/cadastro_perfil"
          exact
          component={CadastroPerfil}
        />
        <RoutesPrivate
          path="/editar_perfil/:id"
          exact
          component={CadastroPerfil}
        />

        {/* Consultório */}
        <RoutesPrivate
          path="/consultorio/agenda/cadastro/:data_inicio/:data_fim/:id_profissional/:dataRedirect"
          exact
          component={CadastroAgendaConsultorio}
        />
        <RoutesPrivate
          path="/consultorio/agenda/editar/:id"
          exact
          component={CadastroAgendaConsultorio}
        />

        {/* Agendas */}
        <RoutesPrivate
          path="/cancelarAgendamento/:tipo/:id/:dataRedirect"
          exact
          component={CancelarAgendamento}
        />

        {/* Lista de espera */}
        <RoutesPrivate
          path="/listaEspera/cadastro/:tipo"
          exact
          component={CadastroListaEspera}
        />
        <RoutesPrivate
          path="/listaEspera/editar/:tipo/:id"
          exact
          component={CadastroListaEspera}
        />

        {/* Lista de Tags */}
        <RoutesPrivate
          path="/listaTags/cadastro/:tipo"
          exact
          component={CadastroListaTags}
        />
        <RoutesPrivate
          path="/listaTags/editar/:tipo/:id"
          exact
          component={CadastroListaTags}
        />
        
        {/* Lista de Procedimentos */}
        <RoutesPrivate
          path="/listaProcedimentos/cadastro/:tipo"
          exact
          component={CadastroListaProcedimentos}
        />
        <RoutesPrivate
          path="/listaProcedimentos/editar/:tipo/:id"
          exact
          component={CadastroListaProcedimentos}
        />

        {/* Pessoa */}
        <RoutesPrivate path="/pessoas/cadastro" component={CadastroPessoa} />
        <RoutesPrivate path="/pessoas/editar/:id" component={CadastroPessoa} />
        {/* Notas Fiscais */}
        <RoutesPrivate
          path="/pessoas/notasFiscais/cadastro/:id_pessoa"
          component={CadastroNotaFiscal}
        />
        <RoutesPrivate
          path="/pessoas/notasFiscais/editar/:id_pessoa/:id_nota"
          component={CadastroNotaFiscal}
        />

        <RoutesPrivate path="/fornecedores" exact component={Fornecedores} />
        <RoutesPrivate path="/fornecedores/cadastro" component={CadastroFornecedor} />
        <RoutesPrivate path="/fornecedores/editar/:id" component={CadastroFornecedor} />

        {/* Diagnósticos */}
        <RoutesPrivate
          path="/diagnosticos/cadastro/"
          exact
          component={CadastroDiagnostico}
        />
        <RoutesPrivate
          path="/diagnosticos/editar/:id"
          exact
          component={CadastroDiagnostico}
        />
        <RoutesPrivate
          path="/diagnosticos/cadastro/withCli/:idCliente"
          exact
          component={CadastroDiagnostico}
        />

        {/* Forma de Pagamento */}
        <RoutesPrivate
          path="/formasPagamento/cadastro"
          exact
          component={CadastroFormaPagamento}
        />
        <RoutesPrivate
          path="/formasPagamento/editar/:id"
          exact
          component={CadastroFormaPagamento}
        />

        {/* Resumo Financeiro */}
        <RoutesPrivate
          path="/financeiro/resumo"
          exact
          component={ResumoFinanceiro}
        />
        <RoutesPrivate
          path="/financeiro/movimentacoes"
          exact
          component={MovimentacoesFinanceiro}
        />
        <RoutesPrivate
          path="/financeiro/contas-a-receber"
          exact
          component={ContasAReceberFinanceiro}
        />
        <RoutesPrivate
          path="/financeiro/contas-a-pagar"
          exact
          component={ContasAPagarFinanceiro}
        />
        <RoutesPrivate
          path="/financeiro/caixas"
          exact
          component={CaixasFinanceiro}
        />
        <RoutesPrivate
          path="/financeiro/formas-de-pagamento"
          exact
          component={FormasDePagamentoFinanceiro}
        />
        <RoutesPrivate
          path="/financeiro/comissoes"
          exact
          component={Comissoes}
        />

        <RoutesPrivate
          path="/financeiro/comissao/pagamento/:id"
          exact
          component={PagamentoComissao}
        />

        {/* Financeiro */}
        <RoutesPrivate path="/financeiro" exact component={Financeiro} />
        <RoutesPrivate
          path="/financeiro/caixas/cadastro"
          component={CadastroCaixa}
        />
        <RoutesPrivate
          path="/financeiro/caixas/editar/:id"
          component={CadastroCaixa}
        />
        <RoutesPrivate
          path="/financeiro/movimentacoes/cadastro"
          component={CadastroMovimento}
        />
        <RoutesPrivate
          path="/financeiro/movimentacoes/editar/:id"
          component={CadastroMovimento}
        />

        {/* Contas Receber */}
        <RoutesPrivate
          path="/financeiro/contasReceber/cadastro"
          exact
          component={CadastroContasReceber}
        />
        <RoutesPrivate
          path="/financeiro/contasReceber/cadastro/:id_pessoa/:tipo"
          exact
          component={CadastroContasReceber}
        />
        <RoutesPrivate
          path="/financeiro/contasReceber/editar/:id"
          exact
          component={CadastroContasReceber}
        />
        <RoutesPrivate
          path="/financeiro/contasReceber/pagamento/:id"
          exact
          component={Pagamento}
        />

        {/* Contas Pagar */}
        <RoutesPrivate
          path="/financeiro/contasPagar/cadastro"
          exact
          component={CadastroContasPagar}
        />
        <RoutesPrivate
          path="/financeiro/contasPagar/editar/:id"
          exact
          component={CadastroContasPagar}
        />
        <RoutesPrivate
          path="/financeiro/contasPagar/pagamento/:id"
          exact
          component={PagamentoContasPagar}
        />

        {/* Mensagens usuários */}
        <RoutesPrivate
          path="/mensagensUsuarios"
          exact
          component={MensagensUsuarios}
        />
      </Switch>
    </BrowserRouter>
  );
}
