import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Button, Card, Col, Alert, Row, Badge } from "react-bootstrap";
import { Formik, Form, ErrorMessage } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { Creators as ContasPagarActions } from "../../Store/Controls/financeiros/contasPagar";
import "../../Global/styleGeral.css";
import { AlertGeral, requisitarAPI } from "../../components/AlertGeral";
import api from "../../services/api";
import MainMenu from "../../components/MainMenu";
import FieldValidate from "../../components/Form/FieldValidate.js";
import { DatePickerField } from "../../components/Form/DatePicker.js";
import { SelectComponent } from "../../components/Form/Select.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { dateToView, dateToField, formatMoeda } from "../../Global/funcoes";
import { MoedaFormat } from "../../components/Form/MoedaFormat.js";
import {
  getFormaPagamentoForSelect,
  getConfig,
  getCaixasForSelect,
} from "../../Global/chamadasAPI";
import Spinner from "../../components/Spiner";
import ModalRestantePagamento from "./modalRestantePagamento";

const PagamentoComissao = ({ history, match }) => {
  const [comissao, setComissao] = useState({});
  const [infosPanel, setInfosPanel] = useState([{}]);
  const [formasPagamento, setFormasPagamento] = useState([{}]);
  const [retorno, setRetorno] = useState({});
  const [loading, setLoading] = useState(true);
  const [showModalPagamentoRestante, setShowModalPagamentoRestante] =
    useState(false);
  const [config, setConfig] = useState({
    tempoagenda: "45",
    modelo_financeiro: "A",
    editando: false,
  });
  const [caixas, setCaixas] = useState([{}]);


  async function getDadosSelects() {
    setFormasPagamento(await getFormaPagamentoForSelect());
    setCaixas(await getCaixasForSelect());
  }

  async function getConfiguracoes() {
    const config = await getConfig();
    setConfig(config);
  }

  async function getComissao() {
    let ret = await requisitarAPI(
      () => api.get("comissoes/"+match.params.id),
      "Falha ao cadastrar comissão"
    );
        
    if (!ret.erro) {
      
      let comi = ret.response.data;

      if (comi.status !== "P") {
        comi.pagamento_liquido = comi.valor_comissao;
        comi.pagamento_data = new Date();
      } else comi.pagamento_data = dateToField(comi.pagamento_data);

    let infosPanel = Object.assign({}, comi);

    infosPanel.data_vencimento = dateToView(infosPanel.data_vencimento);
    infosPanel.valor_comissao = formatMoeda(infosPanel.valor_comissao);

    setInfosPanel(infosPanel);
    setComissao(comi);
    }
  }

  useEffect(() => {
    setLoading(true);
    getDadosSelects();
    getComissao();

      // return history.push("/financeiro/contas-a-pagar");
      
    getConfiguracoes();
    setLoading(false);
  }, [match.params.id]);

  const schema = Yup.object().shape({
    pagamento_data: Yup.date().typeError("Informe uma data válida"),
    pagamento_liquido: Yup.number().required("Informe o Valor Liquído"),
  });

  const handleSubmit = async (values) => {
    setLoading(true);

    values.status = "P";
    let ret = await requisitarAPI(
      () => api.put("comissoes/" + match.params.id, values),
      "Falha ao editar Conta a Pagar"
    );

    setRetorno(ret);

    if (!ret.erro) {
      return history.push("/financeiro/comissoes");
    }

    setLoading(false);

    // if (!ret.erro) {
    //   if (comissao.valor > values.pagamento_liquido) {
    //     comissao.data_vencimento = new Date();
    //     comissao.valor = comissao.valor - values.pagamento_liquido;
    //     setcomissao(comissao);

    //     setShowModalPagamentoRestante(true);
    //   } else {
    //     if (config.modelo_financeiro === "M") {
    //       ret = await requisitarAPI(
    //         () => api.get("contasPagar?id_pessoa=" + values.id_pessoa),
    //         "Falha ao obter dados do servidor"
    //       );
    //       setRetorno(ret);
    //       if (!ret.erro) {
    //         if (ret.response.data.length === 0) {
    //           history.push(
    //             "/financeiro/contasPagar/cadastro/" + values.id_pessoa + "/M"
    //           );
    //         } else {
    //           history.push("/financeiro/contas-a-pagar");
    //         }
    //       }
    //     } else {
    //       history.push("/financeiro/contas-a-pagar");
    //     }
    //   }
    // }
  };

  const calcValorLiquido = (val, Field) => {
    let valor = 0;
    if (typeof valor !== "undefined") valor = val;

    if (Field.name === "pagamento_desconto")
      comissao.pagamento_desconto = valor;
    else comissao.pagamento_acrescimo = valor;

    if (typeof comissao.pagamento_desconto === "undefined")
      comissao.pagamento_desconto = 0;

    if (typeof comissao.pagamento_acrescimo === "undefined")
      comissao.pagamento_acrescimo = 0;

    comissao.pagamento_liquido =
      comissao.valor +
      comissao.pagamento_acrescimo -
      comissao.pagamento_desconto;

    return comissao.pagamento_liquido;
  };

  return (
    <>
      <MainMenu navigation={history} />

      <ModalRestantePagamento
        show={showModalPagamentoRestante}
        setShow={setShowModalPagamentoRestante}
        contaReceber={comissao}
        history={history}
      />
      <div className="titulo-pagina">
        Pagamento Comissão Pagar
        <Button
          variant="voltar"
          onClick={() => {
            history.push("/financeiro/comissoes");
          }}
        >
          <FontAwesomeIcon className="icone" icon={faChevronLeft} /> Voltar para comissões
        </Button>
      </div>

      <div className="container-principal">
        <Card bg="light" style={{ borderColor: "#bdbdbd" }}>
          <Card.Body>
            <Card.Title>
              <h4>
                <b>{infosPanel.descricao}</b>{" "}
                <Badge variant="danger">{infosPanel.data_vencimento}</Badge>
              </h4>
            </Card.Title>
            <Card.Subtitle
              className="mb-2 text-muted"
              style={{ marginTop: "10px" }}
            >
              <b>Profissional:</b> {infosPanel.profissional && infosPanel.profissional.nome}
              <Row>
                <Col><b>Procedimento:</b> {infosPanel.profissional && infosPanel.procedimento.description}</Col>
              </Row>
            </Card.Subtitle>
            <Card.Text style={{ marginTop: "20px" }}>
              <Row>
                <Col>
                  <h3>
                    Valor:{" "}
                    <label style={{ color: "green", fontSize: "25px" }}>
                      {infosPanel.valor_comissao}
                    </label>
                  </h3>
                </Col>
              </Row>
            </Card.Text>
          </Card.Body>
        </Card>

        <Formik
          enableReinitialize
          initialValues={comissao}
          onSubmit={handleSubmit}
          validationSchema={schema}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ errors }) => (
            <Form>
              <div>
                <Row>
                  <Col className="form-group">
                    <label className="obrigatorio">Data de Pagamento *</label>
                    <br />
                    <DatePickerField
                      withPortal
                      placeholderText="Informe a data de Pagamento*"
                      name="pagamento_data"
                      className="form-control"
                    />
                    <ErrorMessage
                      name={"pagamento_data"}
                      component="div"
                      style={{ fontSize: "16px", color: "red" }}
                    />
                  </Col>

                  <Col className="form-group">
                    <label>Forma Pagamento</label>
                    <FieldValidate
                      name="pagamento_id_forma_pagamento"
                      placeholderText="Informe a Forma de Pagamento"
                      component={SelectComponent}
                      options={formasPagamento}
                      errors={errors}
                      noClass
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xs={4} className="form-group">
                    <label>Desconto</label>
                    <FieldValidate
                      onCalc={calcValorLiquido}
                      targetFieldCalc="pagamento_liquido"
                      component={MoedaFormat}
                      name="pagamento_desconto"
                      placeholder="Digite o Desconto"
                      errors={errors}
                    />
                  </Col>
                  <Col xs={4} className="form-group">
                    <label>Acréscimo</label>
                    <FieldValidate
                      onCalc={calcValorLiquido}
                      targetFieldCalc="pagamento_liquido"
                      component={MoedaFormat}
                      name="pagamento_acrescimo"
                      placeholder="Digite o Valor"
                      errors={errors}
                    />
                  </Col>
                  <Col xs={4} className="form-group">
                    <label className="obrigatorio">Valor Liquído*</label>
                    <FieldValidate
                      component={MoedaFormat}
                      name="pagamento_liquido"
                      placeholder="Digite o Valor"
                      errors={errors}
                    />
                  </Col>
                  <Col xs={4} className="form-group">
                    <label className="obrigatorio">Caixa*</label>
                    <FieldValidate
                      name="id_caixa"
                      placeholderText="Nome do caixa"
                      component={SelectComponent}
                      options={caixas}
                      errors={errors}
                      noClass
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="form-group">
                      <label>Observação</label>
                      <FieldValidate
                        width="100%"
                        name="pagamento_observacao"
                        placeholder="Digite a descrição"
                        component="textarea"
                        type="text"
                        errors={errors}
                      />
                    </div>
                  </Col>
                </Row>

                <div className="form-group">
                  <Row>
                    <Col>
                      <AlertGeral retorno={retorno} />
                      <Spinner loading={loading} />
                    </Col>
                  </Row>
                  {comissao.status === "P" ? (
                    <Row>
                      <Col>
                        <Alert variant="success">
                          Pagamento já foi efetuado.
                        </Alert>
                      </Col>
                    </Row>
                  ) : (
                    <Button variant="salvar" type="submit">
                      <FontAwesomeIcon className="icone" icon={faSave} /> Salvar
                    </Button>
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default PagamentoComissao;
