import React, { useEffect, useState } from "react";

import { DataTable } from "primereact/datatable";

import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
// import "../../Global/styleGeral.css";
import "../../../Global/styleGeral.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faEdit, faEye, faMoneyBill } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";

library.add(faEdit, faMoneyBill, faEye);

export default function Listagem({
  header,
  dados,
  cols,
  url_edit,
  tipo_delete,
  history,
  botaoExtra,
  noEdit,
  noCancel,
  filtrarPorData,
  filtrarPorCaixa,
}) {
  const [globalFilter, setGlobalFilter] = useState("");
  const [dadosTable, setDadosTable] = useState([]);
  const [mesesFiltrar, setMesesFiltrar] = useState("0");
  const [caixaFiltrar, setCaixaFiltrar] = useState("0");
  const [caixas, setCaixas] = useState([]);

  useEffect(() => {
    setDadosTable(dados);
  }, [dados]);

  // useEffect(() => {
  //   async function setCaixasForSelect() {
  //     setCaixas(await getCaixasForSelect());
  //   }

  //   setCaixasForSelect();
  // }, [dados]);

  const dispatch = useDispatch();

  let loadColumns = cols.map((col, i) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        body={col.body}
        style={col.style}
        sortable={col.sortable}
      />
    );
  });

  function actionButtons(rowData, column) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      ></div>
    );
  }

  const meses = [
    // { label: "Todos os meses", value: "0" },
    { label: "Janeiro", value: "01" },
    { label: "Fevereiro", value: "02" },
    { label: "Março", value: "03" },
    { label: "Abril", value: "04" },
    { label: "Maio", value: "05" },
    { label: "Junho", value: "06" },
    { label: "Julho", value: "07" },
    { label: "Agosto", value: "08" },
    { label: "Setembro", value: "09" },
    { label: "Otubro", value: "10" },
    { label: "Novembro", value: "11" },
    { label: "Dezembro", value: "12" },
  ];

  return (
    <div>
      <div style={{ textAlign: "left" }}>
        <Row>
          <Col>
            {/* <FontAwesomeIcon
              style={{ color: "#c7c7c7" }}
              className="icone"
              icon={faSearch}
            /> */}
            <InputText
              type="search"
              className="pesquisa"
              onInput={(e) => setGlobalFilter(e.target.value)}
              placeholder="Pesquisa"
              alt="Digite o que procura"
              title="Digite o que procura"
              size="50"
            />
          </Col>
          {filtrarPorData && (
            <Col>
              <select
                onChange={(e) => setMesesFiltrar(e.target.value)}
                style={{ display: "block" }}
                placeholder="Selecione o mês"
              >
                {meses.map((mes) => (
                  <option key={mes.value} value={mes.value}>
                    {mes.label}
                  </option>
                ))}
              </select>
            </Col>
          )}
          {filtrarPorCaixa && (
            <Col>
              <select
                onChange={(e) => setCaixaFiltrar(e.target.value)}
                style={{ display: "block" }}
                placeholder="Selecione o caixa"
              >
                <option key={"all"} value="all">
                  Todos os caixas
                </option>
                {caixas.map((mes) => (
                  <option key={mes.value} value={mes.value}>
                    {mes.label}
                  </option>
                ))}
              </select>
            </Col>
          )}
        </Row>
      </div>

      <div className="content-section implementation">
        <DataTable
          value={dadosTable}
          paginator={true}
          globalFilter={globalFilter}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rows={10}
          rowsPerPageOptions={[10, 50, 150]}
          responsive={true}
          reorderableColumns={true}
          selectionMode="single"
          className="lista-tabela"
        >
          {loadColumns}
          {/* <Column
            body={actionButtons}
            header=""
            style={{ textAlign: "center", width: "300px" }}
          /> */}
        </DataTable>
      </div>
    </div>
  );
}
