import styled from "styled-components";
// Titulo
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  justify-content: center;
  align-items: baseline;
  padding: 20px;
  margin: 0 auto;

  a:hover {
    text-decoration: none !important;
  }
  .swiper-wrapper {
    display: contents !important;
  }

  .fixedElement {
    position: fixed;
    top: 1%;
    width: 170px;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: baseline;
    text-align: center;
    height: 50px;
    padding: 10px 5px;
    border-radius: 5px;
    background-color: #082044;
    color: #fff;
  }
  .nomeProfissionais {
    text-align: center;
    height: 50px;
    padding: 10px 5px;
    border-radius: 5px;
    background-color: #082044;
    color: #fff;
    margin-bottom: 10px;
  }
  .nomePaciente {
    font-size: 10px;
  }

  .semHorario {
    padding-top: 18px;
    text-overflow: clip;
    color: white;
    background-color: rgb(127, 140, 141);
    border-radius: 5px;
    height: 60px;
    margin-bottom: 15px;
  }
  .semHorario:hover {
    background-color: #556263;
  }
  .comHorario {
    padding-top: 5px;
    text-overflow: clip;
    color: white;
    border-radius: 5px;
    background-color: #5c5757;
    height: 60px;
    line-height: 10px;
    font-size: 10px;
    margin-bottom: 15px;
  }
  .comHorario:hover {
    background-color: #556263;
  }

  .comHorario p {
    line-height: 10px !important;
  }

  .bloqueado {
    padding-top: 10px;
    text-overflow: clip;
    color: red;
    border-radius: 5px;
    background-color: #f0f0f0;
    height: 60px;
    line-height: 20px;
    font-size: 10px;
    margin-bottom: 15px;
  }

  .feitoEvolucao {
    padding-top: 5px;
    text-overflow: clip;
    color: white;
    border-radius: 5px;
    background-color: blue;
    height: 60px;
    margin-bottom: 15px;
    line-height: 10px;
    font-size: 10px;
  }

  .confirmado {
    padding-top: 5px;
    text-overflow: clip;
    color: #038401;
    border-radius: 5px;
    background-color: #5c5757;
    height: 60px;
    margin-bottom: 15px;
    line-height: 10px;
    font-size: 10px;
  }
  .confirmado:hover {
    background-color: #217501;
  }
  span {
    text-align: center!;
  }

  h2 {
    text-align: center !important;
  }
  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-wrapper {
    width: 1024px;
    margin: 0 28px;
    > div {
      margin-right: 0px;
    }
  }

  .swiper-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }

  .swiper-slide {
    display: flex;
    flex-direction: column;
    text-align: center;
    background: #fff;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    background-color: transparent;

    .swiper-slide a {
      padding: 10px !important;
    }

    > .wrapper {
      display: flex;
      flex-direction: column;
    }

    > .container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: baseline;

      @media (min-width: 425px) {
        align-items: baseline;
      }

      @media (min-width: 1024px) {
        flex-direction: row;
      }

      .content {
        display: flex;
        flex-direction: column;
        width: 340px;
        vertical-align: middle;

        h4 {
          font-size: 14px;
          text-align: center;
        }

        > div {
          display: grid;
          flex-direction: column;
          align-items: baseline;
          justify-content: center;

          > .horarios {
            padding: 14px;
            background-color: rgb(127, 140, 141);
          }
        }
      }

      > div + div {
        margin-left: 14px;
      }
    }
  }

  .swiper-button-prev {
    position: absolute;
    top: 28px;
  }

  .swiper-button-next {
    position: absolute;
    top: 28px;
  }

  /* botão de próximo e anterior dia da semana*/
  .controle-semana {
    margin-bottom: 60px;
  }
  .controle-semana span {
    cursor: pointer;
    border-bottom: 1px solid #555;
    padding: 1px 0;
  }

  /* input data para escolher dia */

  #data-navigation {
    display: inline-block;
    position: relative;
    margin-left: 10px;
    padding: 0.1rem 1.5rem 0.1rem 0.75rem;

    font-size: 0.8rem;
    font-family: monospace;

    border: 1px solid #8292a2;
    border-radius: 0.25rem;
    background: white
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12.8' viewBox='0 0 20 22'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23688EBB' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' transform='translate(1 1)'%3E%3Crect width='18' height='18' y='2' rx='2'/%3E%3Cpath d='M13 0L13 4M5 0L5 4M0 8L18 8'/%3E%3C/g%3E%3C/svg%3E")
      right 0.8rem center no-repeat;
    cursor: pointer;
  }
  #data-navigation:focus {
    outline: none;
    border-color: #3acfff;
    box-shadow: 0 0 0 0.25rem rgba(0, 120, 250, 0.1);
  }
  ::-webkit-datetime-edit-month-field:hover,
  ::-webkit-datetime-edit-day-field:hover,
  ::-webkit-datetime-edit-year-field:hover {
    background: rgba(0, 120, 250, 0.1);
  }
  ::-webkit-datetime-edit-text {
    opacity: 0;
  }
  ::-webkit-clear-button,
  ::-webkit-inner-spin-button {
    display: none;
  }
  ::-webkit-calendar-picker-indicator {
    position: absolute;
    width: 2.5rem;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;

    opacity: 0;
    cursor: pointer;

    color: rgba(0, 120, 250, 1);
    background: rgba(0, 120, 250, 1);
  }

  #data-navigation[type="date"]:hover::-webkit-calendar-picker-indicator {
    opacity: 0.05;
  }
  #data-navigation[type="date"]:hover::-webkit-calendar-picker-indicator:hover {
    opacity: 0.15;
  }

  /* final do input data para escolher dia */
`;
