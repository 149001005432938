import React, { useState, useEffect } from "react";
import { Breadcrumb, Button, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import "../../Global/styleGeral.css";
import Listagem from "../../components/Listagens";
import { colunasComissoes } from "../../components/Listagens/colunas";
import api from "../../services/api";
import { AlertGeral, requisitarAPI } from "../../components/AlertGeral";
import Spinner from "../../components/Spiner";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faChevronLeft,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";

const Comissao = ({ history }) => {
  const [retorno, setRetorno] = useState({});
  const [loading, setLoading] = useState(true);
  const [comissions, setComissions] = useState([]);


  async function getDados() {
    let ret = await requisitarAPI(
      () => api.get("comissoes"),
      "Falha ao obter dados do servidor"
    );

    if (!ret.erro) {
      setComissions(ret.response.data.message);
    }

    setLoading(false);
  }

  useEffect(() => {
    getDados();
  }, []);

  return (
    <>
      <div className="titulo-pagina">
        Comissões
        <a
          href="/ajuda#financeiro"
          alt="Clique para acessar página de ajuda"
          style={{ float: "right" }}
          title="Clique para acessar página de ajuda"
        >
          <FontAwesomeIcon className="icone" icon={faQuestionCircle} />
        </a>
        <AlertGeral retorno={retorno} />
        <Spinner loading={loading} />
      </div>

      <div className="container-principal">
        <Row>
          <Listagem
            dados={comissions}
            cols={colunasComissoes}
            // url_edit="financeiro/contasReceber/editar"
            // tipo_delete="contasReceber"
            history={history}
            botaoExtra={{
              title: "Pagamento",
              icone: "money-bill",
              page: "financeiro/comissao/pagamento",
            }}
          />
        </Row>
      </div>
    </>
  );
};

export default Comissao;
