import React, { useState, useEffect } from "react";
import { Button, Col, InputGroup, Row, Tab, Tabs } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Creators as ContasReceberActions } from "../../Store/Controls/financeiros/contasReceber";
import { Creators as CaixasActions } from "../../Store/Controls/caixas";
import MainMenu from "../../components/MainMenu";
import "../../Global/styleGeral.css";
import Listagem from "../../components/Listagens";
import { colunasContasReceber } from "../../components/Listagens/colunas";
import api from "../../services/api";
import { requisitarAPI } from "../../components/AlertGeral";
import Spinner from "../../components/Spiner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { getCaixasForSelect } from "../../Global/chamadasAPI";
import { Field } from "formik";

const ContasAReceberFinanceiro = ({ history }) => {
  const [loading, setLoading] = useState(true);
  const [isEmitirNf, setIsEmitirNf] = useState(false);

  const [startDate, setStartDate] = useState(
    moment(new Date()).format("Y-m-d")
  );
  const [endDate, setEndDate] = useState(moment(new Date()).format("Y-m-d"));

  const [caixaFiltrar, setCaixaFiltrar] = useState(null);

  const contasReceber = useSelector((state) => state.contasReceber);
  const caixas = useSelector((state) => state.caixas);
  const dispatch = useDispatch();

  useEffect(() => {
    // Setar data
    const start = moment().startOf("month").format("YYYY-MM-DD").toString();
    const end = moment()
      .endOf("month")
      .format("YYYY-MM-DD")
      .toString();
    setStartDate(start);
    setEndDate(end);

    getDados();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (caixaFiltrar !== null) {
      async function findContasAReceberByCaixa() {
        let ret;

        if (caixaFiltrar !== "all") {
          ret = await requisitarAPI(
            () =>
              api.get(
                `ContasReceber?isNf=${isEmitirNf}&startDate=${startDate}&endDate=${endDate}&id_caixa=${caixaFiltrar}`
              ),
            "Falha ao obter as contas a receber do servidor"
          );
        } else {
          ret = await requisitarAPI(
            () =>
              api.get(
                `ContasReceber?isNf=${isEmitirNf}&startDate=${startDate}&endDate=${endDate}`
              ),
            "Falha ao obter as contas a receber do servidor"
          );
        }

        if (!ret.erro) {
          dispatch(ContasReceberActions.addContasReceber(ret.response.data));
        }
      }

      findContasAReceberByCaixa();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caixaFiltrar]);

  async function getDados() {
     const start = moment().startOf("month").format("YYYY-MM-DD").toString();
    const end = moment()
      .endOf("month")
      .format("YYYY-MM-DD")
      .toString();

    async function findContasReceber() {
      let ret = await requisitarAPI(
        () =>
          api.get(`contasReceber?isNf=${isEmitirNf}&startDate=${start}&endDate=${end.toString()}`),
        "Falha ao obter dados do servidor"
      );

      if (!ret.erro) {
        dispatch(ContasReceberActions.addContasReceber(ret.response.data));
      }
    }
    await findContasReceber();

    // Buscar Todos Os Caixas
    await (async () => {
      dispatch(CaixasActions.addCaixas(await getCaixasForSelect()));
    })();

    setTimeout(() => setLoading(false), 800);
  }

  async function filtrarPorData(endDatee = null) {
    if (endDatee) {
      let ret = await requisitarAPI(
        () =>
          api.get(
            `contasReceber?isNf=${isEmitirNf}&startDate=${startDate}&endDate=${endDatee.toString()}`
          ),
        "Falha ao obter as contas a receber do servidor"
      );

      if (!ret.erro) {
        dispatch(ContasReceberActions.addContasReceber(ret.response.data));
      }
      return;
    }

    const start = moment().startOf("month").format("YYYY-MM-DD").toString();
    const end = moment()
      .endOf("month")
      .format("YYYY-MM-DD")
      .toString();

    if (!startDate || !endDate) {
      let ret = await requisitarAPI(
        () => api.get(`contasReceber?isNf=${isEmitirNf}&startDate=${start}&endDate=${end}`),
        "Falha ao obter o resumo do servidor"
      );

      if (!ret.erro) {
        dispatch(ContasReceberActions.addContasReceber(ret.response.data));
      }

      return;
    }

    let ret = await requisitarAPI(
      () => api.get(`ContasReceber?isNf=${isEmitirNf}&startDate=${startDate}&endDate=${endDate}`),
      "Falha ao obter o resumo do servidor"
    );

    if (!ret.erro) {
      dispatch(ContasReceberActions.addContasReceber(ret.response.data));
    }
  }

  if (!loading) {
    return (
      <>
        <MainMenu navigation={history} />

        <div className="titulo-pagina">
          <Tabs
            activeKey={"contas-a-receber"}
            onSelect={(k) => {
              history.push(`/financeiro/${k}`);
            }}
            className="mb-3"
          >
            <Tab eventKey="resumo" title="Resumo"></Tab>
            <Tab eventKey="movimentacoes" title="Movimentações"></Tab>
            <Tab eventKey="contas-a-receber" title="Contas a Receber">
              Contas a Receber
              <Row>
                <Col className=" mb-3" sm={12} md={6} lg={3}>
                  <InputGroup>
                    <input
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      type="date"
                      className="form-control"
                    />
                  </InputGroup>
                </Col>
                <Col className=" mb-3" sm={12} md={6} lg={3}>
                  <InputGroup>
                    <input
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      type="date"
                      className="form-control"
                    />
                  </InputGroup>
                </Col>
                <Col className=" mb-3" sm={12} md={6} lg={3}>
                  <input
                    name="emited_nf"
                    type="checkbox"
                    onClick={(event) => setIsEmitirNf(event.target.checked)}
                    defaultChecked={isEmitirNf}
                    style={{ marginRight: "0px" }}
                  />
                  <label style={{ color: "green", marginTop: "10px" }}>
                    Precisa emitir Nota Fiscal
                  </label>
                </Col>

				        {/* <Col>
                  <Button
                    onClick={async () => {
                      setEndDate(
                        moment(endDate)
                          .subtract("month", 1)
                          .endOf("month")
                          .format("YYYY-MM-DD")
                          .toString()
                      );
                      await filtrarPorData(
                        moment(endDate)
                          .subtract("month", 1)
                          .endOf("month")
                          .format("YYYY-MM-DD")
                          .toString()
                      );
                    }}
                    variant="outline-secondary"
                    id="button-addon1"
                  >
                    Mês anterior
                  </Button>

                  <Button
                    onClick={async () => {
                      setEndDate(
                        moment(endDate)
                          .add("month", 1)
                          .endOf("month")
                          .format("YYYY-MM-DD")
                          .toString()
                      );

                      await filtrarPorData(
                        moment(endDate)
                          .add("month", 1)
                          .endOf("month")
                          .format("YYYY-MM-DD")
                          .toString()
                      );
                    }}
                    variant="outline-secondary"
                    id="button-addon1"
                  >
                    Próximo mes
                  </Button>
                </Col> */}
          
                {/* <Col>
                  <select
                    onChange={(e) => setCaixaFiltrar(e.target.value)}
                    style={{ display: "block" }}
                    placeholder="Selecione o caixa"
                  >
                    <option key={"all"} value="all">
                      Todos os caixas
                    </option>
                    {caixas.map((mes) => (
                      <option key={mes.value} value={mes.value}>
                        {mes.label}
                      </option>
                    ))}
                  </select>
                </Col> */}

                <Col>
                  <Button
                    onClick={() => filtrarPorData(null)}
                    type="button"
                    variant="primary"
                  >
                    Filtrar
                  </Button>
                </Col>
                <Button
                variant="principal"
                onClick={() => {
                  history.push("/financeiro/contasReceber/cadastro");
                }}
                alt="Adicione uma nova conta a receber"
                title="Adicione uma nova conta a receber"
              >
                {" "}
                <FontAwesomeIcon className="icone" icon={faPlus} /> Incluir
                Contas a Receber
              </Button>
              </Row>
              
              <div
                style={{ margin: 0, marginTop: 20 }}
                className="container-principal"
              >
                <Row>
                  <Listagem
                    filtrarPorData={false}
                    filtrarPorCaixa={false}
                    dados={contasReceber}
                    cols={colunasContasReceber}
                    url_edit="financeiro/contasReceber/editar"
                    tipo_delete="contasReceber"
                    history={history}
                    botaoExtra={{
                      title: "Pagamento",
                      icone: "money-bill",
                      page: "financeiro/contasReceber/pagamento",
                    }}
                  />
                </Row>
              </div>
            </Tab>
            <Tab eventKey="contas-a-pagar" title="Contas a Pagar"></Tab>
            <Tab eventKey="caixas" title="Caixas"></Tab>
            <Tab
              eventKey="formas-de-pagamento"
              title="Formas de Pagamento"
            ></Tab>
            <Tab eventKey="comissoes" title="Comissões"></Tab>
          </Tabs>
        </div>
      </>
    );
  } else {
    return (
      <div
        style={{
          width: "220px",
          height: "30px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        <h4
          style={{
            color: "#123abc",
            fontSize: "18px",
            fontWeight: "bold",
            textTransform: "uppercase",
            margin: "10px 0px",
          }}
        >
          Carregando..
        </h4>
        <Spinner height={20} animation="grow" variant="primary" />
      </div>
    );
  }
};

export default ContasAReceberFinanceiro;
