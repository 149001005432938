/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Button, Row, Alert, Col } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { Creators as ContasPagarActions } from "../../Store/Controls/financeiros/contasPagar";
import "../../Global/styleGeral.css";
import { DatePickerField } from "../../components/Form/DatePicker.js";
import { AlertGeral, requisitarAPI } from "../../components/AlertGeral";
import { SelectComponent } from "../../components/Form/Select.js";
import api from "../../services/api";
import MainMenu from "../../components/MainMenu";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faChevronLeft,
  faUsers,
  faMapMarkerAlt,
  faBuilding,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import FieldValidate from "../../components/Form/FieldValidate.js";
import { MoedaFormat } from "../../components/Form/MoedaFormat.js";
import { dateToField } from "../../Global/funcoes";
import {
  getFornecedoresForSelect,
  getFormaPagamentoForSelect,
  gravarFinanceiro,
  getCaixasForSelect,
  gravarFinanceiroPagar,
} from "../../Global/chamadasAPI";
import Spinner from "../../components/Spiner";
import moment from "moment";

library.add(faSave, faChevronLeft, faUsers, faMapMarkerAlt, faBuilding);

const CadastroContasPagar = ({ history, match }) => {
  const [editForm, setEditForm] = useState(false);
  const [contaReceber, setContaReceber] = useState({
    id_fornecedor: "",
    id_caixa: "",
    descricao: "",
    data_geracao: new Date(),
    id_conta: "",
    data_vencimento: new Date(),
    valor: "",

    parcelas_total: 0,
    parcelas_intervalo: 0,
  });
  const [fornecedores, setFornecedores] = useState([{}]);
  const [fornecedorSel, setFornecedorSel] = useState([{}]);
  const [captionForm, setCaptionForm] = useState("Cadastro de Contas a Pagar");
  const [retorno, setRetorno] = useState({});
  const [loading, setLoading] = useState(true);
  const [geracaoMensalidade, setGeracaoMensalidade] = useState(false);
  const [isBaixaAutomatica, setIsBaixaAutomatica] = useState(true);
  const [formasPagamento, setFormasPagamento] = useState([{}]);
  const [caixas, setCaixas] = useState([{}]);

  const contasPagar = useSelector((state) => state.contasPagar);
  const dispatch = useDispatch();

  async function getDadosSelects() {
    setFornecedores(await getFornecedoresForSelect());
  }

  async function getFornecedores() {
    let ret = await requisitarAPI(
      () => api.get("fornecedores/" + match.params.id_fornecedor),
      "Falha ao obter dados do servidor"
    );
    setRetorno(ret);
    if (!ret.erro) {
      setFornecedorSel(ret.response.data);
    }
  }

  useEffect(() => {
    async function getDadosSelectsFormaPagamento() {
      setFormasPagamento(await getFormaPagamentoForSelect());
    }

    async function getDadosSelectsCaixas() {
      setCaixas(await getCaixasForSelect());
    }

    getDadosSelectsFormaPagamento();

    getDadosSelectsCaixas();

    getDadosSelects();

    if (typeof match.params.id_fornecedor !== "undefined") {
      getFornecedores();

      setGeracaoMensalidade(true);

      contaReceber.id_fornecedor = match.params.id_fornecedor;
      contaReceber.descricao = "Mensalidade";
      contaReceber.tot_mensalidades = 12;
      setContaReceber(contaReceber);
    }

    if (typeof match.params.id !== "undefined") {
      setCaptionForm("Edição de Contas a Pagar");
      setEditForm(true);
      let contaReceber = contasPagar.find((item) => item.id == match.params.id);

      if (typeof contaReceber === "undefined") {
        history.push("/financeiro/contas-a-pagar");
        return;
      }

      contaReceber.data_geracao = dateToField(contaReceber.data_geracao);
      contaReceber.data_vencimento = dateToField(contaReceber.data_vencimento);

      setContaReceber(contaReceber);
    }

    setLoading(false);
  }, [
    contaReceber,
    contasPagar,
    history,
    match.params.id,
    match.params.id_fornecedor,
  ]);

  const schema = Yup.object().shape(
    {
      id_fornecedor: Yup.number().required("Informe a fornecedor"),
      id_caixa: Yup.number().required("Informe o Caixa"),
      descricao: Yup.string().required("Informe a Descricão"),
      data_geracao: Yup.date()
        .typeError("Informe uma data válida")
        .max(new Date(), "Data de geração superior a data atual"),
      data_vencimento: Yup.date().typeError("Informe uma data válida"),
      valor: Yup.number().required("Informe o valor"),
      parcelas_total: Yup.number()
        .when("parcelas_intervalo", (parcelas_intervalo, schema) =>
          parcelas_intervalo > 0 && !editForm
            ? schema.required("Informe o total de parcelas")
            : schema
        )
        .typeError("informe um número válido"),
      parcelas_intervalo: Yup.number()
        .when("parcelas_total", (parcelas_total, schema) =>
          parcelas_total > 0 && !editForm
            ? schema.required("Informe o intervalo de dias")
            : schema
        )
        .typeError("informe um número válido"),
    },
    ["parcelas_total", "parcelas_intervalo"]
  );

  const handleSubmit = async (values) => {
    setLoading(true);

    if (geracaoMensalidade) {
      values.parcelas_total = values.tot_mensalidades;
    }

    let ret = {};
    if (editForm) {
      ret = await requisitarAPI(
        () => api.put("contasPagar/" + match.params.id, values),
        "Falha ao editar Conta a Pagar"
      );
      setRetorno(ret);

      if (!ret.erro) {
        dispatch(
          ContasPagarActions.editContaPagar(match.params.id, ret.response.data)
        );
      }
    } else {
      await gravarFinanceiroPagar(values, setRetorno, true);

      if (!ret.erro) {
        //dispatch(ContasReceberActions.addContaReceber(ret.response));
      }

      values.parcelas_sequencia = 1;

      if (values.parcelas_total > 0) {
        for (let i = 0; i < values.parcelas_total - 1; i++) {
          values.parcelas_sequencia++;

          if (geracaoMensalidade) {
            values.data_vencimento = moment(values.data_vencimento).add(
              1,
              "month"
            );
          } else {
            values.data_vencimento = moment(values.data_vencimento).add(
              values.parcelas_intervalo,
              "days"
            );
          }

          ret = await requisitarAPI(
            () => api.post("contasPagar", values),
            "Falha ao cadastar Conta a Pagar"
          );
          setRetorno(ret);

          if (!ret.erro) {
            //dispatch(ContasReceberActions.addContaReceber(ret.response));
          }
        }
      }
    }

    setLoading(false);

    if (!ret.erro) {
      history.push("/financeiro/contas-a-pagar");
    }
  };

  return (
    <>
      <MainMenu navigation={history} />

      <div className="titulo-pagina">
        {geracaoMensalidade && typeof fornecedorSel.nome !== "undefined"
          ? "Geração Mensalidade - " + fornecedorSel.nome
          : captionForm}
        <Button
          variant="voltar"
          onClick={() => {
            history.push("/financeiro/contas-a-pagar");
          }}
        >
          <FontAwesomeIcon className="icone" icon="chevron-left" /> Voltar para
          Gerenciar Contas a Pagar
        </Button>
        <a
          href="/ajuda#financeiro"
          alt="Clique para acessar página de ajuda"
          style={{ float: "right" }}
          title="Clique para acessar página de ajuda"
        >
          <FontAwesomeIcon className="icone" icon={faQuestionCircle} />
        </a>
      </div>

      <div className="container-principal">
        <Row>
          <div className="container-principal" style={{ width: "100%" }}>
            <Formik
              enableReinitialize
              initialValues={contaReceber}
              onSubmit={handleSubmit}
              validationSchema={schema}
              validateOnChange={false}
              validateOnBlur={false}
            >
              {({ errors }) => (
                <Form>
                  {!geracaoMensalidade && (
                    <Row>
                      <div
                        className="col form-group"
                        style={{ marginTop: "1em", marginBottom: "1em" }}
                      >
                        <label className="obrigatorio">Fornecedor*</label>
                        <FieldValidate
                          name="id_fornecedor"
                          placeholderText="Nome do fornecedor"
                          component={SelectComponent}
                          options={fornecedores}
                          errors={errors}
                          noClass
                        />
                      </div>

                      <div
                        className="col form-group"
                        style={{ marginTop: "1em", marginBottom: "1em" }}
                      >
                        <label className="obrigatorio">Caixa*</label>
                        <FieldValidate
                          name="id_caixa"
                          placeholderText="Nome do caixa"
                          component={SelectComponent}
                          options={caixas}
                          errors={errors}
                          noClass
                        />
                      </div>
                    </Row>
                  )}

                  <Row>
                    <div
                      className="form-group col"
                      style={{ marginTop: "1em", marginBottom: "1em" }}
                    >
                      <label className="obrigatorio">Descrição*</label>
                      <FieldValidate
                        name="descricao"
                        placeholder="Digite a descrição da baixa*"
                        type="text"
                        errors={errors}
                      />
                    </div>

                    <div
                      className="form-group col"
                      style={{ marginTop: "1em", marginBottom: "1em" }}
                    >
                      <label className="obrigatorio">Data de Geração*</label>
                      <br />
                      <DatePickerField
                        withPortal
                        placeholderText="Informe a data de geração*"
                        name="data_geracao"
                        className="form-control"
                      />
                      <ErrorMessage
                        name={"data_geracao"}
                        component="div"
                        style={{ fontSize: "16px", color: "red" }}
                      />
                    </div>

                    {geracaoMensalidade && (
                      <div
                        className="form-group col-3"
                        style={{ marginTop: "1em", marginBottom: "1em" }}
                      >
                        <label>Quantidade de mensalidades</label>
                        <FieldValidate
                          name="tot_mensalidades"
                          placeholder="Quantidade de Mensalidades"
                          type="number"
                          errors={errors}
                        />
                      </div>
                    )}
                  </Row>

                  <Row>
                    <div
                      className="form-group col"
                      style={{ marginTop: "1em", marginBottom: "1em" }}
                    >
                      <label className="obrigatorio">Valor*</label>
                      <FieldValidate
                        component={MoedaFormat}
                        name="valor"
                        placeholder="Digite o Valor"
                        errors={errors}
                      />
                    </div>

                    <div
                      className="form-group col"
                      style={{ marginTop: "1em", marginBottom: "1em" }}
                    >
                      <label className="obrigatorio">Data de Vencimento*</label>
                      <br />
                      <DatePickerField
                        withPortal
                        placeholderText="Informe a data de Vencimento*"
                        name="data_vencimento"
                        className="form-control"
                      />
                      <ErrorMessage
                        name={"data_vencimento"}
                        component="div"
                        style={{ fontSize: "16px", color: "red" }}
                      />
                    </div>
                  </Row>

                  <Row>
                    {!editForm && !geracaoMensalidade && (
                      <>
                        <div
                          className="form-group col"
                          style={{ marginTop: "1em", marginBottom: "1em" }}
                        >
                          <label>Parcelas</label>
                          <FieldValidate
                            name="parcelas_total"
                            placeholder="Digite a quantidade de Parcelas"
                            errors={errors}
                          />
                        </div>

                        <div
                          className="form-group col"
                          style={{ marginTop: "1em", marginBottom: "1em" }}
                        >
                          <label>Intervalo de Dias</label>
                          <FieldValidate
                            name="parcelas_intervalo"
                            placeholder="Digite o Intervalo de Dias"
                            errors={errors}
                          />
                        </div>
                      </>
                    )}
                  </Row>

                  <Row style={{ marginTop: "5px" }}>
                    <Col
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        AlignItems: "center",
                      }}
                    >
                      <Field
                        name="baixar_duplicata"
                        type="checkbox"
                        onClick={(event) =>
                          setIsBaixaAutomatica(event.target.checked)
                        }
                        defaultChecked={isBaixaAutomatica}
                        style={{ marginRight: "0px" }}
                      />
                      <label style={{ color: "green", marginTop: "10px" }}>
                        {" "}
                        Baixar duplicata automáticamente (Pago)
                      </label>
                      <br />
                    </Col>
                  </Row>

                  {isBaixaAutomatica && (
                    <Row style={{ marginTop: "5px" }}>
                      <Col style={{ marginTop: "5px" }}>
                        <label className="obrigatorio">
                          Forma de Pagamento
                        </label>
                        <FieldValidate
                          name="pagamento_id_forma_pagamento"
                          placeholder="Forma de pagamento"
                          component={SelectComponent}
                          options={formasPagamento}
                          errors={errors}
                          noClass
                        />
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Col>
                      <AlertGeral retorno={retorno} />
                      <Spinner loading={loading} />
                    </Col>
                  </Row>
                  {(contaReceber.status !== "P" && editForm) || !editForm ? (
                    <Row
                      style={{ marginTop: "40px" }}
                      className="form-group text-center"
                    >
                      <Col className="text-center">
                        <Button variant="salvar" type="submit">
                          <FontAwesomeIcon className="icone" icon="save" />{" "}
                          Salvar
                        </Button>
                      </Col>
                    </Row>
                  ) : (
                    <Alert variant="danger">
                      Conta já possui Pagamento, não será possivel editar.
                    </Alert>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </Row>
      </div>
    </>
  );
};

export default CadastroContasPagar;
