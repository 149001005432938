import { requisitarAPI } from "../components/AlertGeral";
import api from "../services/api";
import moment from "moment";


export async function getFornecedoresForSelect(tipo) {
  let fornecedoresAPI = [{}];
 
  fornecedoresAPI = await requisitarAPI(
    () => api.get("fornecedores"),
    "Falha ao obter fornecedores do servidor"
  );

  let fornecedores = [];

  if (!fornecedoresAPI.erro) {
    fornecedoresAPI.response.data.forEach((pessoa) => {
      fornecedores.push({ label: pessoa.nome, value: pessoa.id });
    });
  }
  return fornecedores;
}

export async function getPessoasForSelect(tipo) {
  let pessoasAPI = [{}];
  if (typeof tipo !== "undefined")
    pessoasAPI = await requisitarAPI(
      () => api.get("pessoas", { params: { tipo } }),
      "Falha ao obter pessoas do servidor"
    );
  else
    pessoasAPI = await requisitarAPI(
      () => api.get("pessoas"),
      "Falha ao obter pessoas do servidor"
    );

  let pessoas = [];

  if (!pessoasAPI.erro) {
    pessoasAPI.response.data.forEach((pessoa) => {
      pessoas.push({ label: pessoa.nome, value: pessoa.id });
    });
  }
  return pessoas;
}

export async function getPessoas(tipo) {
  let pessoasAPI = [{}];
  if (typeof tipo !== "undefined")
    pessoasAPI = await requisitarAPI(
      () => api.get("pessoas", { params: { tipo } }),
      "Falha ao obter pessoas do servidor"
    );
  else
    pessoasAPI = await requisitarAPI(
      () => api.get("pessoas"),
      "Falha ao obter pessoas do servidor"
    );

  if (!pessoasAPI.erro) {
    return pessoasAPI.response.data;
  }
  
}


export async function getContasForSelect() {
  let contasAPI = await requisitarAPI(
    () => api.get("contas"),
    "Falha ao obter contas do servidor"
  );

  if (!contasAPI.erro) {
    let contas = [];

    contasAPI.response.data.forEach((conta) => {
      contas.push({ label: conta.descricao, value: conta.id });
    });

    return contas;
  }
}

export async function getFormaPagamentoForSelect() {
  let formaAPI = await requisitarAPI(
    () => api.get("formasPagamento"),
    "Falha ao obter formas de pagamento do servidor"
  );

  if (!formaAPI.erro) {
    let formas = [];

    formaAPI.response.data.forEach((forma) => {
      formas.push({ label: forma.descricao, value: forma.id });
    });

    return formas;
  }
}

export async function getCaixasForSelect() {
  let caixaAPI = await requisitarAPI(
    () => api.get("caixas"),
    "Falha ao obter os caixas do servidor"
  );

  if (!caixaAPI.erro) {
    let caixas = [];

    caixaAPI.response.data.forEach((caixa) => {
      caixas.push({ label: caixa.nome, value: caixa.id });
    });

    return caixas;
  }
}

export async function getCaixas() {
  let caixaAPI = await requisitarAPI(
    () => api.get("caixas"),
    "Falha ao obter os caixas do servidor"
  );

  if (!caixaAPI.erro) {
    return caixaAPI.response.data;
  }
}

export async function createCaixa(data) {
  let caixaAPI = await requisitarAPI(
    () => api.post("caixas", data),
    "Falha ao criar um caixa"
  );

  if (!caixaAPI.erro) {
    return caixaAPI.response.data;
  }
}

export async function deleteCaixa(id) {
  let caixaAPI = await requisitarAPI(
    () => api.delete(`caixas/${id}`),
    "Falha ao deletar esse caixa"
  );

  if (!caixaAPI.erro) {
    return caixaAPI.response.data;
  }
}

export const gravarFinanceiro = async (values, setRetorno) => {
  values.data_geracao = new Date();

  if (values.parcelas_total === "") values.parcelas_total = 0;

  if (values.parcelas_intervalo === "") values.parcelas_intervalo = 0;

  values.parcelas_sequencia = 1;
  let ret = await requisitarAPI(
    () => api.post("contasReceber", values),
    "Falha ao cadastar Conta a Receber"
  );
  
  setRetorno(ret);

  if (values.procedimento_name) {
    if (values.procedimento_name.toLowerCase() === 'pilates') {    
      values.parcelas_total = 12
      values.parcelas_intervalo = 30
    }
  } 

  if (values.baixar_duplicata) {
    let contaReceber = ret.response.data;
    contaReceber.pagamento_id_forma_pagamento =
      values.pagamento_id_forma_pagamento;
    contaReceber.pagamento_data = new Date();
    contaReceber.pagamento_liquido = values.valor;

    contaReceber.status = "P";
    ret = await requisitarAPI(
      () => api.put("contasReceber/" + contaReceber.id, contaReceber),
      "Falha ao editar Conta a Receber"
    );
    setRetorno(ret);
  }

  if (values.parcelas_total > 0) {
    
    for (let i = 0; i < values.parcelas_total - 1; i++) {
      values.parcelas_sequencia++;
      values.data_vencimento = moment(values.data_vencimento).add(
        values.parcelas_intervalo,
        "days"
      );

      ret = await requisitarAPI(
        () => api.post("contasReceber", values),
        "Falha ao cadastar Conta a Receber"
      );
      setRetorno(ret);
    }
  }

  return ret.erro;
};
export const gravarFinanceiroPagar = async (values, setRetorno, apenasUm) => {
  values.data_geracao = new Date();

  if (values.parcelas_total === "") values.parcelas_total = 0;

  if (values.parcelas_intervalo === "") values.parcelas_intervalo = 0;

  values.parcelas_sequencia = 1;
  let ret = await requisitarAPI(
    () => api.post("contasPagar", values),
    "Falha ao cadastar Conta a Pagar"
  );
  setRetorno(ret);

  if (values.baixar_duplicata) {
    let contaPagar = ret.response.data;
    contaPagar.pagamento_id_forma_pagamento =
      values.pagamento_id_forma_pagamento;
    contaPagar.pagamento_data = new Date();
    contaPagar.pagamento_liquido = values.valor;

    contaPagar.status = "P";
    ret = await requisitarAPI(
      () => api.put("contasPagar/" + contaPagar.id, contaPagar),
      "Falha ao editar Conta a Pagar"
    );
    setRetorno(ret);
  }

  if (!apenasUm) {
    if (values.parcelas_total > 0) {
      for (let i = 0; i < values.parcelas_total - 1; i++) {
        values.parcelas_sequencia++;
        values.data_vencimento = moment(values.data_vencimento).add(
          values.parcelas_intervalo,
          "days"
        );

        ret = await requisitarAPI(
          () => api.post("contasReceber", values),
          "Falha ao cadastar Conta a Receber"
        );
        setRetorno(ret);
      }
    }
  }

  return ret.erro;
};

export async function getConfig() {
  let ret = await requisitarAPI(
    () => api.get("config"),
    "Falha ao obter dados do servidor"
  );

  if (!ret.erro) {
    if (ret.response.data.length > 0) {
      return ret.response.data[0];
    }
  }
}
