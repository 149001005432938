import { createActions, createReducer } from "reduxsauce";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  addGuias: ["guias"],
  addGuia: ["guia"],
  editGuia: ["id", "guia"],
  removeGuia: ["id"],
});

/**
 * Implementação das manipulações do State
 */
const INITIAL_STATE = [];

const addGuias = (state = INITIAL_STATE, action) => action.guias;

const add = (state = INITIAL_STATE, action) => [...state, action.guia];

const edit = (state = INITIAL_STATE, action) =>
  state.map((item) => {
    if (item.id === action.id) {
      return action.guia;
    } else {
      return item;
    }
  });

const remove = (state = INITIAL_STATE, action) => {
  return state.filter((todo) => todo.id !== action.id);
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.ADD_GUIAS]: addGuias,
  [Types.ADD_GUIA]: add,
  [Types.EDIT_GUIA]: edit,
  [Types.REMOVE_GUIA]: remove,
});
