import {
  faBuilding,
  faChevronLeft,
  faListAlt,
  faMapMarkerAlt,
  faPlus,
  faQuestionCircle,
  faSave,
  faTrash,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import MaskedInput from "react-text-mask";
import * as Yup from "yup";
import { AlertGeral, requisitarAPI } from "../../components/AlertGeral";
import { DatePickerField } from "../../components/Form/DatePicker.js";
import FieldValidate from "../../components/Form/FieldValidate.js";
import { Number } from "../../components/Form/Number.js";
import { SelectComponent } from "../../components/Form/Select.js";
import MainMenu from "../../components/MainMenu";
import Spinner from "../../components/Spiner";
import {
  calcularIdade,
  dateToField,
  dateToView,
  diaIntToString,
  timeToView,
} from "../../Global/funcoes";
import "../../Global/styleGeral.css";
import api from "../../services/api";
import { Creators as PessoaActions } from "../../Store/Controls/pessoas/pessoas.js";
import ModalAddDiasPilates from "../Agendas/modalAddDiasPilates";
import { TabelaAgendamento } from "./TabelaAgendamento";

const CadastroPessoa = ({ history, match }) => {
  const [editForm, setEditForm] = useState(false);
  const [pessoa, setPessoa] = useState({
    profissional: false,
    paciente: false,
    nome: "",
    data_nascimento: null,
  });
  const [captionForm, setCaptionForm] = useState("Cadastro de Pessoa");
  const [retorno, setRetorno] = useState({});
  const [loading, setLoading] = useState(true);
  const [idade, setIdade] = useState(0);
  const [formatCPFCNPJ, setFormatCPFCNPJ] = useState("###.###.###-##");
  const [checked, setChecked] = useState(false);
  const [listaJornada, setListaJornada] = useState([]);
  const [listaDiaSel, setListaDiaSel] = useState({
    id: 0,
    dia: null,
    horario: null,
  });
  const [showModalAddDias, setShowModalAddDias] = useState(false);
  const [editListaDias, setEditListadias] = useState(false);
  const [countIDDiaSemana, setCountIDDiaSemana] = useState(0);
  const [agendaConsultorio, setAgendaConsultorio] = useState([]);
  const pessoas = useSelector((state) => state.pessoas);
  const dispatch = useDispatch();

  function verificarCPFRepetidos(cpf) {
    let pessoa = pessoas.find((item) => item.cpfcnpj === cpf);
    return typeof pessoa !== "undefined";
  }

  function getIdade(ano_aniversario, mes_aniversario, dia_aniversario) {
    setIdade(calcularIdade(ano_aniversario, mes_aniversario, dia_aniversario));
  }

  const calcularIdadeFromInput = (data) => {
    if (typeof data !== "undefined" && data !== null) {
      getIdade(data.getFullYear(), data.getMonth() + 1, data.getDate());
    }
  };

  const getUltimaConsulta = async (id) => {
    let ret = await requisitarAPI(
      () => api.get(`pessoasUltimaConsulta/${id}`),
      "Falha ao obter dados do servidor"
    );
    return ret.response.data[0];
  };

  async function getJornadas() {
    const ret = await requisitarAPI(
      () => api.get(`jornadaTrabalho/${match.params.id}`),
      "Falha ao editar grupo"
    );

    if (!ret.erro) {
      setListaJornada(ret.response.data);
    }
  }

  const tratamentoEdicao = async () => {

    setCaptionForm("Edição de Pessoa");
    setEditForm(true);
    
    let ret = await requisitarAPI(
      () => api.get(`pessoas/${match.params.id}`),
      "Falha ao obter dados do servidor"
    );

    if (ret.erro || !ret.response.data) {
      history.push("/pessoas");
      return;
    }

    let pessoa = ret.response.data;

    if (pessoa.agendaConsultorio) {
      setAgendaConsultorio(pessoa.agendaConsultorio);
    }

    pessoa.data_nascimento = dateToField(pessoa.data_nascimento);
    if (pessoa.data_nascimento) {
      getIdade(
        moment(pessoa.data_nascimento).year(),
        moment(pessoa.data_nascimento).month(),
        moment(pessoa.data_nascimento).day()
      );
    }

    if (pessoa.tipo_pessoa === "F") {
      setFormatCPFCNPJ("###.###.###-##");
    } else {
      setFormatCPFCNPJ("##.###.###/####-##");
    }

    // Pegar ultima aula
    if (pessoa.paciente) {
      const ultimaConsulta = await getUltimaConsulta(pessoa.id);
      pessoa.ultimaConsulta = ultimaConsulta;
    }

    if (pessoa.profissional) {
      getJornadas();
    }

    setPessoa(pessoa);
  };

  useEffect(() => {
    if (typeof match.params.id !== "undefined") {
      tratamentoEdicao();
    }

    setLoading(false);
  }, [match.params.id]);

  const schema = Yup.object().shape({
    nome: Yup.string().required("Informe o Nome"),
    cpfcnpj: Yup.string().required("Informe o Nome"),
    data_nascimento: Yup.date()
      .nullable()
      .max(new Date(), "Data de nascimento superior a data atual")
      .typeError("data inválida"),
  });

  const addDiaSemana = () => {
    setListaDiaSel({ dia: null, horario: null });
    setEditListadias(false);
    setShowModalAddDias(true);
  };

  const editDiaSemana = (item) => {
    setListaDiaSel(item);
    setEditListadias(true);
    setShowModalAddDias(true);
  };

  const deleteDiaSemana = (item) => {
    const novaLista = listaJornada.filter((lista) => item.id !== lista.id);
    setListaJornada(novaLista);
  };
  
  const handleSubmit = async (values) => {
    setLoading(true);

    if ((!editForm) && (verificarCPFRepetidos(values.cpfcnpj))) {
      let ret = {erro:true,titleErro:'CPF Repetido',descriptErro:'CPF já cadastrado na base de dados'};
      setRetorno(ret);
      setLoading(false);
      return;
    } else {

      let ret = {};
      if (editForm) {
        ret = await requisitarAPI(
          () => api.put("pessoas/" + match.params.id, values),
          "Falha ao editar Pessoa"
        );
        setRetorno(ret);

        if (!ret.erro) {
          dispatch(PessoaActions.editPessoa(match.params.id, ret.response.data));
        }
      } else {
        ret = await requisitarAPI(
          () => api.post("pessoas", values),
          "Falha ao cadastar pessoas"
        );
        setRetorno(ret);

        if (!ret.erro) {
          dispatch(PessoaActions.addPessoa(ret.response));
        }
      }

      setLoading(false);

      let idpost = ret.response.data.id;
      if (!ret.erro) {
        if (values.profissional) {
          ret = await requisitarAPI(
            () => api.delete(`jornadaTrabalho/${idpost}`),
            "Falha ao  deletar lista de aulas semana"
          );

          if (!ret.erro) {
            listaJornada.map(async (item) => {
              let dadosJornada = {};
              dadosJornada.dia = item.dia;
              dadosJornada.horario = item.horario;
              dadosJornada.horario_fim = item.horario_fim;
              dadosJornada.id_pessoa = idpost;
              ret = await requisitarAPI(
                () => api.post("jornadaTrabalho", dadosJornada),
                "Falha ao cadastar lista de aulas semana"
              );
              setRetorno(ret);
            });
          }
        }

        history.push("/pessoas");
      }
    }
  };

  const changeValueTipoPessoa = (value) => {
    if (value.value === "F") {
      setFormatCPFCNPJ("###.###.###-##");
    } else {
      setFormatCPFCNPJ("##.###.###/####-##");
    }
  };

  return (
    <>
      <MainMenu navigation={history} />

      <div className="titulo-pagina">
        {" "}
        {captionForm}
        {editForm && (
          <Button
            variant="secundario"
            style={{ marginLeft: "10px" }}
            onClick={() => {
              history.push("/pessoas/notasFiscais/" + pessoa.id);
            }}
          >
            <FontAwesomeIcon className="icone" icon={faListAlt} /> Notas Fiscais
          </Button>
        )}
        <Button
          variant="voltar"
          onClick={() => {
            history.push("/pessoas");
          }}
        >
          <FontAwesomeIcon className="icone" icon={faChevronLeft} /> Voltar para
          Gerenciar Pessoas
        </Button>
        <a
          href="/ajuda#pessoas"
          alt="Clique para acessar página de ajuda"
          style={{ float: "right" }}
          title="Clique para acessar página de ajuda"
        >
          <FontAwesomeIcon className="icone" icon={faQuestionCircle} />
        </a>
      </div>

      <div className="container-principal">
        <Row>
          <div style={{ width: "100%" }}>
            <Formik
              enableReinitialize
              initialValues={pessoa}
              onSubmit={handleSubmit}
              validationSchema={schema}
              validateOnChange={false}
              validateOnBlur={false}
            >
              {({ errors }) => (
                <Form>
                  <Row>
                    <Col md={4}>
                      <h5 style={{ color: "#082044" }}>
                        {" "}
                        <FontAwesomeIcon icon={faUsers} /> Geral
                      </h5>
                    </Col>

                    {typeof pessoa.agendaConsultorio !== "undefined" &&
                      pessoa.agendaConsultorio.length > 0 && (
                        <Col>
                          <strong style={{ color: "#8B4BF9" }}>
                            Primeira Consulta:{" "}
                          </strong>{" "}
                          <label>
                            {dateToView(
                              pessoa.agendaConsultorio[0].data_inicio
                            )}
                          </label>
                        </Col>
                      )}

                    {typeof pessoa.ultimaConsulta !== "undefined" && (
                      <Col>
                        <strong>Ultima Consulta confirmada: </strong>{" "}
                        <label>
                          {dateToView(pessoa.ultimaConsulta.data_inicio)}
                        </label>
                      </Col>
                    )}
                  </Row>

                  <Row className="form-group">
                    <Col className="form-check">
                      <label className="obrigatorio">Tipo de Cadastro*</label>{" "}
                      <br />
                      <Field
                        style={{ display: "inline" }}
                        noclass="true"
                        name="paciente"
                        type="checkbox"
                        errors={errors}
                      />
                      <label>Paciente</label>
                      <ErrorMessage
                        name={"paciente"}
                        component="div"
                        style={{ fontSize: "16px", color: "red" }}
                      />
                      <div onChange={() => setChecked(!checked)}>
                        <Field
                          style={{ display: "inline" }}
                          noclass="true"
                          name="profissional"
                          type="checkbox"
                          errors={errors}
                        />
                        <label>Profissional</label>
                      </div>
                      <ErrorMessage
                        name={"profissional"}
                        component="div"
                        style={{ fontSize: "16px", color: "red" }}
                      />
                    </Col>

                    <Col className="form-group">
                      <label className="obrigatorio">Tipo de Pessoa*</label>
                      <Field
                        name="tipo_pessoa"
                        errors={errors}
                        component={SelectComponent}
                        onChangeValue={changeValueTipoPessoa}
                        options={[
                          { label: "Pessoa Física", value: "F" },
                          { label: "Pessoa Jurídica", value: "J" },
                        ]}
                      />
                    </Col>

                    <Col className="form-group">
                      <label className="obrigatorio">CPF/CNPJ*</label>
                      <Field
                        name="cpfcnpj"
                        component={Number}
                        errors={errors}
                        format={formatCPFCNPJ}
                        mask="_"
                        placeholder="Digite o CPF/CNPJ"
                        className="form-control"
                        type="phone"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={4} className="form-group">
                      <label className="obrigatorio">Nome completo*</label>
                      <FieldValidate
                        name="nome"
                        placeholder="Digite o nome completo*"
                        type="text"
                        errors={errors}
                      />
                    </Col>
                    <Col sm={2} className="form-group">
                      <label>Data de Nascimento</label>
                      <br />
                      <DatePickerField
                        onChangeDate={(date) => calcularIdadeFromInput(date)}
                        placeholderText="Informe a data de nascimento*"
                        name="data_nascimento"
                        className="form-control"
                        customInput={
                          <MaskedInput
                            mask={[
                              /\d/,
                              /\d/,
                              "/",
                              /\d/,
                              /\d/,
                              "/",
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                            ]}
                            keepCharPositions={true}
                            guide={true}
                          />
                        }
                      />
                      <ErrorMessage
                        name={"data_nascimento"}
                        component="div"
                        style={{ fontSize: "16px", color: "red" }}
                      />
                    </Col>
                    <Col className="form-group">
                      <label style={{ color: "#d2d2d2" }}>Idade</label>
                      <Field
                        name="idade"
                        disabled
                        value={idade}
                        className="form-control"
                        type="text"
                      />
                    </Col>
                    <Col style={{ marginTop: "40px" }} className="form-group">
                      <Field
                        style={{ display: "inline" }}
                        name="exige_nfe"
                        type="checkbox"
                        errors={errors}
                      />
                      <label>Exige Nota Fiscal</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="form-group">
                      <label>Gênero</label>
                      <Field
                        name="sexo"
                        component={SelectComponent}
                        options={[
                          { label: "Masculino", value: "M" },
                          { label: "Feminino", value: "F" },
                          { label: "Não informado", value: "" },
                        ]}
                      />
                    </Col>

                    <Col className="form-group">
                      <label>Como encontrou</label>
                      <Field
                        name="indicacao"
                        component={SelectComponent}
                        options={[
                          { label: "Encaminhamento", value: "E" },
                          { label: "Amigo", value: "A" },
                          { label: "Parente", value: "P" },
                          { label: "Indicação", value: "I" },
                          { label: "Redes sociais", value: "R" },
                          { label: "Pesquisa no Google", value: "G" },
                          { label: "Site", value: "S" },
                          { label: "TV ou Rádio", value: "T" },
                          { label: "Outro", value: "O" },
                        ]}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col className="form-group">
                      <label>E-mail</label>
                      <Field
                        name="email"
                        placeholder="Digite o e-mail"
                        className="form-control"
                        type="text"
                      />
                    </Col>

                    <Col className="form-group">
                      <label>Telefone</label>
                      <Field
                        name="telefone"
                        component={Number}
                        format="(##) ####-####"
                        mask="_"
                        placeholder="Digite o telefone"
                        className="form-control"
                        type="phone"
                      />
                    </Col>

                    <Col className="form-group">
                      <label>Celular</label>
                      <Field
                        name="celular"
                        component={Number}
                        format="(##) # ####-####"
                        mask="_"
                        placeholder="Digite o Celular"
                        className="form-control"
                        type="phone"
                      />
                    </Col>

                    <Col className="form-group">
                      <label>Nome do contato</label>
                      <Field
                        name="nome_contato"
                        placeholder="Digite o nome"
                        className="form-control"
                        type="text"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <h5 style={{ color: "#082044", marginTop: "40px" }}>
                        <FontAwesomeIcon icon={faMapMarkerAlt} /> Endereço
                      </h5>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="form-group">
                      <label>Logradouro</label>
                      <Field
                        name="logradouro"
                        placeholder="Digite o logradouro"
                        className="form-control"
                        type="text"
                      />
                    </Col>

                    <Col className="form-group">
                      <label>Número</label>
                      <Field
                        name="numero"
                        placeholder="Digite o número"
                        className="form-control"
                        type="text"
                      />
                    </Col>

                    <Col className="form-group">
                      <label>Complemento</label>
                      <Field
                        name="complemento"
                        placeholder="Digite o complemento"
                        className="form-control"
                        type="text"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col className="form-group">
                      <label>Bairro</label>
                      <Field
                        name="bairro"
                        placeholder="Digite o bairro"
                        className="form-control"
                        type="text"
                      />
                    </Col>

                    <Col className="form-group">
                      <label>Cidade</label>
                      <Field
                        name="cidade"
                        placeholder="Digite a cidade"
                        className="form-control"
                        type="text"
                      />
                    </Col>

                    <Col className="form-group">
                      <label>Estado</label>
                      <Field
                        name="estado"
                        placeholder="Digite o estado"
                        className="form-control"
                        type="text"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col className="form-group">
                      <h5 style={{ color: "#082044", marginTop: "40px" }}>
                        <FontAwesomeIcon icon={faBuilding} /> Profissional
                      </h5>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="form-group">
                      <label>Profissão</label>
                      <Field
                        name="profissao"
                        placeholder="Digite a Profissão"
                        className="form-control"
                        type="text"
                      />
                    </Col>

                    <Col className="form-group">
                      <label>Empresa</label>
                      <Field
                        name="empresa"
                        placeholder="Digite a empresa"
                        className="form-control"
                        type="text"
                      />
                    </Col>
                  </Row>

                  <div style={{ width: "100%" }}>
                    <AlertGeral retorno={retorno} />
                    <Spinner loading={loading} />
                  </div>

                  <ModalAddDiasPilates
                    listaDiaSel={listaDiaSel}
                    editListaDias={editListaDias}
                    listaDiasSemana={listaJornada}
                    setListaDiasSemana={setListaJornada}
                    countIDDiaSemana={countIDDiaSemana}
                    setCountIDDiaSemana={setCountIDDiaSemana}
                    show={showModalAddDias}
                    setShow={setShowModalAddDias}
                    exibeHoraFim={true}
                  />

                  {(checked || pessoa.profissional === true) && (
                    <Row>
                      <Col>
                        <Row>
                          <Col>
                            <h5>Jornada de trabalho</h5>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={6}>
                            <Card>
                              <Card.Body>
                                <Card.Title>
                                  <Button
                                    style={{ marginLeft: "10px" }}
                                    onClick={() => addDiaSemana()}
                                  >
                                    <FontAwesomeIcon
                                      className="icone"
                                      icon={faPlus}
                                    />{" "}
                                    Adicionar
                                  </Button>
                                </Card.Title>
                                <Card.Text>
                                  {listaJornada.map((item) => (
                                    <div
                                      key={item.id}
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <Alert
                                        variant="secondary"
                                        style={{
                                          textAlign: "center",
                                          height: "50px",
                                        }}
                                      >
                                        <label
                                          style={{
                                            marginTop: "7px",
                                            color: "green",
                                            fontSize: "16px",
                                          }}
                                        >
                                          <b>
                                            {diaIntToString(item.dia)} |{" "}
                                            {timeToView(item.horario)} -{" "}
                                            {timeToView(item.horario_fim)}
                                          </b>
                                        </label>
                                      </Alert>

                                      {/* <Button  style={{marginLeft:"10px", marginTop:"15px", width:"50px", height:"50px"}} onClick={() => editDiaSemana(item)}>
                                                                        <FontAwesomeIcon className="icone" icon={faPen} />
                                                                    </Button> */}

                                      <Button
                                        variant="danger"
                                        style={{
                                          marginLeft: "10px",
                                          marginTop: "15px",
                                          width: "50px",
                                          height: "50px",
                                        }}
                                        onClick={() => deleteDiaSemana(item)}
                                      >
                                        <FontAwesomeIcon
                                          className="icone"
                                          icon={faTrash}
                                        />
                                      </Button>
                                    </div>
                                  ))}
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    // <HorarioSemana />
                  )}

                  {agendaConsultorio.length > 0 && (
                    <TabelaAgendamento agenda={agendaConsultorio} />
                  )}

                  <Row
                    style={{ marginTop: "40px" }}
                    className="form-group text-center"
                  >
                    <Col className="text-center">
                      <Button variant="salvar" type="submit">
                        <FontAwesomeIcon className="icone" icon={faSave} />{" "}
                        Salvar
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </div>
        </Row>
      </div>
    </>
  );
};

export default CadastroPessoa;
