import { Field } from "formik";
import React from "react";

export default function CustomField({ ...props }) {
  let className = "";

  // se não tiver component seta o class se não da pau
  if (!props.noClass) {
    className = `form-control ${
      props.errors.hasOwnProperty(props.name) ? "is-invalid" : ""
    }`;
  }

  return (
    <>
      <Field {...props} className={className} />

      {props.errors.hasOwnProperty(props.name) && (
        <div style={{ fontSize: "16px", color: "red" }}>
          {props.errors[props.name]}
        </div>
      )}
    </>
  );
}
