import React, { useState, useEffect } from "react";
import { Button, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Creators as ContasReceberActions } from "../../Store/Controls/financeiros/contasReceber";
import MainMenu from "../../components/MainMenu";
import "../../Global/styleGeral.css";
import Listagem from "../../components/Listagens";
import { colunasContasReceber } from "../../components/Listagens/colunas";
import api from "../../services/api";
import { AlertGeral, requisitarAPI } from "../../components/AlertGeral";
import Spinner from "../../components/Spiner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faMoneyCheckAlt,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";

const ContasReceber = ({ history }) => {
  const [retorno, setRetorno] = useState({});
  const [loading, setLoading] = useState(true);

  const contasReceber = useSelector((state) => state.contasReceber);
  const dispatch = useDispatch();

  async function getDados() {
    let ret = await requisitarAPI(
      () => api.get("contasReceber"),
      "Falha ao obter dados do servidor"
    );
    setRetorno(ret);
    
    if (!ret.erro) {
      dispatch(ContasReceberActions.addContasReceber(ret.response.data));
    }
    setLoading(false);
  }

  useEffect(() => {
    getDados();
  }, []);

  return (
    <>
      <MainMenu navigation={history} />

      <div className="titulo-pagina">
        Contas a Receber
        <Button
          variant="principal"
          onClick={() => {
            history.push("/Financeiro/contasReceber/cadastro");
          }}
          alt="Adicione uma nova conta a receber"
          title="Adicione uma nova conta a receber"
        >
          {" "}
          <FontAwesomeIcon className="icone" icon={faPlus} /> Incluir Contas a
          Receber
        </Button>
        {/* <Button variant="secundario" onClick={()=>{history.push('/contas')}}> <FontAwesomeIcon className="icone" icon={faFileInvoice}/> Gerenciar Contas</Button>   */}
        <Button
          variant="secundario"
          onClick={() => {
            history.push("/formasPagamento");
          }}
          alt="Gerencie as formas de pagamento"
          title="Gerencie as formas de pagamento"
        >
          {" "}
          <FontAwesomeIcon className="icone" icon={faMoneyCheckAlt} /> Gerenciar
          Forma de Pagamento
        </Button>
        <a
          href="/ajuda#financeiro"
          alt="Clique para acessar página de ajuda"
          style={{ float: "right" }}
          title="Clique para acessar página de ajuda"
        >
          <FontAwesomeIcon className="icone" icon={faQuestionCircle} />
        </a>
        <AlertGeral retorno={retorno} />
        <Spinner loading={loading} />
      </div>
      
      <div className="container-principal">
        <Row>
          <Listagem
          
            dados={contasReceber}
            cols={colunasContasReceber}
            url_edit="financeiro/contasReceber/editar"
            tipo_delete="contasReceber"
            history={history}
            botaoExtra={{
              title: "Pagamento",
              icone: "money-bill",
              page: "financeiro/contasReceber/pagamento",
            }}
          />
        </Row>
      </div>
    </>
  );
};

export default ContasReceber;
