import { createActions, createReducer } from "reduxsauce";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  addContasPagar: ["contas"],
  addContaPagar: ["conta"],
  editContaPagar: ["id", "conta"],
  removeContaPagar: ["id"],
});

/**
 * Implementação das manipulações do State
 */
const INITIAL_STATE = [];

const addContasPagar = (state = INITIAL_STATE, action) => action.contas;

const add = (state = INITIAL_STATE, action) => [...state, action.conta];

const edit = (state = INITIAL_STATE, action) =>
  state.map((item) => {
    if (item.id === action.id) {
      return action.conta;
    } else {
      return item;
    }
  });

const remove = (state = INITIAL_STATE, action) =>
  state.filter((todo) => todo.id !== action.id);

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.ADD_CONTAS_PAGAR]: addContasPagar,
  [Types.ADD_CONTA_PAGAR]: add,
  [Types.EDIT_CONTA_PAGAR]: edit,
  [Types.REMOVE_CONTA_PAGAR]: remove,
});
