import React, { useState, useEffect } from "react";
import { Button, Card, Col, InputGroup, Row, Tab, Tabs } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Creators as CaixasActions } from "../../Store/Controls/caixas";
import MainMenu from "../../components/MainMenu";
import "../../Global/styleGeral.css";
import Listagem from "../../components/Listagens";
import {
  colunasCaixa,
  colunasContasReceberResumo,
} from "../../components/Listagens/colunas";
import api from "../../services/api";
import { requisitarAPI } from "../../components/AlertGeral";
import Spinner from "../../components/Spiner";
import moment from "moment";

const ResumoFinanceiro = ({ history }) => {
  const [loading, setLoading] = useState(true);
  const [contasAReceber, setContasAReceber] = useState([]);
  const [contasAPagar, setContasAPagar] = useState([]);

  // Filtro por data
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("Y-m-d")
  );
  const [endDate, setEndDate] = useState(moment(new Date()).format("Y-m-d"));

  const [resumo, setResumo] = useState({
    totalCaixas: 0.0,
    totalAReceber: 0.0,
    totalAPagar: 0.0,
  });

  // Carregar Dados
  useEffect(() => {
    // Setar data
     const start = moment().startOf("month").format("YYYY-MM-DD").toString();
    const end = moment()
      .endOf("month")
      .format("YYYY-MM-DD")
      .toString();
    setStartDate(start);
    setEndDate(end);

    (async () => {
      // Buscar Todos Os Caixas
      await (async () => {
        let ret = await requisitarAPI(
          () => api.get("caixas"),
          "Falha ao obter os caixas do servidor"
        );

        if (!ret.erro) {
          dispatch(CaixasActions.addCaixas(ret.response.data));
        }
      })();

      // Buscar Resumo
      await (async () => {
        let ret = await requisitarAPI(
          () => api.get(`resumo?startDate=${start}&endDate=${end}`),
          "Falha ao obter o resumo do servidor"
        );

        if (!ret.erro) {
          setResumo(ret.response.data);
          setContasAReceber(ret.response.data.contasReceber);
          setContasAPagar(ret.response.data.contasPagar);
        }
      })();

      setTimeout(() => setLoading(false), 800);
    })();
  }, []);

  const caixas = useSelector((state) => state.caixas);
  const dispatch = useDispatch();

  async function filtrarPorData(endDatee = null) {
    if (endDatee) {
      let ret = await requisitarAPI(
        () =>
          api.get(
            `resumo?startDate=${startDate}&endDate=${endDatee.toString()}`
          ),
        "Falha ao obter o resumo do servidor"
      );

      if (!ret.erro) {
        setResumo(ret.response.data);
        setContasAReceber(ret.response.data.contasReceber);
        setContasAPagar(ret.response.data.contasPagar);
      }
      return;
    }

     const start = moment().startOf("month").format("YYYY-MM-DD").toString();
    const end = moment()
      .endOf("month")
      .format("YYYY-MM-DD")
      .toString();

    if (!startDate || !endDate) {
      let ret = await requisitarAPI(
        () => api.get(`resumo?startDate=${start}&endDate=${end}`),
        "Falha ao obter o resumo do servidor"
      );

      if (!ret.erro) {
        setResumo(ret.response.data);
      }

      return;
    }
    let ret = await requisitarAPI(
      () => api.get(`resumo?startDate=${startDate}&endDate=${endDate}`),
      "Falha ao obter o resumo do servidor"
    );

    if (!ret.erro) {
      setResumo(ret.response.data);
    }
  }

  if (!loading) {
    return (
      <>
        <MainMenu navigation={history} />

        <div className="titulo-pagina">
          <Tabs
            activeKey={"resumo"}
            onSelect={(k) => {
              history.push(`/financeiro/${k}`);
            }}
            className="mb-3"
          >
            <Tab eventKey="resumo" title="Resumo">
              Resumo financeiro
              <Row className="mt-3">
                <Col className=" mb-3" sm={12} md={6} lg={3}>
                  <InputGroup>
                    <input
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      type="date"
                      className="form-control"
                    />
                  </InputGroup>
                </Col>
                <Col className=" mb-3" sm={12} md={6} lg={3}>
                  <InputGroup>
                    <input
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      type="date"
                      className="form-control"
                    />
					</InputGroup>
                </Col>
				<Col>
                    <Button
                      onClick={async () => {
                        setEndDate(
                          moment(endDate)
                            .subtract("month", 1)
                            .endOf("month")
                            .format("YYYY-MM-DD")
                            .toString()
                        );
                        await filtrarPorData(
                          moment(endDate)
                            .subtract("month", 1)
                            .endOf("month")
                            .format("YYYY-MM-DD")
                            .toString()
                        );
                      }}
                      variant="outline-secondary"
                      id="button-addon1"
                    >
                      Mês anterior
                    </Button>
                    <Button
                      onClick={async () => {
                        setEndDate(
                          moment(endDate)
                            .add("month", 1)
                            .endOf("month")
                            .format("YYYY-MM-DD")
                            .toString()
                        );

                        await filtrarPorData(
                          moment(endDate)
                            .add("month", 1)
                            .endOf("month")
                            .format("YYYY-MM-DD")
                            .toString()
                        );
                      }}
                      variant="outline-secondary"
                      id="button-addon1"
                    >
                      Próximo mes
                    </Button>
                  
                </Col>
                <Col className="mb-2">
                  <Button
                    onClick={() => filtrarPorData(null)}
                    type="button"
                    variant="primary"
                  >
                    Filtrar
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6} lg={3}>
                  <Card>
                    <Card.Body>
                      <Card.Title>Total caixas</Card.Title>
                      <Card.Text style={{ color: "green" }}>
                        {resumo.totalCaixas.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={12} md={6} lg={3}>
                  <Card>
                    <Card.Body>
                      <Card.Title>Total a receber</Card.Title>
                      <Card.Text style={{ color: "green" }}>
                        {resumo.totalAReceber.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={12} md={6} lg={3}>
                  <Card>
                    <Card.Body>
                      <Card.Title>Total a Pagar</Card.Title>
                      <Card.Text style={{ color: "red" }}>
                        {resumo.totalAPagar.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={12} md={6} lg={3}>
                  <Card>
                    <Card.Body>
                      <Card.Title>Liquido</Card.Title>
                      <Card.Text>
                        {(
                          resumo.totalAReceber +
                          resumo.totalCaixas -
                          resumo.totalAPagar
                        ).toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <div className="resumo mt-0">
                <Row>
                  <Col sm={12} md={6} lg={12} xl={12} xxl={6}>
                    <div className="resumo-card contas-receber">
                      <h4>Caixas</h4>
                      <div className="resumo-content">
                        <div>
                          <Listagem
                            dados={caixas}
                            cols={colunasCaixa}
                            noEdit={true}
                            noCancel={true}
                            tipo_delete="caixas"
                            history={history}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col sm={12} md={6} lg={12} xl={12}>
                    <div className="resumo-card contas-receber  mt-3">
                      <h4>Contas a receber</h4>
                      <div className="resumo-content mt-3">
                        <div>
                          <Listagem
                            filtrarPorData={false}
                            filtrarPorCaixa={false}
                            noEdit={true}
                            noCancel={true}
                            dados={contasAReceber}
                            cols={colunasContasReceberResumo}
                            history={history}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col sm={12} md={6} lg={12} xl={12}>
                    <div className="resumo-card contas-pagar mt-3">
                      <h4>Contas a pagar</h4>
                      <div className="resumo-content mt-3">
                        <div>
                          <Listagem
                            filtrarPorData={false}
                            filtrarPorCaixa={false}
                            dados={contasAPagar}
                            cols={colunasContasReceberResumo}
                            noEdit={true}
                            noCancel={true}
                            history={history}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Tab>
            <Tab eventKey="movimentacoes" title="Movimentações"></Tab>
            <Tab eventKey="contas-a-receber" title="Contas a Receber"></Tab>
            <Tab eventKey="contas-a-pagar" title="Contas a Pagar"></Tab>
            <Tab eventKey="caixas" title="Caixas"></Tab>
            <Tab
              eventKey="formas-de-pagamento"
              title="Formas de Pagamento"
            ></Tab>
            <Tab eventKey="comissoes" title="Comissões"></Tab>
          </Tabs>
        </div>
      </>
    );
  } else {
    return (
      <div
        style={{
          width: "220px",
          height: "30px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        <h4
          style={{
            color: "#123abc",
            fontSize: "18px",
            fontWeight: "bold",
            textTransform: "uppercase",
            margin: "10px 0px",
          }}
        >
          Carregando..
        </h4>
        <Spinner height={20} animation="grow" variant="primary" />
      </div>
    );
  }
};

export default ResumoFinanceiro;
