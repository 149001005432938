import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

const ModalGuiasVencendo = ({ show, setShow, arrVencendo = [] }) => {
  const [captionModal, setCaptionModal] = useState("");

  useEffect(() => {
    setCaptionModal("Alerta de vencimento das Guias");
  }, []);

  return (
    <Modal show={show} onHide={() => setShow(false)} animation={true}>
      <Modal.Header closeButton>
        <Modal.Title> {captionModal} </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {arrVencendo.map((item, index) => (
          <div key={index.numero_guia}>
            <p>
              Guia N° {item.numero_guia} - venc.{" "}
              {format(new Date(item.data), "dd/MM/yyyy")} - {item.paciente}
            </p>
          </div>
        ))}
      </Modal.Body>
    </Modal>
  );
};

export default ModalGuiasVencendo;
