import React, { useEffect, useState } from "react";
import { Button, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { AlertGeral, requisitarAPI } from "../../components/AlertGeral";
import Listagem from "../../components/ListagemProcedimento";
import { colunasListaTagsConsultorio } from "../../components/ListagemProcedimento/colunas";
import MainMenu from "../../components/MainMenu";
import Spinner from "../../components/Spiner";
import "../../Global/styleGeral.css";
import api from "../../services/api";
import { Creators as ListaProcedimentosAction } from "../../Store/Controls/listaProcedimentos";

import { faPlus, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ListaProcedimentos = ({ history, match }) => {
  const [retorno, setRetorno] = useState({}); // resposta do servidor
  const [loading, setLoading] = useState(true); // carregando

  const listasEsperas = useSelector((state) => state.listaProcedimentos);
  const dispatch = useDispatch();
  const tipo = match.params.tipo;
  const url_edit = `listaProcedimentos/editar/${tipo}`; // url para editar

  async function getDados() {
    let ret = await requisitarAPI(
      () => api.get(`listaProcedimentos/${tipo}`),
      "Falha ao obter dados do servidor"
    );

    setRetorno(ret.response.data);

    if (!ret.erro) {
      dispatch(ListaProcedimentosAction.addProcedimentos(ret.response.data));
    }

    setLoading(false);
  }

  useEffect(() => {
    getDados();
  }, []);

  return (
    <>
      <MainMenu navigation={history} />

      <div className="titulo-pagina">
        Lista de Procedimentos
        <Button
          variant="principal"
          onClick={() => {
            history.push(`/listaProcedimentos/cadastro/${tipo}`);
          }}
          alt="Adicione uma nova tag"
          title="Adicione uma nova tag"
        >
          <FontAwesomeIcon className="icone" icon={faPlus} /> Novo procedimento
        </Button>
        <a
          href="/ajuda"
          alt="Clique para acessar página de ajuda"
          style={{ float: "right" }}
          title="Clique para acessar página de ajuda"
        >
          <FontAwesomeIcon className="icone" icon={faQuestionCircle} />
        </a>
        <AlertGeral retorno={retorno} />
        <Spinner loading={loading} />
      </div>

      <div className="container-principal">
        <Row>
          <Listagem
            dados={listasEsperas}
            cols={colunasListaTagsConsultorio}
            url_edit={url_edit}
            tipo_delete="listaProcedimentos"
            history={history}
          />
        </Row>
      </div>
    </>
  );
};

export default ListaProcedimentos;
