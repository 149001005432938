import React, { useState, useEffect } from "react";
import { Button, Card, Col, Row, Tab, Tabs } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Creators as ContasReceberActions } from "../../Store/Controls/financeiros/contasReceber";
import { Creators as ContasPagarActions } from "../../Store/Controls/financeiros/contasPagar";
import { Creators as CaixasActions } from "../../Store/Controls/caixas";
import { Creators as MovimentacoesActions } from "../../Store/Controls/movimentacoes";
import MainMenu from "../../components/MainMenu";
import "../../Global/styleGeral.css";
import Listagem from "../../components/Listagens";
import {
  colunasCaixa,
  colunasContasReceber,
  colunasContasReceberResumo,
  colunasMovimentacoes,
} from "../../components/Listagens/colunas";
import api from "../../services/api";
import { AlertGeral, requisitarAPI } from "../../components/AlertGeral";
import Spinner from "../../components/Spiner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import FormasPagamento from "./formasPagamento";
import queryString from "query-string";

const MovimentacoesFinanceiro = ({ history }) => {
  const [loading, setLoading] = useState(true);

  const movimentacoes = useSelector((state) => state.movimentacoes);
  const dispatch = useDispatch();

  useEffect(() => {
    getDados();
  }, []);

  async function getDados() {
    // Movimentações
    async function findMovimentacoes() {
      let ret = await requisitarAPI(
        () => api.get("movimentacoes"),
        "Falha ao obter as movimentações do servidor"
      );

      if (!ret.erro) {
        dispatch(MovimentacoesActions.addMovimentacoes(ret.response.data));
      }
    }
    await findMovimentacoes();

    setTimeout(() => setLoading(false), 800);
  }

  if (!loading) {
    return (
      <>
        <MainMenu navigation={history} />

        <div className="titulo-pagina">
          <Tabs
            activeKey={"movimentacoes"}
            onSelect={(k) => {
              history.push(`/financeiro/${k}`);
            }}
            className="mb-3"
          >
            <Tab eventKey="resumo" title="Resumo"></Tab>
            <Tab eventKey="movimentacoes" title="Movimentações">
              Movimentações
              <Button
                variant="principal"
                onClick={() => {
                  history.push("/financeiro/movimentacoes/cadastro");
                }}
                alt="Adicione uma nova movimentação"
                title="Adicione uma nova movimentação"
              >
                <FontAwesomeIcon className="icone" icon={faPlus} /> Incluir uma
                nova Movimentação
              </Button>
              <div
                style={{ margin: 0, marginTop: 20 }}
                className="container-principal"
              >
                <Row>
                  <Listagem
                    dados={movimentacoes}
                    noEdit={true}
                    cols={colunasMovimentacoes}
                    tipo_delete="movimentacoes"
                    history={history}
                  />
                </Row>
              </div>
            </Tab>
            <Tab eventKey="contas-a-receber" title="Contas a Receber"></Tab>
            <Tab eventKey="contas-a-pagar" title="Contas a Pagar"></Tab>
            <Tab eventKey="caixas" title="Caixas"></Tab>
            <Tab
              eventKey="formas-de-pagamento"
              title="Formas de Pagamento"
            ></Tab>
            <Tab eventKey="comissoes" title="Comissões"></Tab>
          </Tabs>
        </div>
      </>
    );
  } else {
    return (
      <div
        style={{
          width: "220px",
          height: "30px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        <h4
          style={{
            color: "#123abc",
            fontSize: "18px",
            fontWeight: "bold",
            textTransform: "uppercase",
            margin: "10px 0px",
          }}
        >
          Carregando..
        </h4>
        <Spinner height={20} animation="grow" variant="primary" />
      </div>
    );
  }
};

export default MovimentacoesFinanceiro;
