import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAddressCard,
  faCalendar,
  faCog,
  faDollarSign,
  faFileMedicalAlt,
  faHome,
  faSync,
  faUserCog,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Row, Table } from "react-bootstrap";
import MainMenu from "../../components/MainMenu/";
import "../../Global/styleGeral.css";

library.add(faUsers, faHome, faUserCog);

export default function Clientes({ history }) {
  return (
    <div>
      <MainMenu navigation={history} />

      <div className="titulo-pagina"> Central de Ajuda </div>
      <p style={{ textAlign: "left", paddingLeft: "30px" }}>
        Conteúdo explicativo das principais funcionalidades da aplicação.
      </p>

      <div className="container-principal">
        <span id="principal"></span>
        <h2>
          <FontAwesomeIcon icon={faHome} /> Principal
        </h2>
        <Row style={{ textAlign: "left", paddingLeft: "15px" }}>
          <p style={{ textAlign: "left" }}>
            A <a href="#@">página Principal</a> possuí area restrita para
            notificações e também lista as novas mensagens recebidas de
            Usuários.
            <br />
            <br />
          </p>
        </Row>
      </div>

      <div className="container-principal">
        <span id="consultorio"></span>
        <h2>
          <FontAwesomeIcon icon={faCalendar} /> Consultório{" "}
        </h2>
        <Row style={{ textAlign: "left", paddingLeft: "15px" }}>
          <p style={{ textAlign: "left" }}>
            O menu <strong>Consultório</strong> possui 3 seções,{" "}
            <a href="/consultorio/agenda">Agenda</a>,{" "}
            <a href="/consultorio/listaEspera">Lista de Espera</a> e{" "}
            <a href="/consultorio/eventosCancelados">Cancelamentos</a>.<br />
            <br />A <a href="/consultorio/listaEspera">Lista de Espera</a> traz
            a listagem com <strong>Nome e Telefone.</strong> dos pacientes na
            fila. O cadastro de um novo contato pode ser feito em{" "}
            <a href="/consultorio/listaEspera/cadastro/">"+ Novo Contato"</a>,
            informando Nome, Telefone e Observações.
            <br />
            <br />A página de{" "}
            <a href="/consultorio/eventosCancelados">Cancelamentos</a> traz a{" "}
            <strong>listagem automática</strong> dos pacientes que tiveram os
            eventos cancelados na <a href="/consultorio/agenda">Agenda</a>.{" "}
            <br />
            <br />
            <strong>Vídeo:</strong>
          </p>
        </Row>
      </div>

      <div className="container-principal">
        <span id="pilates"></span>
        <h2>
          <FontAwesomeIcon icon={faCalendar} /> Pilates{" "}
        </h2>
        <Row style={{ textAlign: "left", paddingLeft: "15px" }}>
          <p style={{ textAlign: "left" }}>
            O menu <strong> Pilates</strong> possui 3 seções,{" "}
            <a href="/pilates/agenda">Agenda</a>,{" "}
            <a href="/pilates/listaEspera">Lista de Espera</a> e{" "}
            <a href="/pilates/eventosCancelados">Cancelamentos</a>.<br />
            <br />A <a href="/pilates/listaEspera">Lista de Espera</a> traz a
            listagem com <strong>Nome e Telefone.</strong> dos alunos na fila. O
            cadastro de um novo contato pode ser feito em{" "}
            <a href="/pilates/listaEspera/cadastro/">"+ Novo Contato"</a>,
            informando Nome, Telefone e Observações.
            <br />
            <br />A página de{" "}
            <a href="/pilates/eventosCancelados">Cancelamentos</a> traz a{" "}
            <strong>listagem automática</strong> dos alunos que tiveram os
            eventos cancelados na <a href="/pilates/agenda">Agenda</a>. <br />
            <br />
            <strong>Vídeo:</strong>
          </p>
        </Row>
      </div>

      <div className="container-principal">
        <span id="financeiro"></span>
        <h2>
          <FontAwesomeIcon icon={faDollarSign} /> Financeiro
        </h2>
        <Row style={{ textAlign: "left", paddingLeft: "15px" }}>
          <p style={{ textAlign: "left" }}>
            A <strong>página Financeiro</strong> traz a listagem das{" "}
            <a href="/financeiro/contasReceber">Contas a Receber</a> e o{" "}
            <a href="/formasPagamento/cadastro">
              Cadastro de Formas de Pagamento
            </a>
            .<br />
            <br />
            <strong>Contas a Receber</strong>
            <br />A listagem das contas a receber lista o{" "}
            <strong>
              Status, Parcelas, Vencimento, Pessoa, Descrição e Valor.
            </strong>
            <br />
            Para <strong>incluir uma nova conta a receber</strong> clique em{" "}
            <a href="/Financeiro/contasReceber/cadastro">
              "+ Incluir Contas a Receber"
            </a>
            .
          </p>
          <Table striped bordered hover size="sm" style={{ fontSize: "12px" }}>
            <thead>
              <tr>
                <th>Campo</th>
                <th>Instrução</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Pessoa</td>
                <td>
                  Campo selecão obrigatório para o novo registro de contas a
                  receber. O campo habilita todos as pessoas criados na{" "}
                  <a href="/pessoas"> página de Pessoa.</a>
                </td>
              </tr>
              <tr>
                <td>Data de Geração</td>
                <td>
                  Campo de data obrigatório que por padrão é a data atual.
                </td>
              </tr>
              <tr>
                <td>Descrição</td>
                <td>Campo obrigatório para a descrição da baixa ou conta.</td>
              </tr>
              <tr>
                <td>Valor</td>
                <td>Campo de valor obrigatório da conta.</td>
              </tr>
              <tr>
                <td>Data de Vencimento</td>
                <td>
                  Campo de data obrigatório que define a data de vencimento da
                  conta. <strong>Caso vencida é notificada na listagem.</strong>
                </td>
              </tr>
              <tr>
                <td>Parcelas</td>
                <td>Campo para registro do número de parcelas da conta.</td>
              </tr>
              <tr>
                <td>Intervalo de Dias</td>
                <td>Campo para registro do intervalo de dias da conta.</td>
              </tr>
            </tbody>
          </Table>
          <p>
            <br />
            <strong>Gerenciar Formas de Pagamento</strong>
            <br />
            <br />A página de{" "}
            <a href="/formasPagamento">Gerenciar Formas de Pagamento</a> lista
            as formas de pagamento com os campos{" "}
            <strong>Código e Descrição.</strong> O cadastro pode ser feita{" "}
            <a href="/formasPagamento/cadastro">
              "+ Cadastrar Forma de Pagamento"
            </a>
            .
          </p>

          <Table striped bordered hover size="sm" style={{ fontSize: "12px" }}>
            <thead>
              <tr>
                <th>Campo</th>
                <th>Instrução</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Descrição</td>
                <td>
                  Campo obrigatório para o novo registro da forma de pagamento.{" "}
                  <i>
                    Exemplos: Cartão de Crédito, Boleto, Depósito ou Cheque.
                  </i>
                </td>
              </tr>
            </tbody>
          </Table>
        </Row>
      </div>

      <div className="container-principal">
        <span id="pessoas"></span>
        <h2>
          {" "}
          <FontAwesomeIcon icon={faUsers} /> Pessoas
        </h2>
        <Row style={{ textAlign: "left", paddingLeft: "15px" }}>
          <p style={{ textAlign: "left" }}>
            A <a href="/pessoas">página Pessoas</a> traz a listagem das pessoas
            (paciente, professor ou aluno) com as principais colunas:{" "}
            <strong>
              Nome Completo, Tipo, CPF/CNPJ, Logradouro e Celular.
            </strong>
            <br />
            Para <strong>incluir uma nova Pessoa</strong> clique em{" "}
            <a href="/pessoas/cadastro">"+ Nova Pessoa"</a>.<br />
            <br />
            <strong>Campos:</strong>
          </p>

          <Table striped bordered hover size="sm" style={{ fontSize: "12px" }}>
            <thead>
              <tr>
                <th>Campo</th>
                <th>Instrução</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Tipo do Cadastro</td>
                <td>
                  Campo obrigatório para controle de usuário. Esse campo permite
                  o controle nas agendas. As opções são{" "}
                  <strong>
                    Paciente (Agenda Consultório), Aluno (Agenda Pilates) ou
                    Professor (Agenda Pilates).
                  </strong>
                </td>
              </tr>
              <tr>
                <td>Tipo de Pessoa</td>
                <td>
                  Identificação da pessoa, sendo <strong>CPF ou CNPJ</strong>.
                  Conforme selecionado, o campo é ajustado para preenchimento.
                </td>
              </tr>
              <tr>
                <td>Nome Completo</td>
                <td>
                  Campo obrigatório que define o nome do paciente. Visualizado
                  na listagem.
                </td>
              </tr>
              <tr>
                <td>Data de Nascimento</td>
                <td>
                  Campo para data de nascimento do usuário. Ao preencher a data,
                  o campo <strong>Idade é preenchido automáticamente.</strong>
                </td>
              </tr>
              <tr>
                <td>Como encontrou</td>
                <td>
                  Campo de seleção, onde informa como o usuário encontrou o seu
                  negócio.
                </td>
              </tr>

              <tr>
                <td>E-mail</td>
                <td>Campo para registro do e-mail.</td>
              </tr>
              <tr>
                <td>Telefone</td>
                <td>Campo para registro do telefone.</td>
              </tr>
              <tr>
                <td>Celular</td>
                <td>Campo para registro do celular.</td>
              </tr>
              <tr>
                <td>Logradouro</td>
                <td>Campo para registro do logradouro</td>
              </tr>
              <tr>
                <td>Número</td>
                <td>Campo para registro do número do complemento</td>
              </tr>
              <tr>
                <td>Complemento</td>
                <td>
                  Campo para registro do complemento (Casa, Bairro,
                  Apartamento...)
                </td>
              </tr>
              <tr>
                <td>Bairro</td>
                <td>Campo para registro do bairro.</td>
              </tr>
              <tr>
                <td>Cidade</td>
                <td>Campo para registro da cidade.</td>
              </tr>
              <tr>
                <td>Estado</td>
                <td>Campo para registro do estado.</td>
              </tr>
              <tr>
                <td>Profissão</td>
                <td>Campo para registro da profissão.</td>
              </tr>
              <tr>
                <td>Empresa</td>
                <td>Campo para registro da empresa.</td>
              </tr>
            </tbody>
          </Table>
        </Row>
      </div>

      <div className="container-principal">
        <span id="diagnosticos"></span>
        <h2>
          <FontAwesomeIcon icon={faFileMedicalAlt} /> Diagnósticos
        </h2>
        <Row style={{ paddingLeft: "15px" }}>
          <p style={{ textAlign: "left" }}>
            A página de <a href="/diagnosticos">Diagnósticos</a> realiza o
            controle dos diagnósticos das pessoas. A página lista os
            Diagnósticos com os campos{" "}
            <strong> Pessoa e Queixa Principal.</strong>
            <br />É possível <strong>criar um novo diagnósticos </strong>{" "}
            clicando em{" "}
            <a href="/diagnosticos/cadastro">"+ Novo Diagnóstico"</a>.<br />
            <br />
            Ao cadastrar um novo Diagnóstico, a{" "}
            <strong>aba Geral é habilitada</strong> e a aba Evolucação
            desabilitada até o registro do novo Diagnóstico.
            <br />
            <br />
            <h3>Aba Geral</h3>
            <strong>Campos:</strong>
            <br />
          </p>
          <Table striped bordered hover size="sm" style={{ fontSize: "12px" }}>
            <thead>
              <tr>
                <th>Campo</th>
                <th>Instrução</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Pessoa</td>
                <td>
                  Campo selecão obrigatório para o novo registro de diagnóstico.
                  O campo habilita todos os pacientes criados na{" "}
                  <a href="/pessoas"> página de Pessoas.</a>
                </td>
              </tr>
              <tr>
                <td>Queixa Principal</td>
                <td>
                  Campo de comentário obrigatório para registrar a queixa do
                  paciente.
                </td>
              </tr>
              <tr>
                <td>Tipo de Dor</td>
                <td>
                  Campo que define o tipo de dor do paciente (Usar modelos
                  padrões de texto).
                </td>
              </tr>
              <tr>
                <td>Escala Visual Analógica (EVA)</td>
                <td>Campo de seleção de 1 a 10 de Escala Visual Analógica.</td>
              </tr>
              <tr>
                <td>Qualidade do Sono</td>
                <td>
                  Campo de seleção com Leve, Moderado e Baixo para qualidade do
                  sono do paciente.
                </td>
              </tr>
              <tr>
                <td>Hábitos de Vida (HV)</td>
                <td>
                  Campo de comentário para registro dos hábitos de vida do
                  paciente.
                </td>
              </tr>
              <tr>
                <td>Histórico de Doença Atual (HDA)</td>
                <td>
                  Campo de comentário para registro do histórico de doença atual
                  do paciente.
                </td>
              </tr>
              <tr>
                <td>Histórico de Doença Progressiva (HDP)</td>
                <td>
                  Campo de comentário para registro do histórico de doença
                  progressiva do paciente.
                </td>
              </tr>
              <tr>
                <td>Frequência</td>
                <td>
                  Campo de seleção com Variável e Constante para registro da
                  frequência de dor do paciente.{" "}
                </td>
              </tr>
              <tr>
                <td>Fator de Piora</td>
                <td>
                  Campo de comentário para registro do fator de piora do
                  paciente.
                </td>
              </tr>
              <tr>
                <td>Fator de Melhora</td>
                <td>
                  Campo de comentário para registro do fator de melhora do
                  paciente.
                </td>
              </tr>
              <tr>
                <td>Exames Complementares</td>
                <td>
                  Campo de comentário para registro dos exames complementares do
                  paciente.
                </td>
              </tr>
            </tbody>
          </Table>

          <p style={{ textAlign: "left" }}>
            <br />
            <h3>Aba Evolução</h3>
            <br />
            A aba de Evolução registra as evoluções do paciente. Podendo ter
            várias evoluções para um único paciente. <br />
            Os registros das evoluções são exibidos em caixas, onde é possível{" "}
            <strong>Editar e Excluir</strong> cada um deles.
            <br />
            <br />
            <strong>Campos:</strong>
            <br />
          </p>
          <Table striped bordered hover size="sm" style={{ fontSize: "12px" }}>
            <thead>
              <tr>
                <th>Campo</th>
                <th>Instrução</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Evolução</td>
                <td>
                  Campo de descrição obrigatório para o descrever a evolução do
                  paciente.
                </td>
              </tr>
              <tr>
                <td>Data da Evolução</td>
                <td>
                  Campo de data obrigatório para registrar a evolução. Ao clicar
                  o calendário é mostrado para definir data e hora (por padrão é
                  preenchido data e hora atual).
                </td>
              </tr>
            </tbody>
          </Table>
        </Row>
      </div>

      <div className="container-principal">
        <span id="menu"></span>
        <h2 id="menu-configuracoes">
          {" "}
          <FontAwesomeIcon icon={faCog} /> Menu de Configurações
        </h2>
        <Row style={{ textAlign: "left", paddingLeft: "15px" }}>
          <p style={{ textAlign: "left" }}>
            <a href="/versao"> Versão da Aplicação:</a>
            <br />
            <strong>Objetivo:</strong> apresentar o controle de versões da
            aplicação. A tela lista todas as versões da aplicação, tanto versões
            antigas quanto as próximas atualizações.
            <br />
            <br />
            <p>
              Por padrão, a versão em produção está marcada com{" "}
              <span>
                <strong>(atual)</strong>
              </span>
              .
            </p>
            <p>
              Todas as versões são marcadas com{" "}
              <strong>
                Versão, Data de Atualização e Correções/Melhorias.
              </strong>
            </p>
          </p>

          <hr />

          <p style={{ textAlign: "left" }}>
            <a href="/usuarios">
              <FontAwesomeIcon icon={faUserCog} /> Gerenciar Usuários:
            </a>
            <br />
            <strong>Objetivo:</strong> realiza o controle dos usuários de acesso
            da aplicação. A tela lista todos os usuários com os campos{" "}
            <strong>Nome, Usuário e Perfil.</strong> Ainda é possível{" "}
            <strong>Editar e Excluir </strong> os registros.
            <br />
            <br />
            <p>
              É possível <strong>criar um novo usuário </strong> clicando em{" "}
              <a href="/cadastro_usuario">"+ Novo Usuário"</a>.
            </p>
            <strong>Campos:</strong>
            <br />
            <Table
              striped
              bordered
              hover
              size="sm"
              style={{ fontSize: "12px" }}
            >
              <thead>
                <tr>
                  <th>Campo</th>
                  <th>Instrução</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Nome de Usuário</td>
                  <td>
                    Campo obrigatório para acesso da aplicação na tela de login
                    (não utilizar carecteres especiais)
                  </td>
                </tr>
                <tr>
                  <td>Senha de Usuário</td>
                  <td>
                    Campo obrigatório de senha para acesso do usuário na
                    aplicação. Não é possível restaurar a senha ou visualizar
                    posteriormente. Recomendado uso de senhas fortes. A
                    alteração da senha é feita em{" "}
                    <a href="#">Esqueceu a senha?</a>
                  </td>
                </tr>
                <tr>
                  <td>Perfil de Usuário</td>
                  <td>
                    Campo obrigatório que define as políticas do usuário, quais
                    permissões serão habilitadas. Um novo perfil pode ser criado
                    em{" "}
                    <a href="http://localhost:3000/cadastro_perfil">
                      Gerenciar Perfis > + Novo Perfil.
                    </a>
                  </td>
                </tr>
              </tbody>
            </Table>
          </p>

          <hr />

          <p style={{ textAlign: "left" }}>
            <a href="/Perfis">
              {" "}
              <FontAwesomeIcon icon={faAddressCard} /> Gerenciar Perfis:
            </a>
            <br />
            <strong>Objetivo:</strong> realiza o controle dos perfils de
            usuário, permitindo ou não acesso as funcionalidades da aplicação. A
            tela lista todos os perfis com o campo <strong>Nome.</strong> É
            possível <strong>Editar e Excluir </strong> os registros.
            <br />
            <br />
            <p>
              É possível <strong>criar um novo perfil </strong> clicando em{" "}
              <a href="/cadastro_perfil">"+ Novo Perfil"</a>.
            </p>
            <strong>Campos:</strong>
            <br />
            <Table
              striped
              bordered
              hover
              size="sm"
              style={{ fontSize: "12px" }}
            >
              <thead>
                <tr>
                  <th>Campo</th>
                  <th>Instrução</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Nome</td>
                  <td>
                    Campo obrigatório para identificar o usuário. Ex:
                    Administrador, Secretária ou Manunteção.
                  </td>
                </tr>
                <tr>
                  <td>Permissões (Checklists)</td>
                  <td>
                    Os campos são assinalados para autorização do Perfil. Caso
                    não esteja assinalado o Perfil não tem permissão.
                  </td>
                </tr>
              </tbody>
            </Table>
          </p>

          <hr />

          <p style={{ textAlign: "left" }}>
            <strong>
              <FontAwesomeIcon icon={faSync} /> Atualizar Permissões:
            </strong>
            <br />
            <strong>Objetivo:</strong> atualizar permissões dos Perfis aos
            Usuários.
            <br />
            Caso seja feita alguma modicação no Perfil, ao clicar nesse item, as
            permissões serão atualizadas, permitindo ou não as novas funções aos
            usuários cadastrados.
            <br />
          </p>
        </Row>
      </div>
    </div>
  );
}
