/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Button, Col, Row } from "react-bootstrap";
import { Formik, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { Creators as FormasPagamentoActions } from "../../Store/Controls/financeiros/formasPagamento";
import "../../Global/styleGeral.css";
import { AlertGeral, requisitarAPI } from "../../components/AlertGeral";
import api from "../../services/api";
import MainMenu from "../../components/MainMenu";
import FieldValidate from "../../components/Form/FieldValidate.js";
import Spinner from "../../components/Spiner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";

const URL_FORMAS_PAGAMENTO = "formasPagamento";

const CadastroFormaPagamento = ({ history, match }) => {
  const [editForm, setEditForm] = useState(false);
  const [formaPagamento, setFormaPagamento] = useState({ descricao: "" });
  const [captionForm, setCaptionForm] = useState(
    "Cadastro de Forma de Pagamento"
  );
  const [retorno, setRetorno] = useState({});
  const [loading, setLoading] = useState(true);

  const formasPagamento = useSelector((state) => state.formasPagamento);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);

    if (typeof match.params.id !== "undefined") {
      setCaptionForm("Edição de Forma de Pagamento");
      setEditForm(true);
      let formaPagamento = formasPagamento.find(
        (item) => item.id == match.params.id
      );

      if (typeof formaPagamento === "undefined") {
        history.goBack();
        return;
      }

      setFormaPagamento(formaPagamento);
    }

    setLoading(false);
  }, [formasPagamento, match.params.id]);

  const schema = Yup.object().shape({
    descricao: Yup.string().required("Informe a Descrição"),
  });

  const handleSubmit = async (values) => {
    setLoading(true);

    let ret = {};
    if (editForm) {
      ret = await requisitarAPI(
        () => api.put(URL_FORMAS_PAGAMENTO + "/" + match.params.id, values),
        "Falha ao editar Forma de Pagamento"
      );
      setRetorno(ret);

      if (!ret.erro) {
        dispatch(
          FormasPagamentoActions.editFormaPagamento(
            match.params.id,
            ret.response.data
          )
        );
      }
    } else {
      ret = await requisitarAPI(
        () => api.post(URL_FORMAS_PAGAMENTO, values),
        "Falha ao cadastar Forma de Pagamento"
      );
      setRetorno(ret);

      if (!ret.erro) {
        dispatch(FormasPagamentoActions.addFormaPagamento(ret.response));
      }
    }

    setLoading(false);

    if (!ret.erro) {
      history.goBack();
    }
  };

  return (
    <>
      <MainMenu navigation={history} />

      <div className="titulo-pagina">
        {" "}
        {captionForm}
        <Button
          variant="voltar"
          onClick={() => {
            history.goBack();
          }}
        >
          <FontAwesomeIcon className="icone" icon={faChevronLeft} /> Voltar para
          Gerenciar Formas de Pagamentos
        </Button>
        <a
          href="/ajuda#financeiro"
          alt="Clique para acessar página de ajuda"
          style={{ float: "right" }}
          title="Clique para acessar página de ajuda"
        >
          <FontAwesomeIcon className="icone" icon={faQuestionCircle} />
        </a>
        <AlertGeral retorno={retorno} />
        <Spinner loading={loading} />
      </div>

      <div className="container-principal">
        <Formik
          enableReinitialize
          initialValues={formaPagamento}
          onSubmit={handleSubmit}
          validationSchema={schema}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ errors }) => (
            <Form>
              <div>
                <div className="form-group">
                  <label className="obrigatorio">Descrição*</label>
                  <FieldValidate
                    name="descricao"
                    placeholder="Digite a descrição"
                    type="text"
                    errors={errors}
                  />
                </div>

                <Row>
                  <Col>
                    <AlertGeral retorno={retorno} />
                    <Spinner loading={loading} />
                  </Col>
                </Row>

                <Row
                  style={{ marginTop: "40px" }}
                  className="form-group text-center"
                >
                  <Col className="text-center">
                    <Button variant="salvar" type="submit">
                      <FontAwesomeIcon className="icone" icon="save" /> Salvar
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default CadastroFormaPagamento;
