import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as Sentry from "@sentry/react";


Sentry.init({
  dsn: "https://7041d723c6ce4c668b2d74caa8b60b2d@o4504888820695040.ingest.sentry.io/4505128024866816",
  //integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

/* import 'bootstrap/dist/css/bootstrap.min.css'; */
ReactDOM.render(<App />, document.getElementById('root'));
