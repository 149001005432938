import { combineReducers } from "redux";

import eventosConsultorio from "./agendas/eventosConsultorio";
import caixas from "./caixas";
import config from "./config";
import diagnosticos from "./diagnosticos";
import contas from "./financeiros/contas";
import contasPagar from "./financeiros/contasPagar";
import contasReceber from "./financeiros/contasReceber";
import formasPagamento from "./financeiros/formasPagamento";
import guias from "./guias";
import listaEspera from "./listasEspera";
import listaTags from "./listaTags";
import listaProcedimentos from "./listaProcedimentos";
import movimentacoes from "./movimentacoes";
import perfis from "./perfis";
import notasFiscais from "./pessoas/notasFiscais";
import pessoas from "./pessoas/pessoas";
import fornecedores from "./fornecedores/fornecedores";
import usuarioLogado from "./usuarioLogado";
import usuarios from "./usuarios";

export default combineReducers({
  usuarios,
  perfis,
  usuarioLogado,
  pessoas,
  diagnosticos,
  contas,
  formasPagamento,
  contasReceber,
  contasPagar,
  eventosConsultorio,
  caixas,
  movimentacoes,
  notasFiscais,
  listaEspera,
  listaTags,
  listaProcedimentos,
  config,
  guias,
  fornecedores
});
