/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Button, Row, Col } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { Creators as DiagnosticoActions } from "../../Store/Controls/diagnosticos";
import "../../Global/styleGeral.css";
import {
  calcularIdade,
  dateToView,
  MesesEntreDatas,
  DiasEntreDatas,
} from "../../Global/funcoes.js";
import {  requisitarAPI } from "../../components/AlertGeral";
import { SelectComponent } from "../../components/Form/Select.js";
import { getPessoasForSelect } from "../../Global/chamadasAPI";
import api from "../../services/api";
import FieldValidate from "../../components/Form/FieldValidate.js";
import ModalDeEscolha from "../../components/ModalDeEscolha";

const FormDiagnostico = ({
  diagnostico,
  editForm,
  setDiagnostico,
  match,
  handlePrint,
}) => {
  const [retorno, setRetorno] = useState({});
  const [loading, setLoading] = useState(true);
  const [pessoas, setPessoas] = useState([{}]);
  const [pessoasSelect, setPessoasSelect] = useState([{}]);
  const [pessoaSelected, setPessoaSelected] = useState(null);
  const [profissionais, setProfissionais] = useState([{}]);

  const dispatch = useDispatch();

  async function getPessoas() {
    let pessoasAPI = [{}];
    let pessoasSel = [{}];

    pessoasAPI = await requisitarAPI(
      () =>
        api.get("pessoas", { params: { tipo: "P", listarConsultas: true } }),
      "Falha ao obter pessoas do servidor"
    );

    if (!pessoasAPI.erro) {
      pessoasAPI.response.data.forEach((pessoa) => {
        pessoasSel.push({ label: pessoa.nome, value: pessoa.id });
      });
    }

    setPessoas(pessoasAPI.response.data);
    setPessoasSelect(pessoasSel);
    alterarPessoaSelecionada(diagnostico.id_pessoa, pessoasAPI.response.data);
    setProfissionais(await getPessoasForSelect("PF"));
  }

  const alterarPessoaSelecionada = (value, pessoasLoading) => {
    let pes = pessoas;
    if (pessoasLoading) {
      pes = pessoasLoading;
    }

    pes.forEach((pessoa) => {
      if (pessoa.id === value.value) {
        if (pessoa.data_nascimento) {
          const data = new Date(pessoa.data_nascimento);
          pessoa.idade = calcularIdade(
            data.getFullYear(),
            data.getMonth() + 1,
            data.getDate
          );
          if (pessoa.idade > 0) {
            pessoa.idade = pessoa.idade + " anos";
          } else {
            const dias = DiasEntreDatas(data, new Date());
            let mes = MesesEntreDatas(data, new Date());
            if (mes > 1) {
              mes = mes + " meses";
            } else {
              mes = mes + " mês";
            }
            if (dias >= 30) {
              pessoa.idade = mes;
            } else {
              pessoa.idade = dias + " dias";
            }
          }
        }
        setPessoaSelected(pessoa);
        return;
      }
    });
  };

  useEffect(() => {
    setLoading(true);

    getPessoas();
    setLoading(false);
  }, [diagnostico]);

  const schema = Yup.object().shape({
    id_profissional: Yup.number().required("Informe o Profissional"),
    id_pessoa: Yup.number().required("Informe a Pessoa"),
    queixa_principal: Yup.string().required("Informe a Queixa Principal"),
  });

  const handleSubmit = async (values) => {
    setLoading(true);

    let ret = {};
    if (editForm) {
      ret = await requisitarAPI(
        () => api.put("diagnosticos/" + diagnostico.id, values),
        "Falha ao editar Diagnóstico"
      );

      setRetorno(ret);

      if (!ret.erro) {
        dispatch(
          DiagnosticoActions.editDiagnostico(diagnostico.id, ret.response.data)
        );
      }
    } else {
      ret = await requisitarAPI(
        () => api.post("diagnosticos", values),
        "Falha ao cadastar diagnostico"
      );
      setRetorno(ret);

      if (!ret.erro) {
        match.params.id = ret.response.data.id;
        dispatch(DiagnosticoActions.addDiagnostico(ret.response.data));
      }
    }

    setLoading(false);

    if (!ret.erro) {
      setDiagnostico(ret.response.data);
      ret.messageSuccess = "Diagnóstico gravado com sucesso!";
    }
  };

  const [toggleModal, setToggleModal] = useState({
    isOpen: false,
  });

  const handleToggle = (values) => {
    setToggleModal({
      isOpen: true,
      values: {
        values,
        pessoaSelected,
      },
    });
  };

  return (
    <>
      <ModalDeEscolha
        toggleModal={toggleModal}
        handlePrint={handlePrint}
        handleSave={handleSubmit}
        setToggleModal={setToggleModal}
      />

      <Formik
        enableReinitialize
        initialValues={diagnostico}
        onSubmit={handleToggle}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ errors }) => (
          <Form>
            <div>
              <Row style={{ paddingTop: "15px" }}>
                <Col>
                  <label className="obrigatorio">Profissional*</label>
                  <FieldValidate
                    name="id_profissional"
                    placeholderText="Nome do Profissional"
                    component={SelectComponent}
                    options={profissionais}
                    errors={errors}
                    noClass
                  />
                </Col>
                <Col>
                  <label className="obrigatorio">Pessoa*</label>
                  <FieldValidate
                    name="id_pessoa"
                    onChangeValue={alterarPessoaSelecionada}
                    placeholderText="Nome do Pessoa"
                    component={SelectComponent}
                    options={pessoasSelect}
                    errors={errors}
                    noClass
                  />

                  {pessoaSelected && (
                    <div style={{ marginTop: "10px" }}>
                      <label style={{ color: "#8B4BF9" }}>
                        <b>Profissão:</b> {pessoaSelected.profissao}{" "}
                      </label>

                      <label style={{ marginLeft: "15px", color: "#8B4BF9" }}>
                        <b>Data de Nascimento:</b>{" "}
                        {dateToView(pessoaSelected.data_nascimento)}
                      </label>
                      <label style={{ marginLeft: "15px", color: "#8B4BF9" }}>
                        <b>Idade:</b> {pessoaSelected.idade}
                      </label>
                      {typeof pessoaSelected.agendaConsultorio !==
                        "undefined" &&
                        pessoaSelected.agendaConsultorio.length > 0 && (
                          <label
                            style={{ marginLeft: "15px", color: "#8B4BF9" }}
                          >
                            <b>Primeira Consulta:</b>{" "}
                            {dateToView(
                              pessoaSelected.agendaConsultorio[0].data_inicio
                            )}{" "}
                          </label>
                        )}
                    </div>
                  )}
                </Col>
              </Row>

              <Row style={{ paddingTop: "15px" }}>
                <Col>
                  <label className="obrigatorio">Anamnese*</label>
                  <FieldValidate
                    name="queixa_principal"
                    placeholder="Informe a Queixa Principal (QP)"
                    className="form-control"
                    component="textarea"
                    type="text"
                    errors={errors}
                  />
                </Col>
              </Row>

              <Row style={{ paddingTop: "15px" }}>
                <Col>
                  <label>Observação interna</label>
                  <Field
                    name="obs_interna"
                    placeholder="Informe o Observação interna"
                    className="form-control"
                    component="textarea"
                    type="text"
                  />
                </Col>
                <Col>
                  <label>Observação para Relatório (Termo)</label>
                  <Field
                    name="obs_report"
                    placeholder="Informe o Observação para Relatório (Termo)"
                    className="form-control"
                    component="textarea"
                    type="text"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button variant="salvar" type="submit">
                    <FontAwesomeIcon icon={faSave} className="icone" /> Salvar
                    ou Imprimir Diagnóstico
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FormDiagnostico;
