import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { requisitarAPI } from "../../components/AlertGeral";
import Listagem from "../../components/Listagens";
import {
  colunasCaixa,
  colunasContasReceber,
  colunasContasReceberResumo,
  colunasMovimentacoes,
} from "../../components/Listagens/colunas";
import MainMenu from "../../components/MainMenu";
import "../../Global/styleGeral.css";
import api from "../../services/api";
import { Creators as CaixasActions } from "../../Store/Controls/caixas";
import { Creators as ContasPagarActions } from "../../Store/Controls/financeiros/contasPagar";
import { Creators as ContasReceberActions } from "../../Store/Controls/financeiros/contasReceber";
import { Creators as MovimentacoesActions } from "../../Store/Controls/movimentacoes";
import FormasPagamento from "./formasPagamento";
import Comissoes from "./comissoes";

const Financeiro = ({ history }) => {
  const [loading, setLoading] = useState(true);
  const [tabActive, setTabActive] = useState("resumo");
  const [mesesFiltrar, setMesesFiltrar] = useState("0");
  const [dados, setDados] = useState({
    totalContas: 0,
    totalReceber: 0,
    totalPagar: 0,
    contasReceber: [],
    contasPagar: [],
  });
  const [contasAPagarNaoPagas, setContasAPagarNaoPagas] = useState([]);
  const [contasAReceberNaoPagas, setContasAReceberNaoPagas] = useState([]);

  const contasReceber = useSelector((state) => state.contasReceber);
  const contasPagar = useSelector((state) => state.contasPagar);
  const caixas = useSelector((state) => state.caixas);
  const movimentacoes = useSelector((state) => state.movimentacoes);
  const dispatch = useDispatch();

  const { tab } = queryString.parse(history.location.search);

  useEffect(() => {
    let cttsAPagarNaoPagas = [];
    let cttsAReceberNaoPagas = [];

    contasPagar.filter((i) => {
      if (i.status !== "P") {
        cttsAPagarNaoPagas.push(i);
      }
    });
    contasReceber.filter((i) => {
      if (i.status !== "P") {
        cttsAReceberNaoPagas.push(i);
      }
    });

    setContasAPagarNaoPagas(cttsAPagarNaoPagas);
    setContasAReceberNaoPagas(cttsAReceberNaoPagas);
  }, [contasPagar, contasReceber]);

  useEffect(() => {
    setTabActive(tab);
  }, [tab]);

  async function getDados() {
    // Conta Receber
    async function findContasReceber() {
      let ret = await requisitarAPI(
        () => api.get("contasReceber?month=01"),
        "Falha ao obter dados do servidor"
      );

      if (!ret.erro) {
        dispatch(ContasReceberActions.addContasReceber(ret.response.data));
      }
    }
    await findContasReceber();

    // Contas Pagar
    async function findContasPagar() {
      let ret = await requisitarAPI(
        () => api.get("contasPagar?month=01"),
        "Falha ao obter dados do servidor"
      );

      if (!ret.erro) {
        dispatch(ContasPagarActions.addContasPagar(ret.response.data));
      }
    }

    await findContasPagar();

    // Caixa
    async function findCaixas() {
      let ret = await requisitarAPI(
        () => api.get("caixas"),
        "Falha ao obter os caixas do servidor"
      );

      if (!ret.erro) {
        dispatch(CaixasActions.addCaixas(ret.response.data));
      }
    }
    await findCaixas();

    // Movimentações
    async function findMovimentacoes() {
      let ret = await requisitarAPI(
        () => api.get("movimentacoes"),
        "Falha ao obter as movimentações do servidor"
      );

      if (!ret.erro) {
        dispatch(MovimentacoesActions.addMovimentacoes(ret.response.data));
      }
    }
    await findMovimentacoes();

    setLoading(false);
  }

  useEffect(() => {
    getDados().then(() => findDadosDoResumo());
  }, []);

  useEffect(() => {
    if (mesesFiltrar !== 0) {
      findDadosDoResumo(mesesFiltrar);
    } else {
      findDadosDoResumo(1);
    }
  }, [mesesFiltrar]);

  async function findDadosDoResumo(month = 1) {
    let ret;
    if (month) {
      ret = await requisitarAPI(
        () => api.get(`resumo?month=${month}`),
        "Falha buscar os dados"
      );
    } else {
      ret = await requisitarAPI(
        () => api.get(`resumo`),
        "Falha buscar os dados"
      );
    }

    setDados(ret.response.data);
  }

  const meses = [
    // { label: "Todos os meses", value: "01" },
    { label: "Janeiro", value: "01" },
    { label: "Fevereiro", value: "02" },
    { label: "Março", value: "03" },
    { label: "Abril", value: "04" },
    { label: "Maio", value: "05" },
    { label: "Junho", value: "06" },
    { label: "Julho", value: "07" },
    { label: "Agosto", value: "08" },
    { label: "Setembro", value: "09" },
    { label: "Otubro", value: "10" },
    { label: "Novembro", value: "11" },
    { label: "Dezembro", value: "12" },
  ];

  return (
    <>
      <MainMenu navigation={history} />

      <div className="titulo-pagina">
        <Tabs
          activeKey={tabActive}
          onSelect={(k) => setTabActive(k)}
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="resumo" title="Resumo">
            Resumo financeiro
            <Row>
              <Col>
                <select
                  onChange={(e) => setMesesFiltrar(e.target.value)}
                  style={{ display: "block" }}
                  placeholder="Selecione o mês"
                >
                  {meses.map((mes) => (
                    <option key={mes.value} value={mes.value}>
                      {mes.label}
                    </option>
                  ))}
                </select>
              </Col>
              <Col>
                {/* <select
                  onChange={(e) => setMesesFiltrar(e.target.value)}
                  style={{ display: "block" }}
                  placeholder="Selecione o mês"
                >
                  {meses.map((mes) => (
                    <option key={mes.value} value={mes.value}>
                      {mes.label}
                    </option>
                  ))}
                </select> */}
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6} lg={3}>
                <Card>
                  <Card.Body>
                    <Card.Title>Total caixas</Card.Title>
                    <Card.Text style={{ color: "green" }}>
                      {dados.totalContas.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={12} md={6} lg={3}>
                <Card>
                  <Card.Body>
                    <Card.Title>Total a receber</Card.Title>
                    <Card.Text style={{ color: "green" }}>
                      {dados.totalReceber.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={12} md={6} lg={3}>
                <Card>
                  <Card.Body>
                    <Card.Title>Total a Pagar</Card.Title>
                    <Card.Text style={{ color: "red" }}>
                      {dados.totalPagar.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={12} md={6} lg={3}>
                <Card>
                  <Card.Body>
                    <Card.Title>Liquido</Card.Title>
                    <Card.Text>
                      {(
                        dados.totalReceber +
                        dados.totalContas -
                        dados.totalPagar
                      ).toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <div className="resumo mt-0">
              <Row>
                <Col sm={12} md={6} lg={12} xl={12} xxl={6}>
                  <div className="resumo-card contas-receber">
                    <h4>Caixas</h4>
                    <div className="resumo-content">
                      <div>
                        <Listagem
                          dados={caixas}
                          cols={colunasCaixa}
                          noEdit={true}
                          noCancel={true}
                          tipo_delete="caixas"
                          history={history}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={12} md={6} lg={12} xl={12}>
                  <div className="resumo-card contas-receber  mt-3">
                    <h4>Contas a receber</h4>
                    <div className="resumo-content mt-3">
                      <div>
                        <Listagem
                          filtrarPorData={false}
                          filtrarPorCaixa={false}
                          noEdit={true}
                          noCancel={true}
                          dados={dados.contasReceber}
                          cols={colunasContasReceberResumo}
                          history={history}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={12} md={6} lg={12} xl={12}>
                  <div className="resumo-card contas-pagar mt-3">
                    <h4>Contas a pagar</h4>
                    <div className="resumo-content mt-3">
                      <div>
                        <Listagem
                          filtrarPorData={false}
                          filtrarPorCaixa={false}
                          dados={dados.contasPagar}
                          cols={colunasContasReceberResumo}
                          noEdit={true}
                          noCancel={true}
                          history={history}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Tab>
          <Tab eventKey="movimentacoes" title="Movimentações">
            Movimentações
            <Button
              variant="principal"
              onClick={() => {
                history.push("/financeiro/movimentacoes/cadastro");
              }}
              alt="Adicione uma nova movimentação"
              title="Adicione uma nova movimentação"
            >
              <FontAwesomeIcon className="icone" icon={faPlus} /> Incluir uma
              nova Movimentação
            </Button>
            <div
              style={{ margin: 0, marginTop: 20 }}
              className="container-principal"
            >
              <Row>
                <Listagem
                  dados={movimentacoes}
                  noEdit={true}
                  cols={colunasMovimentacoes}
                  tipo_delete="movimentacoes"
                  history={history}
                />
              </Row>
            </div>
          </Tab>
          <Tab eventKey="contas_a_receber" title="Contas a Receber">
            Contas a Receber
            <Button
              variant="principal"
              onClick={() => {
                history.push("/financeiro/contasReceber/cadastro");
              }}
              alt="Adicione uma nova conta a receber"
              title="Adicione uma nova conta a receber"
            >
              {" "}
              <FontAwesomeIcon className="icone" icon={faPlus} /> Incluir Contas
              a Receber
            </Button>
            <div
              style={{ margin: 0, marginTop: 20 }}
              className="container-principal"
            >
              <Row>
                <Listagem
                  filtrarPorData={true}
                  filtrarPorCaixa={true}
                  dados={contasReceber}
                  cols={colunasContasReceber}
                  url_edit="financeiro/contasReceber/editar"
                  tipo_delete="contasReceber"
                  history={history}
                  botaoExtra={{
                    title: "Pagamento",
                    icone: "money-bill",
                    page: "financeiro/contasReceber/pagamento",
                  }}
                />
              </Row>
            </div>
          </Tab>
          <Tab eventKey="contas_a_pagar" title="Contas a Pagar">
            Contas a Pagar
            <Button
              variant="principal"
              onClick={() => {
                history.push("/financeiro/contasPagar/cadastro");
              }}
              alt="Adicione uma nova conta a pagar"
              title="Adicione uma nova conta a pagar"
            >
              {" "}
              <FontAwesomeIcon className="icone" icon={faPlus} /> Incluir Contas
              a Pagar
            </Button>
            <div
              style={{ margin: 0, marginTop: 20 }}
              className="container-principal"
            >
              <Row>
                <Listagem
                  filtrarPorData={true}
                  filtrarPorCaixa={true}
                  dados={contasPagar}
                  cols={colunasContasReceber}
                  url_edit="financeiro/contasPagar/editar"
                  tipo_delete="contasPagar"
                  history={history}
                  botaoExtra={{
                    title: "Pagamento",
                    icone: "money-bill",
                    page: "financeiro/contasPagar/pagamento",
                  }}
                />
              </Row>
            </div>
          </Tab>
          <Tab eventKey="caixa" title="Caixas">
            Caixas
            <Button
              variant="principal"
              onClick={() => {
                history.push("/financeiro/caixas/cadastro");
              }}
              alt="Adicione um novo caixa"
              title="Adicione um novo caixa"
            >
              <FontAwesomeIcon className="icone" icon={faPlus} /> Incluir um
              novo Caixa
            </Button>
            <div
              style={{ margin: 0, marginTop: 20 }}
              className="container-principal"
            >
              <Row>
                <Listagem
                  dados={caixas}
                  cols={colunasCaixa}
                  url_edit="financeiro/caixas/editar"
                  tipo_delete="caixas"
                  history={history}
                />
              </Row>
            </div>
          </Tab>
          <Tab eventKey="formas_de_pagamento" title="Formas de Pagamento">
            <FormasPagamento history={history} />
          </Tab>
          <Tab eventKey="comissao" title="Comissões">
            <Comissoes history={history} />
          </Tab>
        </Tabs>

        {/* <Button variant="secundario" onClick={()=>{history.push('/contas')}}> <FontAwesomeIcon className="icone" icon={faFileInvoice}/> Gerenciar Contas</Button>   */}
      </div>
    </>
  );
};

export default Financeiro;
