import { createActions, createReducer } from "reduxsauce";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  addMovimentacoes: ["movimentacoes"],
  addMovimentacao: ["movimentacao"],
  editMovimentacao: ["id", "movimentacao"],
  removeMovimentacao: ["id"],
});

/**
 * Implementação das manipulações do State
 */
const INITIAL_STATE = [];

const addMovimentacoes = (state = INITIAL_STATE, action) =>
  action.movimentacoes;

const add = (state = INITIAL_STATE, action) => [...state, action.movimentacao];

const edit = (state = INITIAL_STATE, action) =>
  state.map((item) => {
    if (item.id === action.id) {
      return action.movimentacao;
    } else {
      return item;
    }
  });

const remove = (state = INITIAL_STATE, action) =>
  state.filter((todo) => todo.id !== action.id);

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.ADD_MOVIMENTACOES]: addMovimentacoes,
  [Types.ADD_MOVIMENTACAO]: add,
  [Types.EDIT_MOVIMENTACAO]: edit,
  [Types.REMOVE_MOVIMENTACAO]: remove,
});
