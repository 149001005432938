/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Button, Row, Col } from "react-bootstrap";
import { Formik, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { Creators as CaixaActions } from "../../Store/Controls/caixas";
import "../../Global/styleGeral.css";
import { AlertGeral, requisitarAPI } from "../../components/AlertGeral";
import api from "../../services/api";
import MainMenu from "../../components/MainMenu";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faChevronLeft,
  faUsers,
  faMapMarkerAlt,
  faBuilding,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import FieldValidate from "../../components/Form/FieldValidate.js";
import { MoedaFormat } from "../../components/Form/MoedaFormat.js";

import Spinner from "../../components/Spiner";

library.add(faSave, faChevronLeft, faUsers, faMapMarkerAlt, faBuilding);

const CadastroCaixa = ({ history, match }) => {
  const [editForm, setEditForm] = useState(false);
  const [captionForm, setCaptionForm] = useState("Cadastro de caixa");
  const [retorno, setRetorno] = useState({});
  const [loading, setLoading] = useState(true);
  const [caixa, setCaixa] = useState({});

  const caixas = useSelector((state) => state.caixas);
  const dispatch = useDispatch();

  useEffect(() => {
    if (typeof match.params.id !== "undefined") {
      setCaptionForm("Edição de Caixa");
      setEditForm(true);
      let caixaV = caixas.find((item) => item.id == match.params.id);
      if (typeof caixaV === "undefined") {
        history.push("/financeiro/caixas");
        return;
      }

      setCaixa(caixaV);
    }
    setLoading(false);
  }, [history, match.params.id, match.params.id_pessoa]);

  const schema = Yup.object().shape({
    nome: Yup.string().required("Informe o nome"),
    valor: Yup.number().required("Informe o valor"),
  });

  const handleSubmit = async (values) => {
    setLoading(true);

    let ret = {};

    if (!editForm) {
      ret = await requisitarAPI(
        () => api.post("caixas", values),
        "Falha criar um caixa"
      );

      history.push("/financeiro/caixas");
    } else {
      ret = await requisitarAPI(
        () => api.put("caixas/" + match.params.id, values),
        "Falha ao editar o caixa"
      );
      setRetorno(ret);
    }

    setLoading(false);

    if (!ret.erro) {
      history.push("/financeiro/caixas");
    }
  };

  return (
    <>
      <MainMenu navigation={history} />

      <div className="titulo-pagina">
        {captionForm}
        <Button
          variant="voltar"
          onClick={() => {
            history.push("/financeiro/caixas");
          }}
        >
          <FontAwesomeIcon className="icone" icon="chevron-left" /> Voltar para
          financeiro
        </Button>
        <a
          href="/ajuda#financeiro"
          alt="Clique para acessar página de ajuda"
          style={{ float: "right" }}
          title="Clique para acessar página de ajuda"
        >
          <FontAwesomeIcon className="icone" icon={faQuestionCircle} />
        </a>
      </div>

      <div className="container-principal">
        <Row>
          <div className="container-principal" style={{ width: "100%" }}>
            <Formik
              enableReinitialize
              initialValues={caixa}
              onSubmit={handleSubmit}
              validationSchema={schema}
              validateOnChange={false}
              validateOnBlur={false}
            >
              {({ errors }) => (
                <Form>
                  <Row>
                    <Col>
                      <label className="obrigatorio">Nome*</label>
                      <FieldValidate
                        name="nome"
                        placeholder="Digite o nome do caixa*"
                        type="text"
                        errors={errors}
                      />
                    </Col>
                    <Col>
                      <label className="obrigatorio">Valor*</label>
                      <FieldValidate
                        component={MoedaFormat}
                        name="valor"
                        placeholder="Digite o Valor"
                        errors={errors}
                        disabled={editForm}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <AlertGeral retorno={retorno} />
                      <Spinner loading={loading} />
                    </Col>
                  </Row>
                  <Row
                    style={{ marginTop: "40px" }}
                    className="form-group text-center"
                  >
                    <Col className="text-center">
                      <Button variant="salvar" type="submit">
                        <FontAwesomeIcon className="icone" icon="save" /> Salvar
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </div>
        </Row>
      </div>
    </>
  );
};

export default CadastroCaixa;
