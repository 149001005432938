import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { faSave, faPrint, faTrash } from "@fortawesome/free-solid-svg-icons";

export default function ModalDeEscolha({
  toggleModal,
  handlePrint,
  handleSave,
  setToggleModal,
}) {
  const handleClose = () => {
    setToggleModal({
      ...toggleModal,
      isOpen: false,
    });
  };

  return (
    <Modal size="lg" centered show={toggleModal.isOpen} onHide={handleClose}>
      <Modal.Body>
        <h3>O que deseja fazer com o cadastro ?</h3>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: 20,
          }}
        >
          <Button
            variant="danger"
            style={{ maxWidth: "14rem", height: "3rem" }}
            onClick={handleClose}
          >
            <FontAwesomeIcon icon={faTrash} className="icone" /> Cancelar
          </Button>

          <div
            style={{
              display: "flex",
            }}
          >
            <Button
              variant="secondary"
              style={{ maxWidth: "14rem", height: "3rem", minWidth: "8rem" }}
              onClick={() => handlePrint(toggleModal.values)}
            >
              <FontAwesomeIcon icon={faPrint} className="icone" /> Imprimir
            </Button>
            <Button
              variant="salvar"
              style={{
                maxWidth: "14rem",
                height: "3rem",
                margin: 0,
                marginLeft: 10,
              }}
              onClick={() => { handleSave(toggleModal.values.values); setToggleModal({...toggleModal,isOpen: false,})}}
            >
              <FontAwesomeIcon icon={faSave} className="icone" /> Salvar
              Cadastro
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
