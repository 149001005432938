import React, { useState } from "react";

// import "./style.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { useDispatch } from "react-redux";
import { Creators as CaixasActions } from "../../Store/Controls/caixas";
import { Creators as DiagnosticoActions } from "../../Store/Controls/diagnosticos";
import { Creators as ContaActions } from "../../Store/Controls/financeiros/contas";
import { Creators as ContasPagarActions } from "../../Store/Controls/financeiros/contasPagar";
import { Creators as ContasReceberActions } from "../../Store/Controls/financeiros/contasReceber";
import { Creators as FormasPagamentoActions } from "../../Store/Controls/financeiros/formasPagamento";
import { Creators as guias } from "../../Store/Controls/guias";
import { Creators as listaEspera } from "../../Store/Controls/listasEspera/";
import { Creators as listaTags } from "../../Store/Controls/listaTags";
import { Creators as MovimentacoesActions } from "../../Store/Controls/movimentacoes";
import { Creators as PerfilActions } from "../../Store/Controls/perfis";
import { Creators as NotaFiscalAction } from "../../Store/Controls/pessoas/notasFiscais";
import { Creators as PessoaAction } from "../../Store/Controls/pessoas/pessoas";
import { Creators as UserActions } from "../../Store/Controls/usuarios";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import api from "../../services/api";

library.add(faTrash);

const Delete = ({ tipo, dados, noCaption }) => {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  const handleClose = () => setShow(false);

  const handleSubmit = async () => {
    const response = await api.delete(tipo + "/" + dados.id);

    if (tipo === "usuarios") {
      dispatch(UserActions.removeUser(dados.id));
    } else if (tipo === "perfis") {
      dispatch(PerfilActions.removePerfil(dados.id));
    } else if (tipo === "pessoas") {
      dispatch(PessoaAction.removePessoa(dados.id));
    } else if (tipo === "diagnosticos") {
      dispatch(DiagnosticoActions.removeDiagnostico(dados.id));
    } else if (tipo === "evolucoes") {
      dispatch(
        DiagnosticoActions.removeEvolucao(dados.id_diagnostico, dados.id)
      );
    } else if (tipo === "contas") {
      dispatch(ContaActions.removeConta(dados.id));
    } else if (tipo === "formasPagamento") {
      dispatch(FormasPagamentoActions.removeFormaPagamento(dados.id));
    }

    if (tipo === "contasReceber") {
      dispatch(ContasReceberActions.removeContaReceber(dados.id));
    }

    if (tipo === "contasPagar") {
      dispatch(ContasPagarActions.removeContaPagar(dados.id));
    }

    if (tipo === "notas") {
      dispatch(NotaFiscalAction.removeNota(dados.id));
    }

    if (tipo === "notasItens") {
      dispatch(NotaFiscalAction.removeItem(dados.id_nota, dados.id));
    }

    if (tipo === "listaEspera") {
      dispatch(listaEspera.removeEspera(dados.id));
    }

    if (tipo === "listaTags") {
      dispatch(listaTags.removeTag(dados.id));
    }

    if (tipo === "guias") {
      dispatch(guias.removeGuia(dados.id));
    }

    if (tipo === "caixas") {
      dispatch(CaixasActions.removeCaixa(dados.id));
    }

    if (tipo === "movimentacoes") {
      dispatch(MovimentacoesActions.removeMovimentacao(dados.id));
    }

    setShow(false);
  };

  return (
    <>
      <Button
        variant="apagar-listagem"
        alt="Excluir"
        title="Excluir"
        onClick={() => setShow(true)}
      >
        <FontAwesomeIcon icon="trash" /> {!noCaption && "Apagar"}
      </Button>

      <Modal show={show} onHide={handleClose} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>Atenção!</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            Deseja excluir o registro?
            <br />{" "}
            <span style={{ color: "#c93d2e", fontWeight: "bold" }}>
              Essa ação não tem volta!
            </span>
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="fechar"
            style={{ float: "left", width: "50%" }}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            variant="excluir"
            style={{ float: "right", width: "50%" }}
            type="submit"
            onClick={handleSubmit}
          >
            Excluir!
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Delete;
