import { format } from "date-fns";
import React from "react";

const status = (props) => {
  return (
    <span>
      {props.status === "P"
        ? "presente"
        : Date.parse(new Date(props.data_inicio)) > Date.parse(new Date())
        ? "agendado"
        : "ausente"}
    </span>
  );
};

const formatarData = (props) => {
  return <span>{format(new Date(props.data_inicio), "dd/MM/yyyy HH:mm")}</span>;
};

const proficional = (props) => {
  return <span>{props.profissional.nome}</span>;
};

const numeroGuia = (props) => {
  if (props.guia) {
    return <span>{props.guia.numero_guia}</span>;
  } else {
    return <span></span>;
  }
};

export const colunasAgendamento = [
  {
    field: "status",
    header: "Status",
    body: status,
    style: { width: "120px", textAlign: "Center" },
    sortable: true,
  },
  {
    field: "data_inicio",
    header: "Data",
    body: formatarData,
    style: { textAlign: "Center" },
    sortable: true,
  },
  {
    field: "guia.numero_guia",
    header: "Guia",
    style: { textAlign: "Center" },
    sortable: true,
  },
  {
    field: "profissional",
    header: "Profissional",
    body: proficional,
    style: { textAlign: "Center" },
    sortable: true,
  },
];
