import React, { useEffect, useState } from "react";
import { colunasAgendamento } from "./colunas";
import Listagem from "./Listagem";

export const TabelaAgendamento = (agenda, history) => {
  const [agendamentos, setAgendamentos] = useState([]);

  useEffect(() => {
    if (agenda) {
      setAgendamentos(agenda.agenda);
    }
  }, []);

  const url_edit = "/agendamento/editar";

  return (
    <div>
      <h4
        style={{
          marginBottom: "20px",
        }}
      >
        Histórico de agendamento
      </h4>
      <p
        style={{
          marginBottom: "20px",
        }}
      >
        Total de consultas: {agendamentos.length}
        <br />
        Total de consultas presentes:{" "}
        {agendamentos.filter((a) => a.status === "P").length}
      </p>

      {agendamentos.length > 0 && (
        <Listagem
          dados={agendamentos}
          cols={colunasAgendamento}
          url_edit={url_edit}
          tipo_delete="listaTags"
          history={history}
        />
      )}
    </div>
  );
};
