/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// import "./style.css";
import {
  faArrowAltCircleRight,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Form, Formik } from "formik";
import { Panel } from "primereact/panel";
import { Badge, Button, Col, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Select from "react-select";
import * as Yup from "yup";
import { AlertGeral, requisitarAPI } from "../../components/AlertGeral";
import PanelContasReceber from "../../components/Financeiro/panelContasReceber.js";
import Spinner from "../../components/Spiner";
import {
  getCaixasForSelect,
  getPessoasForSelect,
  gravarFinanceiro,
} from "../../Global/chamadasAPI";
import {
  dateTimeToView,
  dateToField,
  dateToView,
  formatMoeda,
} from "../../Global/funcoes";
import { Creators as EventosConsultorioActions } from "../../Store/Controls/agendas/eventosConsultorio";
import { Creators as DiagnosticoActions } from "../../Store/Controls/diagnosticos";

import { DatePickerField } from "../../components/Form/DatePicker";
import api from "../../services/api";

const ModalViewAgenda = ({
  history,
  tipo,
  dados,
  show,
  setShow,
  setShowModalAulasConcluidas,
  dataQuadro,
  setGuiaFinalizada = () => {},
  setIdGuia,
}) => {
  const [retorno, setRetorno] = useState({});
  const [captionModal, setCaptionModal] = useState("");
  const [loading, setLoading] = useState(true);
  const [gerarFinanceiro, setGerarFinanceiro] = useState(false);
  const [faturasFinanceiro, setFaturasFinanceiro] = useState([]);
  const dispatch = useDispatch();
  const isConsultorio = tipo === "consultorio";
  const [dadosFinanceiro, setDadosFinanceiro] = useState({});
  const [editandoProfissional, setEditandoProfissional] = useState(false);
  const [editandoObservacao, setEditandoObservacao] = useState(false);
  const [editandoData, setEditandoData] = useState(false);
  const [novoProfissional, setNovoProfissional] = useState(null);
  const [novaObservacao, setNovaObservacao] = useState(null);
  const [profissionaisSelect, setProfissionaisSelect] = useState([]);
  const [caixas, setCaixas] = useState([{}]);
  const [profissionais, setProfissionais] = useState([{}]);
  const [procedimentos, setProcedimentos] = useState([{}]);
  const [procedimentosForSelect, setProcedimentosForSelect] = useState([{}]);

  const formatTags = (arr = []) => {
    return arr.map((item) => {
      return {
        label: item.description,
        value: item.id,
      };
    });
  };
  
  const getProcedimentos = () => {
    api
      .get("listaProcedimentos/consultorio")
      .then(({ data }) => {
        setProcedimentos(data);
        setProcedimentosForSelect(formatTags(data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [evento, setEvento] = useState({
    data_inicio: "",
    data_fim: "",
  });

  const handleSubmit = async (values) => {
    
    setLoading(true);

    const ret = await requisitarAPI(
      () =>
        api.put("consultorio/agenda/" + dados.id, {
          ...values,
          id: dados.id,
        }),
      "Falha ao editar agenda"
    );
    // setRetorno(ret);

    if (!ret.erro) {
      // reload page

      window.location.reload();
    }

    setLoading(false);
  };

  const schema = Yup.object().shape({
    data_inicio: Yup.date()
      .typeError("Informe a data de início")
      .when("data_fim", (data_fim, schema) =>
        schema.max(data_fim, "Data de inicio superior a data final")
      ),
    data_fim: Yup.date().typeError("Informe a data final"),
  });

  let conteudoBtnConfirma = "";

  const getConteudoBtnConfirma = () => {
    if (isConsultorio) {
      if (dados.status === "P") {
        return (
          <Button
            variant="success"
            style={{ width: "100%", marginLeft: "10px" }}
            onClick={chamadaDiagnostico}
          >
            Diagnóstico
          </Button>
        );
      } else {
        return (
          <Button
            variant="success"
            style={{ width: "100%", marginLeft: "10px" }}
            onClick={confirmarPresenca}
          >
            Confirmar Presença
          </Button>
        );
      }
    } else {
      if (dados.status === "P") {
        return "";
      } else {
        return (
          <Button
            variant="success"
            style={{ width: "100%", marginLeft: "10px" }}
            onClick={confirmarPresenca}
          >
            Confirmar Presença
          </Button>
        );
      }
    }
  };

  const setDadosInicialFinanceiro = () => {
  
    dadosFinanceiro.id_procedimento = dados.id_procedimento;
    dadosFinanceiro.id_profissional = dados.profissional.id;
    dadosFinanceiro.descricao = "Consulta";
    dadosFinanceiro.nf_cpf = dados.pessoa.cpfcnpj;
    dadosFinanceiro.baixar_duplicata = true;
    dadosFinanceiro.data_vencimento = new Date();
    dadosFinanceiro.parcelas_total = 0;
    setDadosFinanceiro(dadosFinanceiro);
  };

  const getFaturasFinanceiro = async () => {
    let ret = await requisitarAPI(
      () => api.get("contasReceber?id_agenda_consultorio=" + dados.id),
      "Falha ao obter dados do servidor"
    );
    setRetorno(ret);

    setFaturasFinanceiro(ret.response.data);
  };

  async function getProfissionais() {
    let profs = await getPessoasForSelect("PF");
    setProfissionaisSelect(profs);
  }

  const submitAlterarProfissional = async () => {
    if (novoProfissional != null || novaObservacao != null) {
      let values = {};
      if (novoProfissional) {
        values = { id_profissional: novoProfissional.value };
      } else {
        values = { observacao: novaObservacao };
      }

      setLoading(true);
      try {
        const ret = await requisitarAPI(
          () =>
            api.put(`${tipo}/agenda/${dados.id}`, {
              ...values,
              allguide: false,
            }),
          "Falha ao alterar agenda"
        );

        setRetorno(ret);

        if (!ret.erro) {
          dados = ret.response.data;
          dados.data_inicio = dateToField(dados.data_inicio);
          dados.data_fim = dateToField(dados.data_fim);

          try {
            dispatch(
              EventosConsultorioActions.editEventoConsultorio(dados.id, dados)
            );
          } catch (error) {
            console.log(error);
          }
          setEditandoProfissional(false);
          setEditandoObservacao(false);
          setNovoProfissional(null);
          setShow(false);
        }
      } catch (error) {
        console.log(error);
      }

      setLoading(false);
    }
  };

  const setCaixasFromApi = async () => {
    setCaixas(await getCaixasForSelect());
  };

  const setProfissionaisFromApi = async () => {
    setProfissionais(await getPessoasForSelect("PF"));
  }

  useEffect(() => {
    if (dados !== "") {
      if (isConsultorio) {
        setCaptionModal("Agendamento Consultório");
        setDadosInicialFinanceiro();
        setCaixasFromApi();
        getProcedimentos();
        setProfissionaisFromApi();
        getFaturasFinanceiro();
        getProfissionais();
      }
    }

    setLoading(false);
  }, [conteudoBtnConfirma, dados, tipo, dadosFinanceiro]);

  const confirmarPresenca = async () => {
    setLoading(true);

    dados.status = "P";

    // verificar se já encerraram as guias
    const ret = await requisitarAPI(
      () =>
        api.put(`${tipo}/agenda/${dados.id}`, {
          ...dados,
          allguide: false,
        }),
      "Falha ao editar agenda"
    );
    
    if (ret.response?.data?.restante?.numero_guia) {
      if (ret.response?.data?.restante.restante <= 0) {
        setGuiaFinalizada(true);
        setIdGuia(ret.response.data.restante);
      }
    }
    setRetorno(ret);

    if (!ret.erro) {
      if (isConsultorio) {
        dispatch(
          EventosConsultorioActions.editEventoConsultorio(dados.id, dados)
        );
      }
    }

    if (!ret.erro) {
      setShow(false);
    }

    setLoading(false);
  };

  const chamadaDiagnostico = () => {
    if (dados.pessoa.diagnosticos.length > 0) {
      async function getDiagnosticos() {
        setLoading(true);

        let ret = await requisitarAPI(
          () => api.get("diagnosticos"),
          "Falha ao obter diagnosticos do servidor"
        );

        setRetorno(ret);

        if (!ret.erro) {
          dispatch(DiagnosticoActions.addDiagnosticos(ret.response.data));
        }

        history.push("/diagnosticos/editar/" + dados.pessoa.diagnosticos[0].id);
        setLoading(false);
      }

      getDiagnosticos();
    } else history.push("/diagnosticos/cadastro/withCli/" + dados.id_pessoa);
  };

  const schemaFinanceiro = Yup.object().shape(
    {
      id_caixa: Yup.number().required("Informe o Caixa"),
      id_profissional: Yup.number().required("Informe o Profssional"),
      descricao: Yup.string().required("Informe a Descricão"),
      data_vencimento: Yup.date().typeError("Informe uma data válida"),
      valor: Yup.number().required("Informe o valor"),
      
      nf_cpf: Yup.string().nullable()
        .when("emited_nf", (emited_nf, schema) =>
        emited_nf === true
            ? schema.required("Informe um CPF/CNPJ")
            : schema
        ),
        
      parcelas_total: Yup.number()
        .when("parcelas_intervalo", (parcelas_intervalo, schema) =>
          parcelas_intervalo > 0
            ? schema.required("Informe o total de parcelas")
            : schema
        )
        .typeError("informe um número válido"),
      parcelas_intervalo: Yup.number()
        .when("parcelas_total", (parcelas_total, schema) =>
          parcelas_total > 0
            ? schema.required("Informe o intervalo de dias")
            : schema
        )
        .typeError("informe um número válido"),
    },
    ["parcelas_total", "parcelas_intervalo","emited_nf","nf_cpf"]
  );

  const gravarComissao = async (values) => {
    let ret = await requisitarAPI(
      () => api.get("comissoes/"+values.id_profissional+"/"+values.id_procedimento),
      "Falha ao cadastrar comissão"
    );

    let valor_total = 0;
    let valor_comissao = 0;

    let id_comissao = 0;
    
    if (!ret.erro) {
      if (ret.response.data) {
        
        id_comissao = ret.response.data.id;
        valor_total = ret.response.data.valor_total;
        valor_comissao  = ret.response.data.valor_comissao;
      }
    }
    
    const procedimento = procedimentos.find(function (element) {
      return element.id = values.id_procedimento;
    });  

    valor_total = valor_total + values.valor;

    const percet_com = parseInt(procedimento.commission) / 100;
    const final_commission = (percet_com * values.valor);
   
    valor_comissao = valor_comissao + final_commission;
   
    let comissao = {
      id_profissional: values.id_profissional,
      id_procedimento: values.id_procedimento,
      valor_total: valor_total,
      valor_comissao: valor_comissao,
      status: "A"
    }

    
    if (id_comissao > 0){
      
      ret = await requisitarAPI(
        () => api.put("comissoes/"+id_comissao, comissao),
        "Falha ao cadastrar comissão"
      );
      
      setRetorno(ret);
    } else {
      ret = await requisitarAPI(
        () => api.post("comissoes", comissao),
        "Falha ao cadastrar comissão"
      );

      setRetorno(ret);
    }
    
  }

  const submitGravarFinanceiro = async (values) => {  
    
    let nameProcedimento = "";

    procedimentosForSelect.map((item) => {
      if (item.value === values.id_procedimento) {
        nameProcedimento = item.label;  
      }   
    });

    values.procedimento_name = nameProcedimento;
    values.id_pessoa = dados.id_pessoa;
    values.id_agenda_consultorio = dados.id;
    const erro = await gravarFinanceiro(values, setRetorno);

    if (!erro) {
      setGerarFinanceiro(false);
      getFaturasFinanceiro();
      
      if (values. baixar_duplicata) {
        gravarComissao(values);  
      }
    }
  };

  if (dados !== "")
    return (
      <>
        <Modal show={show} onHide={() => setShow(false)} animation={true}>
          <Modal.Header closeButton>
            <Modal.Title> {captionModal} </Modal.Title>

            {dados.status !== "P" && isConsultorio && (
              <Button
                variant="primary"
                style={{ marginLeft: "10px", width: "100px" }}
                onClick={() => {
                  history.push(`/${tipo}/agenda/editar/${dados.id}`);
                }}
              >
                Editar
              </Button>
            )}
          </Modal.Header>

          <Modal.Body style={{ marginLeft: "20px" }}>
            {dados.pessoa.exige_nfe && (
              <div
                style={{
                  marginTop: "3px",
                  marginBottom: "5px",
                  display: "flex",
                  justifyContent: "flex-end",
                  flexDirection: "row",
                }}
              >
                <span
                  style={{
                    padding: "10px",
                    borderRadius: "20px",
                    backgroundColor: "green",
                    color: "white",
                    fontSize: "12px",
                  }}
                >
                  {" "}
                  Exige Nota Fiscal
                </span>
              </div>
            )}

            <div style={{ marginTop: "10px" }}>
              <span style={{ color: "#082044", fontSize: "18px" }}>
                <strong>Profissional:</strong>{" "}
                <span style={{ color: "gray" }}>{dados.profissional.nome}</span>
              </span>
              <Button
                variant="info"
                size="sm"
                style={{ marginLeft: "15px", marginTop: "-5px", width: "35px" }}
                onClick={() => setEditandoProfissional(!editandoProfissional)}
              >
                {" "}
                <FontAwesomeIcon className="icone" icon={faPen} />
              </Button>
            </div>

            {editandoProfissional && (
              <div style={{ marginTop: "10px" }}>
                <Col className="form-group" md={10}>
                  <Select
                    name="id_novo_profissional"
                    value={novoProfissional}
                    placeholder="Novo Profissional"
                    options={profissionaisSelect}
                    onChange={(option) => {
                      setNovoProfissional(option);
                    }}
                  />
                  <Button
                    variant="salvar"
                    type="submit"
                    onClick={submitAlterarProfissional}
                  >
                    <FontAwesomeIcon className="icone" icon="save" /> Salvar
                  </Button>
                </Col>
              </div>
            )}

            <div style={{ marginTop: "10px" }}>
              <span style={{ color: "#082044", fontSize: "20px" }}>
                <strong>Paciente:</strong>{" "}
                <span style={{ color: "gray" }}> {dados.pessoa.nome}</span>
              </span>
            </div>

            <div style={{ marginTop: "15px" }}>
              <strong>Data inicial:</strong>{" "}
              <span style={{ marginLeft: "5px" }}>
                {dateTimeToView(dados.data_inicio)}
              </span>
              <Button
                variant="info"
                size="sm"
                style={{ marginLeft: "15px", marginTop: "-5px", width: "35px" }}
                onClick={() => setEditandoData(!editandoData)}
              >
                {" "}
                <FontAwesomeIcon className="icone" icon={faPen} />
              </Button>
            </div>
            <div>
              <strong style={{ color: "red" }}>Data Final:</strong>{" "}
              <span style={{ marginLeft: "5px", color: "red" }}>
                {dateTimeToView(dados.data_fim)}
              </span>
            </div>

            {editandoData && (
              <div style={{ marginTop: "10px", marginRight: "1px" }}>
                <Formik
                  enableReinitialize
                  initialValues={evento}
                  onSubmit={(e) => {
                    console.log(e);
                    handleSubmit(e);
                  }}
                  validationSchema={schema}
                  validateOnChange={false}
                  validateOnBlur={false}
                >
                  <Form>
                    <div>
                      <label>Data início*</label>
                      <br />
                      <DatePickerField
                        withPortal
                        dateFormat="dd/MM/yyyy HH:mm"
                        showTimeSelect
                        name="data_inicio"
                        placeholder="Digite a data de evolução*"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="data_inicio"
                        component="div"
                        style={{ fontSize: "16px", color: "red" }}
                      />

                      <label>Data Fim*</label>
                      <br />
                      <DatePickerField
                        withPortal
                        dateFormat="dd/MM/yyyy HH:mm"
                        showTimeSelect
                        name="data_fim"
                        placeholder="Digite a data de evolução*"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="data_fim"
                        component="div"
                        style={{ fontSize: "16px", color: "red" }}
                      />

                      <Button
                        size="sm"
                        style={{ marginTop: "5px" }}
                        variant="success"
                        type="submit"
                      >
                        Salvar
                      </Button>
                    </div>
                  </Form>
                </Formik>
                {/* <DatePickerField
                  withPortal
                  dateFormat="dd/MM/yyyy HH:mm"
                  showTimeSelect
                  name="data_inicio"
                  placeholder="Digite a data de evolução*"
                  className="form-control"
                  value={newDataInit}
                  onChange={(date) => setNewDataInit(date)}
                /> */}
              </div>
            )}

            <div style={{ marginTop: "15px" }}>
              <span style={{ color: "#082044", fontSize: "18px" }}>
                {" "}
                <strong>Observação</strong>{" "}
              </span>
              <Button
                variant="info"
                size="sm"
                style={{ marginLeft: "15px", marginTop: "-5px", width: "35px" }}
                onClick={() => setEditandoObservacao(!editandoObservacao)}
              >
                {" "}
                <FontAwesomeIcon className="icone" icon={faPen} />
              </Button>
            </div>

            {editandoObservacao ? (
              <div style={{ marginTop: "10px", marginRight: "1px" }}>
                <textarea
                  className="form-control"
                  name="observacao"
                  placeholder={dados.observacao}
                  onChange={(event) => {
                    setNovaObservacao(event.target.value);
                  }}
                />
                <Button
                  size="sm"
                  style={{ marginTop: "5px" }}
                  variant="success"
                  onClick={submitAlterarProfissional}
                >
                  {" "}
                  Salvar
                </Button>
              </div>
            ) : (
              <div>
                <span> {dados.observacao}</span>
              </div>
            )}

            <div style={{ marginTop: "10px" }}>
              <AlertGeral retorno={retorno} />
              <Spinner loading={loading} />
            </div>

            {isConsultorio && dados.status === "P" && (
              <>
                {faturasFinanceiro.length > 0 ? (
                  <Panel header="Faturas Financeiro">
                    {faturasFinanceiro.map((item) => (
                      <Row style={{ marginTop: "10px" }}>
                        <Col>
                          <FontAwesomeIcon icon={faArrowAltCircleRight} />
                          <strong style={{ marginLeft: "10px" }}>
                            Vencimento:
                          </strong>{" "}
                          {dateToView(item.data_vencimento)}
                          <strong style={{ marginLeft: "29px" }}>
                            Valor:
                          </strong>{" "}
                          {formatMoeda(item.valor)}
                          {item.status === "P" && (
                            <Badge
                              style={{ marginLeft: "5px" }}
                              variant="success"
                            >
                              Pago
                            </Badge>
                          )}
                        </Col>
                      </Row>
                    ))}
                  </Panel>
                ) : (
                  <Formik
                    enableReinitialize
                    initialValues={dadosFinanceiro}
                    onSubmit={submitGravarFinanceiro}
                    validationSchema={schemaFinanceiro}
                    validateOnChange={false}
                    validateOnBlur={false}
                  >
                    {({ errors }) => (
                      <Form>
                        <Row style={{ marginTop: "10px" }}>
                          <Col>
                            <Button
                              variant="primary"
                              onClick={() =>
                                setGerarFinanceiro(!gerarFinanceiro)
                              }
                            >
                              Gerar Financeiro
                            </Button>
                          </Col>
                        </Row>

                        {gerarFinanceiro && (
                          <Row style={{ marginTop: "10px" }}>
                            <Col>
                              <PanelContasReceber
                                profissionais={profissionais}
                                procedimentos={procedimentosForSelect}
                                caixas={caixas}
                                errors={errors}
                                dadosFinanceiro={dadosFinanceiro}
                                setDadosFinanceiro={setDadosFinanceiro}
                              />
                            </Col>
                          </Row>
                        )}
                      </Form>
                    )}
                  </Formik>
                )}
              </>
            )}
          </Modal.Body>

          <Modal.Footer>
            <Col style={{ display: "flex" }}>
              <Button
                variant="danger"
                style={{ width: "100%" }}
                onClick={() =>
                  history.push(
                    `/cancelarAgendamento/${tipo}/${dados.id}/${dataQuadro}`
                  )
                }
              >
                Cancelar Presença
              </Button>

              {getConteudoBtnConfirma()}
            </Col>
          </Modal.Footer>
        </Modal>
      </>
    );
  else return "";
};

export default ModalViewAgenda;
