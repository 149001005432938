import {
  faAddressCard,
  faCalendar,
  faCog,
  faDollarSign,
  faEnvelope,
  faFileMedicalAlt,
  faHome,
  faKey,
  faQuestionCircle,
  faSync,
  faUserCog,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Image, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import { AlertGeral, requisitarAPI } from "../../components/AlertGeral";
import Spinner from "../../components/Spiner";
import api from "../../services/api";
import { Creators as UsuarioLogadoActions } from "../../Store/Controls/usuarioLogado";
import ModalAlterarSenha from "./modalAlterarSenha.js";

export default function MainMenu({ navigation }) {
  const [retorno, setRetorno] = useState({});
  const [selectHome, setSelectHome] = useState(false);
  const [selectAgendaConsultas, setSelectAgendaConsultas] = useState(false);
  const [selectQuadroFuncionarios, setSelectQuadroFuncionarios] =
    useState(false);
  const [selectPessoas, setSelectPessoas] = useState(false);
  const [selectFornecedores, setSelectFornecedores] = useState(false);
  const [selectDiagnosticos, setSelectDiagnosticos] = useState(false);
  const [selectFinanceiro, setSelectFinanceiro] = useState(false);
  const [selectAdministradorUsuario, setselectAdministradorUsuario] =
    useState(false);
  const [selectAdministradorPerfil, setselectAdministradorPerfil] =
    useState(false);
  const [SelectMensagensUsuarios, setSelectMensagensUsuarios] = useState(false);
  const [selectConsultoriolistasEspera, setselectConsultoriolistasEspera] =
    useState(false);
  const [selectConsultorioCancelamentos, setselectConsultorioCancelamentos] =
    useState(false);
  const [selectVersao, setselectVersao] = useState(false);
  const [loading, setLoading] = useState(true);
  const [modalAlterarSenha, setModalAlterarSenha] = useState(false);
  const usuarioLogado = useSelector((state) => state.usuarioLogado);
  const dispatch = useDispatch();

  //Ao criar o componente seta os dados
  useEffect(() => {
    const path = navigation.location.pathname.toUpperCase();

    setSelectHome(path === "/");
    setSelectPessoas(path.includes("PESSOAS"));
    setSelectFornecedores(path.includes("FORNECEDORES"));
    setSelectDiagnosticos(path.includes("DIAGNOSTICOS"));
    setSelectFinanceiro(path.includes("FINANCEIRO"));
    setSelectAgendaConsultas(path.includes("CONSULTORIO/AGENDA"));
    setSelectQuadroFuncionarios(path.includes("CONSULTORIO/QUADRO"));
    setselectConsultoriolistasEspera(path.includes("CONSULTORIO/LISTAESPERA"));
    setselectConsultorioCancelamentos(
      path.includes("CONSULTORIO/EVENTOSCANCELADOS")
    );
    setselectVersao(path.includes("VERSAO"));
    setselectAdministradorUsuario(path.includes("/USUARIOS"));
    setselectAdministradorPerfil(path.includes("PERFIS"));
    setSelectMensagensUsuarios(path.includes("MENSAGENSUSUARIOS"));
  }, [usuarioLogado, navigation.location.pathname]);

  const logout = () => {
    setLoading(true);

    localStorage.removeItem("APP_TOKEN");
    localStorage.removeItem("REFRESH_TOKEN");
    dispatch(UsuarioLogadoActions.removeUserLogado());
    navigation.push("/login");
    setLoading(false);
  };

  function getStyle(select = false, ...REST) {
    let styled = {
      marginRight: "20px",
    };

    if (select) {
      styled = Object.assign(styled, { color: "#2f81fd" });
    }

    styled = Object.assign(styled, ...REST);
    return styled;
  }

  async function atualizarUser() {
    setLoading(true);

    let ret = await requisitarAPI(
      () => api.get("usuario/" + usuarioLogado.id),
      "Falha ao obter usuario"
    );
    setRetorno(ret);
    if (!ret.erro) {
      dispatch(UsuarioLogadoActions.addUserLogado(ret.response.data));
    } else {
      alert(ret.descriptErro);
    }

    setLoading(false);
  }

  return (
    <Navbar expand="xl" variant="dark" className="Navbar" fixed="sticky">
      <ModalAlterarSenha
        show={modalAlterarSenha}
        setShow={setModalAlterarSenha}
        usuarioLogado={usuarioLogado}
      />
      <AlertGeral retorno={retorno} />
      <Spinner loading={loading} />

      <Navbar.Brand
        style={{ marginRight: "3em" }}
        href="/"
        title="Overall.Cloud"
        alt="Overall.Cloud"
      >
        <Image src={logo} width="150" />
      </Navbar.Brand>

      <Navbar.Toggle aria-controls="responsive-navbar-nav" />

      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <Link to="/">
            <Nav.Link
              style={getStyle(selectHome)}
              href="/"
              title="Principal"
              alt="Principal"
            >
              <FontAwesomeIcon icon={faHome} className="icone-menu" /> Principal
            </Nav.Link>
          </Link>

          <NavDropdown
            id="collasible-nav-dropdown"
            style={getStyle(selectHome)}
            title={
              <div
                style={getStyle(
                  selectAgendaConsultas ||
                    selectConsultoriolistasEspera ||
                    selectConsultorioCancelamentos,
                  { display: "inline-block", marginRight: "" }
                )}
              >
                <FontAwesomeIcon className="icone-menu" icon={faCalendar} />{" "}
                Agendamento
              </div>
            }
          >

            {/* visualizar quadro de horarios  */}
            <Link
              to={`/consultorio/quadro/${moment()
                .format("YYYY-MM-DD")
                .toString()}`}
            >
              {/* <Link to={`/consultorio/quadro/2022-06-20`}> */}
              <NavDropdown.Item
                style={getStyle(selectQuadroFuncionarios)}
                // href="/consultorio/quadro"
                href={`/consultorio/quadro/${moment()
                  .format("YYYY-MM-DD")
                  .toString()}`}
              >
                Quadro
              </NavDropdown.Item>
            </Link>

            {usuarioLogado.perfil.permite_visualizar_agenda_consultas && (
              <NavDropdown.Item
                style={getStyle(selectConsultoriolistasEspera)}
                href="/listaEspera/consultorio"
              >
                {" "}
                Lista Espera
              </NavDropdown.Item>
            )}
            {usuarioLogado.perfil.permite_visualizar_agenda_consultas && (
              <NavDropdown.Item
                style={getStyle(selectConsultorioCancelamentos)}
                href="/eventosCancelados/consultorio"
              >
                {" "}
                Cancelamentos
              </NavDropdown.Item>
            )}

            {usuarioLogado.perfil.permite_visualizar_agenda_consultas && (
              <NavDropdown.Item
                style={getStyle(selectConsultorioCancelamentos)}
                href="/listaTags/consultorio"
              >
                Convênio
              </NavDropdown.Item>
            )}
            {usuarioLogado.perfil.permite_visualizar_agenda_consultas && (
              <NavDropdown.Item
                style={getStyle(selectConsultorioCancelamentos)}
                href="/listaProcedimentos/consultorio"
              >
                Procedimentos
              </NavDropdown.Item>
            )}
            {usuarioLogado.perfil.permite_visualizar_agenda_consultas && (
              <NavDropdown.Item
                style={getStyle(selectConsultorioCancelamentos)}
                href="/guias/consultorio"
              >
                Guias
              </NavDropdown.Item>
            )}
          </NavDropdown>

          {usuarioLogado.perfil.permite_gerenciar_clientes && (
            <Link to="/pessoas">
              <Nav.Link
                style={getStyle(selectPessoas)}
                href="/pessoas"
                title="Pessoas"
                alt="Pessoas"
              >
                {" "}
                <FontAwesomeIcon className="icone-menu" icon={faUsers} />{" "}
                Pessoas
              </Nav.Link>
            </Link>
          )}

          {usuarioLogado.perfil.permite_gerenciar_clientes && (
            <Link to="/fornecedores">
              <Nav.Link
                style={getStyle(selectFornecedores)}
                href="/fornecedores"
                title="fornecedores"
                alt="fornecedores"
              >
                {" "}
                <FontAwesomeIcon className="icone-menu" icon={faUsers} />{" "}
                Fornecedores
              </Nav.Link>
            </Link>
          )}

          {usuarioLogado.perfil.permite_gerenciar_diagnosticos && (
            <Link to="/diagnosticos">
              <Nav.Link
                style={getStyle(selectDiagnosticos)}
                href="/diagnosticos"
                title="Diagnósticos"
                alt="Diagnósticos"
              >
                {" "}
                <FontAwesomeIcon
                  className="icone-menu"
                  icon={faFileMedicalAlt}
                />{" "}
                Diagnósticos
              </Nav.Link>
            </Link>
          )}

          {usuarioLogado.perfil.permite_gerenciar_financeiro && (
            <Link to="/financeiro/resumo">
              <Nav.Link
                style={getStyle(selectFinanceiro)}
                href="/financeiro/resumo"
                title="Financeiro"
                alt="Financeiro"
              >
                {" "}
                <FontAwesomeIcon
                  className="icone-menu"
                  icon={faDollarSign}
                />{" "}
                Financeiro
              </Nav.Link>
            </Link>
          )}
        </Nav>
        <Nav>
          <Nav.Link
            className="navbar-text"
            href="/ajuda"
            title="Tire as principais dúvidas da aplicação"
          >
            {" "}
            <FontAwesomeIcon className="icone" icon={faQuestionCircle} />
          </Nav.Link>
          <Nav.Link
            className="navbar-text"
            href="/versao"
            title="Versão (updates) da Aplicação"
            alt="Versão (updates) da Aplicação"
            style={getStyle(selectVersao)}
          >
            v1.1.1
          </Nav.Link>
        </Nav>

        <Nav>
          <NavDropdown
            style={{ marginRight: "1em" }}
            alt="Usuário logado"
            title={
              <div
                style={getStyle(
                  selectAdministradorUsuario ||
                    selectAdministradorPerfil ||
                    SelectMensagensUsuarios
                )}
                className="dropdown-usuario"
              >
                <FontAwesomeIcon className="icone-menu" icon={faCog} />{" "}
                {usuarioLogado.nome}
              </div>
            }
            id="basic-nav-dropdown"
            className="drop-menu"
          >
            <Link to="/configuracoes">
              <NavDropdown.Item href="/configuracoes">
                <FontAwesomeIcon className="icone" icon={faCog} /> Configurações
              </NavDropdown.Item>
            </Link>

            {usuarioLogado.perfil.permite_gerenciar_usuarios && (
              <>
                <Link to="/usuarios">
                  <NavDropdown.Item
                    href="/usuarios"
                    style={getStyle(selectAdministradorUsuario)}
                  >
                    <FontAwesomeIcon className="icone" icon={faUserCog} />
                    Gerenciar Usuários
                  </NavDropdown.Item>
                </Link>

                <Link to="/Perfis">
                  <NavDropdown.Item
                    href="/Perfis"
                    style={getStyle(selectAdministradorPerfil)}
                  >
                    <FontAwesomeIcon className="icone" icon={faAddressCard} />{" "}
                    Gerenciar Perfis
                  </NavDropdown.Item>
                </Link>

                <NavDropdown.Divider />
              </>
            )}

            <NavDropdown.Item onClick={atualizarUser}>
              {" "}
              <FontAwesomeIcon className="icone" icon={faSync} /> Atualizar
              permissões
            </NavDropdown.Item>

            <Link to="/mensagensUsuarios">
              <NavDropdown.Item
                href="/mensagensUsuarios"
                style={getStyle(SelectMensagensUsuarios)}
              >
                <FontAwesomeIcon className="icone" icon={faEnvelope} />{" "}
                Mensagens de usuários
              </NavDropdown.Item>
            </Link>

            <Link to="/mensagensUsuarios">
              <NavDropdown.Item
                onClick={() => {
                  setModalAlterarSenha(true);
                }}
              >
                {" "}
                <FontAwesomeIcon className="icone" icon={faKey} /> Alterar Senha
              </NavDropdown.Item>
            </Link>

            <NavDropdown.Divider />

            <NavDropdown.Item onClick={logout}> Sair </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
