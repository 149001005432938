import { createActions, createReducer } from "reduxsauce";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  addCaixas: ["caixas"],
  addCaixa: ["caixa"],
  editCaixa: ["id", "caixa"],
  removeCaixa: ["id"],
});

/**
 * Implementação das manipulações do State
 */
const INITIAL_STATE = [];

const addCaixas = (state = INITIAL_STATE, action) => action.caixas;

const add = (state = INITIAL_STATE, action) => [...state, action.caixa];

const edit = (state = INITIAL_STATE, action) =>
  state.map((item) => {
    if (item.id === action.id) {
      return action.conta;
    } else {
      return item;
    }
  });

const remove = (state = INITIAL_STATE, action) =>
  state.filter((todo) => todo.id !== action.id);

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.ADD_CAIXAS]: addCaixas,
  [Types.ADD_CAIXA]: add,
  [Types.EDIT_CAIXA]: edit,
  [Types.REMOVE_CAIXA]: remove,
});
