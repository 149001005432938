import moment from "moment";
import React, { useState } from "react";
import { Alert, Button, Col, Modal, Row } from "react-bootstrap";
import Select from "react-select";
import ModalGuiasfinalizada from "../../pages/Agendas/modalGuiasFinalizadas.js";
import ModalViewAgenda from "../../pages/Agendas/modalViewAgenda.js";

export default function ModalQuadroSel({
  // toggleModal,
  // handlePrint,
  // handleSave,
  eventosM,
  show,
  setShow,
  history,
}) {
  const [pessoaSelect, setPessoaSelect] = useState();
  const [showModalView, setShowModalView] = useState(false);
  const [dadosClickEvent, setDadosClickEvent] = useState("");
  const [showModalAulasConcluidas, setShowModalAulasConcluidas] =
    useState(false);
  const [erroVazio, setErroVazio] = useState(false);
  const [clicouView, setClicouView] = useState(false);
  const [onGuiasFinalizadas, setOnGuiasFinalizadas] = useState(false);
  const [idGuia, setIdGuia] = useState({});

  function formataPessoasSelect() {
    let pessoas = [];

    eventosM.map((evento) => {
      if (typeof evento.pessoa !== "undefined")
        pessoas.push({
          label: evento.pessoa.nome,
          value: evento.pessoa.id,
          evento: evento,
        });
    });

    return pessoas;
  }

  const fecharModal = () => {
    setPessoaSelect();
    setErroVazio(false);
    setShow(false);
    if (clicouView) {
      window.location.reload(false);
    }
  };

  const handleNovoEvento = () => {
    let start = eventosM[0].data_inicio;
    let end = moment(eventosM[0].data_inicio).add(30, "minutes");

    history.push(
      `/consultorio/agenda/cadastro/${new Date(start)}/${new Date(end)}/${
        eventosM[0].id_profissional
      }/${moment(eventosM[0].data_inicio).format("YYYY-MM-DD")}`
    );
  };

  return (
    <>
      <ModalGuiasfinalizada
        show={onGuiasFinalizadas}
        setShow={setOnGuiasFinalizadas}
        guia={idGuia}
      />
      <ModalViewAgenda
        tipo="consultorio"
        history={history}
        dados={dadosClickEvent}
        show={showModalView}
        setShow={setShowModalView}
        setShowModalAulasConcluidas={setShowModalAulasConcluidas}
        setGuiaFinalizada={setOnGuiasFinalizadas}
        dataQuadro={moment(eventosM[0].data_inicio).format("YYYY-MM-DD")}
        setIdGuia={setIdGuia}
      />

      <Modal show={show} onHide={fecharModal} animation={true}>
        <Modal.Body>
          <h3>Escolha uma opção</h3>

          <Row>
            <Col style={{ display: "flex" }}>
              <Button variant="success" onClick={() => handleNovoEvento()}>
                Nova agenda
              </Button>
            </Col>
          </Row>
          <br />

          <Row>
            <Col>
              <span>Selecione uma pessoa *</span>
            </Col>
          </Row>
          {erroVazio && (
            <Row>
              <Col md="4">
                <Alert variant="light">Informe um valor</Alert>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <Select
                name="pessoasel"
                value={pessoaSelect}
                placeholder="Pessoa"
                options={formataPessoasSelect()}
                onChange={(option) => {
                  setPessoaSelect(option);
                }}
              />
            </Col>

            <Col>
              <Button
                variant="info"
                style={{ width: "100%" }}
                onClick={() => {
                  if (typeof pessoaSelect === "undefined") {
                    setErroVazio(true);
                  } else {
                    setDadosClickEvent(pessoaSelect.evento);
                    setShowModalView(true);
                    setClicouView(true);
                  }
                }}
              >
                Visualizar agenda
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
