import React, { useState, useEffect } from "react";
import { Panel } from "primereact/panel";
import { Field, ErrorMessage } from "formik";
import { Col, Row, Button } from "react-bootstrap";
import FieldValidate from "../../components/Form/FieldValidate.js";
import { MoedaFormat } from "../../components/Form/MoedaFormat.js";
import { DatePickerField } from "../../components/Form/DatePicker.js";
import { SelectComponent } from "../../components/Form/Select.js";
import { getFormaPagamentoForSelect } from "../../Global/chamadasAPI";

const PanelContasReceber = ({ caixas, errors, profissionais, procedimentos, dadosFinanceiro, setDadosFinanceiro }) => {
  useEffect(() => {
    async function getDadosSelectsFormaPagamento() {
      setFormasPagamento(await getFormaPagamentoForSelect());
    }

    getDadosSelectsFormaPagamento();
  }, []);

  const [isBaixaAutomatica, setIsBaixaAutomatica] = useState(false);
  const [isEmitirNf, setIsEmitirNf] = useState(false);
  const [formasPagamento, setFormasPagamento] = useState([{}]);

  return (
    <Panel header="Financeiro">
      <Row>
     
        <input type="hidden" name="id_profissional" />
        <Col>
          <label className="obrigatorio">Procedimento*</label>
          <FieldValidate
            name="id_procedimento"
            placeholderText="Selecione o procedimento"
            component={SelectComponent}
            options={procedimentos}
            errors={errors}
 
            noClass
          />
        </Col>
      </Row>

      <Row style={{ marginTop: "20px" }}>
        <Col>
          <label className="obrigatorio">Descrição*</label>
          <FieldValidate
            name="descricao"
            placeholder="Digite a descrição da baixa*"
            type="text"
            errors={errors}
          />
        </Col>
        <Col>
          <label className="obrigatorio">Caixa*</label>
          <FieldValidate
            name="id_caixa"
            placeholderText="Nome do caixa"
            component={SelectComponent}
            options={caixas}
            errors={errors}
            noClass
          />
        </Col>
      </Row>

      <Row style={{ marginTop: "20px" }}>
        <Col>
          <label className="obrigatorio">Valor*</label>
          <FieldValidate
            component={MoedaFormat}
            name="valor"
            placeholder="Digite o Valor"
            errors={errors}
          />
        </Col>

        <Col>
          <label className="obrigatorio">Data de Vencimento*</label>
          <br />
          <DatePickerField
            withPortal
            placeholderText="Informe a data de Vencimento*"
            name="data_vencimento"
            className="form-control"
          />
          <ErrorMessage
            name={"data_vencimento"}
            component="div"
            style={{ fontSize: "16px", color: "red" }}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: "20px" }}>
        <Col>
          <label>Parcelas</label>
          <FieldValidate
            name="parcelas_total"
            placeholder="Digite a quantidade de Parcelas"
            errors={errors}
          />
        </Col>

        <Col>
          <label>Intervalo de Dias</label>
          <FieldValidate
            name="parcelas_intervalo"
            placeholder="Digite o Intervalo de Dias"
            errors={errors}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: "5px" }}>
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            AlignItems: "center",
          }}
        >
          <Field
            name="baixar_duplicata"
            type="checkbox"
            onClick={(event) => setIsBaixaAutomatica(event.target.checked)}
            defaultChecked={isBaixaAutomatica}
            style={{ marginRight: "0px" }}
          />
          <label style={{ color: "green", marginTop: "10px" }}>
            {" "}
            Baixar duplicata automáticamente (Pago)
          </label>
          <br />
        </Col>
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            AlignItems: "center",
          }}
        >
          <Field
            name="emited_nf"
            type="checkbox"
            onClick={(event) => setIsEmitirNf(event.target.checked)}
            defaultChecked={isEmitirNf}
            style={{ marginRight: "0px" }}
          />
          <label style={{ color: "green", marginTop: "10px" }}>
            {" "}
            Emitir nota fiscal?
          </label>
          <br />
        </Col>
      </Row>

      {isBaixaAutomatica && (
        <Row style={{ marginTop: "5px" }}>
          <Col style={{ marginTop: "5px" }}>
            <label className="obrigatorio">Forma de Pagamento</label>
            <FieldValidate
              name="pagamento_id_forma_pagamento"
              placeholder="Forma de pagamento"
              component={SelectComponent}
              options={formasPagamento}
              errors={errors}
              noClass
            />
          </Col>
        </Row>
      )}

      {isEmitirNf && (
        <Row style={{ marginTop: "5px" }}>
        <Col style={{ marginTop: "5px" }}>
          <label className="obrigatorio">Informe um CPF para a nota fiscal!</label>
          <FieldValidate
            name="nf_cpf"
            placeholder="CNPJ"
            errors={errors}
          />
        </Col>
      </Row>
      )}

      <Button
        variant="success"
        type="submit"
        style={{ width: "100%", marginTop: "10px" }}
      >
        Salvar Financeiro
      </Button>
    </Panel>
  );
};

export default PanelContasReceber;
