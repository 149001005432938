import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";

import MainMenu from "../../components/MainMenu";
import Spinner from "../../components/Spiner";
import "../../Global/styleGeral.css";
import Comissao from "./comissao";

const Comissoes = ({ history }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, []);

  if (!loading) {
    return (
      <>
        <MainMenu navigation={history} />

        <div className="titulo-pagina">
          <Tabs
            activeKey={"comissoes"}
            onSelect={(k) => {
              history.push(`/financeiro/${k}`);
            }}
            className="mb-3"
          >
            <Tab eventKey="resumo" title="Resumo"></Tab>
            <Tab eventKey="movimentacoes" title="Movimentações"></Tab>
            <Tab eventKey="contas-a-receber" title="Contas a Receber"></Tab>
            <Tab eventKey="contas-a-pagar" title="Contas a Pagar"></Tab>
            <Tab eventKey="caixas" title="Caixas"></Tab>
            <Tab eventKey="formas-de-pagamento" title="Formas de Pagamento"></Tab>
            <Tab eventKey="comissoes" title="Comissões">
                <Comissao history={history} />
            </Tab>
          </Tabs>
        </div>
      </>
    );
  } else {
    return (
      <div
        style={{
          width: "220px",
          height: "30px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        <h4
          style={{
            color: "#123abc",
            fontSize: "18px",
            fontWeight: "bold",
            textTransform: "uppercase",
            margin: "10px 0px",
          }}
        >
          Carregando..
        </h4>
        <Spinner height={20} animation="grow" variant="primary" />
      </div>
    );
  }
};

export default Comissoes;
