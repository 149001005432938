import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Button, Card, Col, Alert, Row, Badge } from "react-bootstrap";
import { Formik, Form, ErrorMessage } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { Creators as ContasPagarActions } from "../../Store/Controls/financeiros/contasPagar";
import "../../Global/styleGeral.css";
import { AlertGeral, requisitarAPI } from "../../components/AlertGeral";
import api from "../../services/api";
import MainMenu from "../../components/MainMenu";
import FieldValidate from "../../components/Form/FieldValidate.js";
import { DatePickerField } from "../../components/Form/DatePicker.js";
import { SelectComponent } from "../../components/Form/Select.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { dateToView, dateToField, formatMoeda } from "../../Global/funcoes";
import { MoedaFormat } from "../../components/Form/MoedaFormat.js";
import {
  getFormaPagamentoForSelect,
  getConfig,
  getCaixasForSelect,
} from "../../Global/chamadasAPI";
import Spinner from "../../components/Spiner";
import ModalRestantePagamento from "./modalRestantePagamento";

const PagamentoContasPagar = ({ history, match }) => {
  const [contaPagar, setContaPagar] = useState({});
  const [infosPanel, setInfosPanel] = useState([{}]);
  const [formasPagamento, setFormasPagamento] = useState([{}]);
  const [retorno, setRetorno] = useState({});
  const [loading, setLoading] = useState(true);
  const [showModalPagamentoRestante, setShowModalPagamentoRestante] =
    useState(false);
  const [config, setConfig] = useState({
    tempoagenda: "45",
    modelo_financeiro: "A",
    editando: false,
  });
  const [caixas, setCaixas] = useState([{}]);

  const contasPagar = useSelector((state) => state.contasPagar);
  const dispatch = useDispatch();

  async function getDadosSelects() {
    setFormasPagamento(await getFormaPagamentoForSelect());
    setCaixas(await getCaixasForSelect());
  }

  async function getConfiguracoes() {
    const config = await getConfig();
    setConfig(config);
  }

  useEffect(() => {
    setLoading(true);
    getDadosSelects();
    const contaPagar = contasPagar.find((item) => item.id == match.params.id);

    if (contaPagar.length === 0) {
      return history.push("/financeiro/contas-a-pagar");
    }

    // Objeto para tratamento da visualização do panel
    let infosPanel = Object.assign({}, contaPagar);
    infosPanel.data_vencimento = dateToView(infosPanel.data_vencimento);
    infosPanel.valor = formatMoeda(infosPanel.valor);

    if (contaPagar.status !== "P") {
      contaPagar.pagamento_liquido = contaPagar.valor;
      contaPagar.pagamento_data = new Date();
    } else contaPagar.pagamento_data = dateToField(contaPagar.pagamento_data);

    setContaPagar(contaPagar);
    setInfosPanel(infosPanel);
    getConfiguracoes();
    setLoading(false);
  }, [contasPagar, match.params.id]);

  const schema = Yup.object().shape({
    pagamento_data: Yup.date().typeError("Informe uma data válida"),
    pagamento_liquido: Yup.number().required("Informe o Valor Liquído"),
  });

  const handleSubmit = async (values) => {
    setLoading(true);

    values.status = "P";
    let ret = await requisitarAPI(
      () => api.put("contasPagar/" + match.params.id, values),
      "Falha ao editar Conta a Pagar"
    );
    setRetorno(ret);

    if (!ret.erro) {
      dispatch(
        ContasPagarActions.editContaPagar(match.params.id, ret.response.data)
      );
    }

    setLoading(false);

    if (!ret.erro) {
      if (contaPagar.valor > values.pagamento_liquido) {
        contaPagar.data_vencimento = new Date();
        contaPagar.valor = contaPagar.valor - values.pagamento_liquido;
        setContaPagar(contaPagar);

        setShowModalPagamentoRestante(true);
      } else {
        if (config.modelo_financeiro === "M") {
          ret = await requisitarAPI(
            () => api.get("contasPagar?id_pessoa=" + values.id_pessoa),
            "Falha ao obter dados do servidor"
          );
          setRetorno(ret);
          if (!ret.erro) {
            if (ret.response.data.length === 0) {
              history.push(
                "/financeiro/contasPagar/cadastro/" + values.id_pessoa + "/M"
              );
            } else {
              history.push("/financeiro/contas-a-pagar");
            }
          }
        } else {
          history.push("/financeiro/contas-a-pagar");
        }
      }
    }
  };

  const calcValorLiquido = (val, Field) => {
    let valor = 0;
    if (typeof valor !== "undefined") valor = val;

    if (Field.name === "pagamento_desconto")
      contaPagar.pagamento_desconto = valor;
    else contaPagar.pagamento_acrescimo = valor;

    if (typeof contaPagar.pagamento_desconto === "undefined")
      contaPagar.pagamento_desconto = 0;

    if (typeof contaPagar.pagamento_acrescimo === "undefined")
      contaPagar.pagamento_acrescimo = 0;

    contaPagar.pagamento_liquido =
      contaPagar.valor +
      contaPagar.pagamento_acrescimo -
      contaPagar.pagamento_desconto;

    return contaPagar.pagamento_liquido;
  };

  return (
    <>
      <MainMenu navigation={history} />

      <ModalRestantePagamento
        show={showModalPagamentoRestante}
        setShow={setShowModalPagamentoRestante}
        contaReceber={contaPagar}
        history={history}
      />
      <div className="titulo-pagina">
        Pagamento Conta Pagar
        <Button
          variant="voltar"
          onClick={() => {
            history.push("/financeiro/contas-a-pagar");
          }}
        >
          <FontAwesomeIcon className="icone" icon={faChevronLeft} /> Voltar para
          contas a pagar
        </Button>
      </div>

      <div className="container-principal">
        <Card bg="light" style={{ borderColor: "#bdbdbd" }}>
          <Card.Body>
            <Card.Title>
              <h4>
                <b>{infosPanel.descricao}</b>{" "}
                <Badge variant="danger">{infosPanel.data_vencimento}</Badge>
              </h4>
            </Card.Title>
            <Card.Subtitle
              className="mb-2 text-muted"
              style={{ marginTop: "10px" }}
            >
              <b>Pessoa:</b> {infosPanel.pessoa && infosPanel.pessoa.nome}
            </Card.Subtitle>
            <Card.Text style={{ marginTop: "20px" }}>
              <Row>
                <Col>
                  <h3>
                    Valor:{" "}
                    <label style={{ color: "green", fontSize: "25px" }}>
                      {infosPanel.valor}
                    </label>
                  </h3>
                </Col>
              </Row>
            </Card.Text>
          </Card.Body>
        </Card>

        <Formik
          enableReinitialize
          initialValues={contaPagar}
          onSubmit={handleSubmit}
          validationSchema={schema}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ errors }) => (
            <Form>
              <div>
                <Row>
                  <Col className="form-group">
                    <label className="obrigatorio">Data de Pagamento *</label>
                    <br />
                    <DatePickerField
                      withPortal
                      placeholderText="Informe a data de Pagamento*"
                      name="pagamento_data"
                      className="form-control"
                    />
                    <ErrorMessage
                      name={"pagamento_data"}
                      component="div"
                      style={{ fontSize: "16px", color: "red" }}
                    />
                  </Col>

                  <Col className="form-group">
                    <label>Forma Pagamento</label>
                    <FieldValidate
                      name="pagamento_id_forma_pagamento"
                      placeholderText="Informe a Forma de Pagamento"
                      component={SelectComponent}
                      options={formasPagamento}
                      errors={errors}
                      noClass
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xs={4} className="form-group">
                    <label>Desconto</label>
                    <FieldValidate
                      onCalc={calcValorLiquido}
                      targetFieldCalc="pagamento_liquido"
                      component={MoedaFormat}
                      name="pagamento_desconto"
                      placeholder="Digite o Desconto"
                      errors={errors}
                    />
                  </Col>
                  <Col xs={4} className="form-group">
                    <label>Acréscimo</label>
                    <FieldValidate
                      onCalc={calcValorLiquido}
                      targetFieldCalc="pagamento_liquido"
                      component={MoedaFormat}
                      name="pagamento_acrescimo"
                      placeholder="Digite o Valor"
                      errors={errors}
                    />
                  </Col>
                  <Col xs={4} className="form-group">
                    <label className="obrigatorio">Valor Liquído*</label>
                    <FieldValidate
                      component={MoedaFormat}
                      name="pagamento_liquido"
                      placeholder="Digite o Valor"
                      errors={errors}
                    />
                  </Col>
                  <Col>
                    <label className="obrigatorio">Caixa*</label>
                    <FieldValidate
                      name="id_caixa"
                      placeholderText="Nome do caixa"
                      component={SelectComponent}
                      options={caixas}
                      errors={errors}
                      noClass
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="form-group">
                      <label>Observação</label>
                      <FieldValidate
                        width="100%"
                        name="pagamento_observacao"
                        placeholder="Digite a descrição"
                        component="textarea"
                        type="text"
                        errors={errors}
                      />
                    </div>
                  </Col>
                </Row>

                <div className="form-group">
                  <Row>
                    <Col>
                      <AlertGeral retorno={retorno} />
                      <Spinner loading={loading} />
                    </Col>
                  </Row>
                  {contaPagar.status === "P" ? (
                    <Row>
                      <Col>
                        <Alert variant="success">
                          Pagamento já foi efetuado.
                        </Alert>
                      </Col>
                    </Row>
                  ) : (
                    <Button variant="salvar" type="submit">
                      <FontAwesomeIcon className="icone" icon={faSave} /> Salvar
                    </Button>
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default PagamentoContasPagar;
