import { createActions, createReducer } from "reduxsauce";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  addTags: ["tags"],
  addTag: ["tag"],
  editTag: ["id", "tag"],
  removeTag: ["id"],
});

/**
 * Implementação das manipulações do State
 */
const INITIAL_STATE = [];

const addTags = (state = INITIAL_STATE, action) => action.tags;

const add = (state = INITIAL_STATE, action) => [...state, action.tag];

const edit = (state = INITIAL_STATE, action) =>
  state.map((item) => {
    if (item.id === action.id) {
      return action.tag;
    } else {
      return item;
    }
  });

const remove = (state = INITIAL_STATE, action) => {
  return state.filter((todo) => todo.id !== action.id);
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.ADD_TAGS]: addTags,
  [Types.ADD_TAG]: add,
  [Types.EDIT_TAG]: edit,
  [Types.REMOVE_TAG]: remove,
});
