/* eslint-disable react-hooks/exhaustive-deps */
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/nova-light/theme.css";
import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "./App.css";
import "./Global/styleGeral.css";
import Routes from "./routes/";
import { persistor, store } from "./Store";

function App() {
  return (
    <div id="app">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Routes />
        </PersistGate>
      </Provider>
    </div>
  );
}
export default App;
