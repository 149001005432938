import React from "react";
import Select from "react-select";

export const SelectComponent = ({
  options,
  defaultValue,
  field,
  form,
  ...props
}) => {
  const menuIsOpen = props.isReadOnly ? false : props.menuIsOpen;

  return (
    <Select
      {...props}
      menuIsOpen={menuIsOpen}
      placeholder={
        props.placeholder ? props.placeholder : "Selecione uma opção"
      }
      options={options}
      name={field.name}
      // defaultValue={"Selecione uma opção"}
      defaultValue={
        defaultValue !== undefined
          ? options[defaultValue]
          : "Selecione uma opção"
      }
      value={
        options ? options.find((option) => option.value === field.value) : ""
      }
      onChange={(option) => {
        form.setFieldValue(field.name, option.value);
        if (typeof props.onChangeValue !== "undefined") {
          props.onChangeValue(option);
        }
      }}
      onBlur={field.onBlur}
    />
  );
};
