import { ErrorMessage, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import * as Yup from "yup";
import { DatePickerField } from "../../components/Form/DatePicker.js";
import FieldValidate from "../../components/Form/FieldValidate.js";
import { SelectComponent } from "../../components/Form/Select.js";

const ModalAddDiasPilates = ({
  listaDiaSel,
  listaDiasSemana,
  setListaDiasSemana,
  editListaDias,
  countIDDiaSemana,
  setCountIDDiaSemana,
  show,
  setShow,
  exibeHoraFim,
  profissionais,
  listaProfissionaisSemana,
  setListaProfissionaisSemana,
}) => {
  const [captionModal, setCaptionModal] = useState("");

  useEffect(() => {
    setCaptionModal("Adicionar Aula da Semana");
  }, []);

  const schemaModal = Yup.object().shape({
    dia: Yup.number().required("Informe o dia").typeError("Informe o dia"),
    horario: Yup.date()
      .required("Informe o horário")
      .typeError("Informe o horário"),
  });

  const sincronizarDiaEHorario = (dia, horario) => {
    // saber se o dia é maior que o horário.getDay()
    if (dia > horario.getDay()) {
      // se for maior, soma a diferença
      horario.setDate(horario.getDate() + (dia - horario.getDay()));
    } else if (dia < horario.getDay()) {
      // se for menor, subtrai a diferença
      horario.setDate(horario.getDate() - (horario.getDay() - dia));
    }
    return horario;
  };

  const handleModal = async (values) => {
    if (editListaDias) {
      const novaLista = listaDiasSemana.map((item) => {
        if (item.id === listaDiaSel.id) {
          return values;
        } else {
          return item;
        }
      });

      setListaDiasSemana(novaLista);
    } else {
      countIDDiaSemana = countIDDiaSemana + 1;
      setCountIDDiaSemana(countIDDiaSemana);

      values.id = countIDDiaSemana;

      if (!listaProfissionaisSemana) {
        setListaDiasSemana([...listaDiasSemana, values]);
      } else {
        const response = [...listaDiasSemana, values];
        response.forEach((item) => {
          setListaProfissionaisSemana([
            ...listaProfissionaisSemana,
            {
              dia: item.dia,
              horario: item.horario,
              profissional: item.profissional ? item.profissional : undefined,
            },
          ]);
        });
        response.forEach((item) => {
          item.horario = sincronizarDiaEHorario(item.dia, item.horario);
        });
        setListaDiasSemana(response);
      }
    }

    setShow(false);
  };

  return (
    <Modal show={show} onHide={() => setShow(false)} animation={true}>
      <Formik
        enableReinitialize
        initialValues={listaDiaSel}
        onSubmit={handleModal}
        validationSchema={schemaModal}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ errors }) => (
          <Form>
            <Modal.Header closeButton>
              <Modal.Title> {captionModal} </Modal.Title>
            </Modal.Header>

            <Modal.Body style={{ marginLeft: "20px" }}>
              <Row className="form-group">
                <Col>
                  <label className="obrigatorio">Dia da Semana*</label>
                  <FieldValidate
                    name="dia"
                    component={SelectComponent}
                    options={[
                      { label: "Domingo", value: 0 },
                      { label: "Segunda", value: 1 },
                      { label: "Terça", value: 2 },
                      { label: "Quarta", value: 3 },
                      { label: "Quinta", value: 4 },
                      { label: "Sexta", value: 5 },
                      { label: "Sábado", value: 6 },
                    ]}
                    errors={errors}
                    noClass
                  />
                </Col>
              </Row>
              <Row className="form-group">
                <Col md="4">
                  {exibeHoraFim ? (
                    <label className="obrigatorio">Hora inicio*</label>
                  ) : (
                    <label className="obrigatorio">Horário*</label>
                  )}
                  <DatePickerField
                    dateFormat="HH:mm"
                    showTimeSelect
                    showTimeSelectOnly
                    name="horario"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="horario"
                    component="div"
                    style={{ fontSize: "16px", color: "red" }}
                  />
                </Col>

                {exibeHoraFim && (
                  <Col md="4">
                    <label className="obrigatorio">Hora fim*</label>
                    <DatePickerField
                      dateFormat="HH:mm"
                      showTimeSelect
                      showTimeSelectOnly
                      name="horario_fim"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="horario_fim"
                      component="div"
                      style={{ fontSize: "16px", color: "red" }}
                    />
                  </Col>
                )}
              </Row>
              {listaProfissionaisSemana && (
                <Row className="form-group">
                  <Col>
                    <label>Profissional - (opicional)</label>
                    <FieldValidate
                      name="profissional"
                      component={SelectComponent}
                      options={profissionais}
                      errors={errors}
                      noClass
                    />
                  </Col>
                </Row>
              )}
            </Modal.Body>

            <Modal.Footer>
              <Col style={{ display: "flex" }}>
                <Button
                  variant="success"
                  style={{ width: "100%" }}
                  type="submit"
                >
                  {editListaDias ? "Editar" : "Adicionar"}
                </Button>
              </Col>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ModalAddDiasPilates;
