import moment from "moment";
import React from "react";
import { Col, Modal, Row } from "react-bootstrap";

const ModalFiles = React.forwardRef((props, ref) => {
  let fileData = [];

  if (props.files) {
    for (const i in props.files) {
      const file = props.files[i];
      const urlFile = process.env.REACT_APP_AWS_S3_URL + "/" + file.nome;
      const descricao = file.descricao;
      const created_at = moment(file.createdAt).format("DD/MM/YYYY - HH:mm:ss");
      const id = file.id;

      fileData.push({
        key: id,
        urlFile,
        descricao,
        created_at,
      });
    }
  }

  const hideModal = () => {
    props.setShow(false);
  };

  return (
    <Modal
      size="lg"
      show={props.show}
      onHide={() => hideModal()}
      animation={true}
    >
      <Modal.Header closeButton>
        <Modal.Title> Arquivos </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="form-group container-files list">
          <Col>
            <strong>Arquivo</strong>
          </Col>
          <Col>
            <strong>Descrição</strong>
          </Col>
          <Col>
            <strong>Data</strong>
          </Col>
          <Col>
            <strong>Ação</strong>
          </Col>
        </Row>
        {fileData.map((obj) => (
          <Row
            className="form-group container-files list"
            id={obj.key}
            key={obj.key}
          >
            <Col>
              <img src={obj.urlFile} />
            </Col>
            <Col>
              <label>{obj.descricao}</label>
            </Col>
            <Col>
              <label>{obj.created_at}</label>
            </Col>
            <Col>
              <a href={obj.urlFile} target="_blank">
                Ver arquivo
              </a>
            </Col>
          </Row>
        ))}
      </Modal.Body>
    </Modal>
  );
});

export default ModalFiles;
