/* eslint-disable react-hooks/exhaustive-deps */
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { AlertGeral, requisitarAPI } from "../../components/AlertGeral";
import { colunasListaGuia } from "../../components/Guia/colunas";
import MainMenu from "../../components/MainMenu";
import Spinner from "../../components/Spiner";
import "../../Global/styleGeral.css";
import api from "../../services/api";
import { Creators as ListaTagsAction } from "../../Store/Controls/listaTags";

import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ListagemGuias from "../../components/Guia";

const ListaGuias = ({ history, match }) => {
  const [retorno, setRetorno] = useState({}); // resposta do servidor
  const [loading, setLoading] = useState(true); // carregando

  const [dadosGuias, setDadosGuias] = useState([]); // resposta do servidor
  const [nFilter, setNFilter] = useState(0);

  const newDefineIcon = (filter, item) => {
    // concluidos
    if (filter === 2) return <span>🟢</span>;
    // encerramento em 3 dias
    else if (filter === 3) return <span>🟡</span>;
    // fechados
    else if (filter === 4) return <span>🔴</span>;
    // todos
    else {
      const dataAtual = new Date();
      const dataProximos3Dias = new Date();
      dataProximos3Dias.setDate(dataAtual.getDate() + 3);

      if (item.quantidade_consultas === item.quantidade_consultas_realizadas)
        return newDefineIcon(2);
      else if (new Date(item.data_vencimento_guia_utc) <= dataProximos3Dias)
        return newDefineIcon(3);
      else if (item.fechado) return newDefineIcon(4);
      else return <span></span>;
    }
  };

  const newFiltros = (filtro) => {
    let dados = [];
    // concluidos 🟢
    if (filtro === 2)
      dados = retorno.filter(
        (item) =>
          item.quantidade_consultas === item.quantidade_consultas_realizadas
      );
    // encerramento em 3 dias 🟡
    else if (filtro === 3) {
      const dataAtual = new Date();
      const dataProximos3Dias = new Date();
      dataProximos3Dias.setDate(dataAtual.getDate() + 3);

      dados = retorno.filter((item) => {
        return (
          new Date(item.data_vencimento_guia_utc) <= dataProximos3Dias &&
          new Date(item.data_vencimento_guia_utc) >= dataAtual
        );
      });
    }
    // fechados 🔴
    else if (filtro === 4)
      dados = retorno.filter(
        (item) =>
          item.fechado &&
          item.quantidade_consultas > item.quantidade_consultas_realizadas
      );
    // todos
    else dados = retorno;

    const response = dados.map((item) => {
      return {
        ...item,
        status: newDefineIcon(filtro, item),
      };
    });

    setDadosGuias(response);
  };

  // definir Icone
  const defineIcone = (data, aberto) => {
    const dataUTF = data; //format(new Date(data), "yyyy-dd-MM");

    const dataAtual = new Date();
    const dataProximos3Dias = new Date();
    dataProximos3Dias.setDate(dataAtual.getDate() + 3);

    // data de vencimento já passou, ou se já fechou
    if (dataAtual > new Date(dataUTF) || !aberto) {
      return <span>🔴</span>;
    }
    // data de vencimento está próxima
    else if (new Date(dataUTF) < dataProximos3Dias) {
      return <span>🟡</span>;
    } else {
      return <span></span>;
    }
  };

  const fechar = async (id) => {
    const verification = window.confirm("Deseja realmente fechar esta guia?");

    if (verification) {
      let ret = await requisitarAPI(
        () => api.get(`/consultorio/close/${id}`),
        "Falha ao obter dados do servidor"
      );
      if (!ret.erro) {
        window.alert("Guia fechada com sucesso!");
        window.location.reload();
      }
    }
  };

  const ColunaFechar = ({ id }) => {
    return (
      <span style={{ color: "red" }} onClick={() => fechar(id)}>
        fechar
      </span>
    );
  };

  const filtroAbertas = (guias) => {
    return guias.filter((guia) => {
      const aberto =
        guia.quantidade_consultas - guia.quantidade_consultas_realizadas > 0 &&
        guia.fechado !== true;

      guia.Fechado = <ColunaFechar id={guia.id} />;

      guia.status = defineIcone(guia.data_vencimento_guia_utc, aberto);

      if (new Date(guia.data_vencimento_guia_utc) >= new Date() && aberto) {
        return guia;
      }
    });
  };

  useEffect(() => {
    setDadosGuias(filtroAbertas(dadosGuias));
  }, []);

  // useEffect(() => {
  //   if (retorno.length > 0) newFiltros(1);
  // }, [retorno]);

  // const listasGuias = useSelector((state) => state.guias);
  const dispatch = useDispatch();
  const tipo = match.params.tipo;
  const url_edit = `listaTags/editar/${tipo}`; // url para editar

  async function getDados() {
    let ret = await requisitarAPI(
      () => api.get(`consultorio/guias`),
      "Falha ao obter dados do servidor"
    );
    // formartar data_vencimento_guia para o formato brasileiro
    ret.response.data.forEach((item) => {
      item.data_vencimento_guia_utc = item.data_vencimento_guia;
      item.data_vencimento_guia = format(
        new Date(item.data_vencimento_guia),
        "dd/MM/yyyy"
      );
    });
    setDadosGuias(filtroAbertas(ret.response.data));
    setRetorno(ret.response.data);

    if (!ret.erro) {
      dispatch(ListaTagsAction.addTags(ret.response.data));
    }

    setLoading(false);
  }

  useEffect(() => {
    getDados();
  }, []);

  const AlignFilter = ({ value, text }) => {
    return (
      <div className="align-filter">
        <input
          type="radio"
          id={`filtro${value}`}
          name="filtro"
          value={value}
          onChange={(e) => {
            setNFilter(value);
            newFiltros(parseInt(e.target.value));
          }}
          checked={nFilter === value}
        />
        <label htmlFor={`filtro${value}`}>{text}</label>
      </div>
    );
  };

  return (
    <>
      <MainMenu navigation={history} />

      <div className="titulo-pagina">
        Lista de Guias
        <a
          href="/ajuda"
          alt="Clique para acessar página de ajuda"
          style={{ float: "right" }}
          title="Clique para acessar página de ajuda"
        >
          <FontAwesomeIcon className="icone" icon={faQuestionCircle} />
        </a>
        <AlertGeral retorno={retorno} />
        <Spinner loading={loading} />
      </div>
      <div className="container-principal">
        <div className="filtros-container">
          <AlignFilter value={1} text="Todos" />
          <AlignFilter value={2} text="🟢 Concluidas" />
          <AlignFilter value={3} text="🟡 Próximas" />
          <AlignFilter value={4} text="🔴 Vencidas" />
        </div>
        <Row>
          <ListagemGuias
            dados={dadosGuias}
            cols={colunasListaGuia}
            url_edit={url_edit}
            tipo_delete="guias"
            history={history}
          />
        </Row>
      </div>
    </>
  );
};

export default ListaGuias;
