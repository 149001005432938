import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

const ModalGuiasfinalizada = ({ show, setShow, guia = {} }) => {
  const [captionModal, setCaptionModal] = useState("");

  useEffect(() => {
    setCaptionModal("Guia finalizada");
  }, []);

  return (
    <Modal show={show} onHide={() => setShow(false)} animation={true}>
      <Modal.Header closeButton>
        <Modal.Title> {captionModal} </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <h3>Guia {guia.numero_guia}</h3>

        {guia.data_vencimento_guia && (
          <p>
            vencimento{" "}
            {format(new Date(guia.data_vencimento_guia), "dd/MM/yyyy")}
          </p>
        )}
        <p>As guias do paciente {guia.paciente} encerraram</p>
        {guia.quant_extra != null && (
          <span>
            Ainda restam um total de {guia.quant_extra} agendamentos para serem
            adicionados
          </span>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ModalGuiasfinalizada;
