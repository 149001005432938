import {
  faBuilding,
  faChevronLeft,
  faListAlt,
  faMapMarkerAlt,
  faPlus,
  faQuestionCircle,
  faSave,
  faTrash,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import MaskedInput from "react-text-mask";
import * as Yup from "yup";
import { AlertGeral, requisitarAPI } from "../../components/AlertGeral";
import { DatePickerField } from "../../components/Form/DatePicker.js";
import FieldValidate from "../../components/Form/FieldValidate.js";
import { Number } from "../../components/Form/Number.js";
import { SelectComponent } from "../../components/Form/Select.js";
import MainMenu from "../../components/MainMenu";
import Spinner from "../../components/Spiner";
import {
  calcularIdade,
  dateToField,
  dateToView,
  diaIntToString,
  timeToView,
} from "../../Global/funcoes";
import "../../Global/styleGeral.css";
import api from "../../services/api";
import { Creators as FornecedorActions } from "../../Store/Controls/fornecedores/fornecedores.js";

const CadastroFornecedor = ({ history, match }) => {
  const [editForm, setEditForm] = useState(false);
  const [fornecedor, setFornecedor] = useState({
    nome: "",
    data_nascimento: null,
  });
  const [captionForm, setCaptionForm] = useState("Cadastro de Fornecedor");
  const [retorno, setRetorno] = useState({});
  const [loading, setLoading] = useState(true);
  const [idade, setIdade] = useState(0);
  const [formatCPFCNPJ, setFormatCPFCNPJ] = useState("###.###.###-##");

  const fornecedores = useSelector((state) => state.fornecedores);
  const dispatch = useDispatch();

  function verificarCPFRepetidos(cpf) {
    let fornecedor = fornecedores.find((item) => item.cpfcnpj === cpf);
    return typeof fornecedor !== "undefined";
  }

  function getIdade(ano_aniversario, mes_aniversario, dia_aniversario) {
    setIdade(calcularIdade(ano_aniversario, mes_aniversario, dia_aniversario));
  }

  const calcularIdadeFromInput = (data) => {
    if (typeof data !== "undefined" && data !== null) {
      getIdade(data.getFullYear(), data.getMonth() + 1, data.getDate());
    }
  };

  const tratamentoEdicao = async () => {
    setCaptionForm("Edição de Fornecedor");
    setEditForm(true);

    let ret = await requisitarAPI(
      () => api.get(`fornecedores/${match.params.id}`),
      "Falha ao obter dados do servidor"
    );

    if (ret.erro || !ret.response.data) {
      history.push("/fornecedores");
      return;
    }

   
    let fornecedor = ret.response.data;

    fornecedor.data_nascimento = dateToField(fornecedor.data_nascimento);
    if (fornecedor.data_nascimento) {
      getIdade(
        moment(fornecedor.data_nascimento).year(),
        moment(fornecedor.data_nascimento).month(),
        moment(fornecedor.data_nascimento).day()
      );
    }

    if (fornecedor.tipo_pessoa === "F") {
      setFormatCPFCNPJ("###.###.###-##");
    } else {
      setFormatCPFCNPJ("##.###.###/####-##");
    }
    
   
    setFornecedor(fornecedor);
  };

  useEffect(() => {
    if (typeof match.params.id !== "undefined") {
      tratamentoEdicao();
    }

    setLoading(false);
  }, [match.params.id]);

  const schema = Yup.object().shape({
    nome: Yup.string().required("Informe o Nome"),
    data_nascimento: Yup.date()
      .nullable()
      .max(new Date(), "Data de nascimento superior a data atual")
      .typeError("data inválida"),
  });

  const handleSubmit = async (values) => {
    setLoading(true);

    if ((!editForm) && (verificarCPFRepetidos(values.cpfcnpj))) {
      let ret = {erro:true,titleErro:'CPF Repetido',descriptErro:'CPF já cadastrado na base de dados'};
      setRetorno(ret);
      setLoading(false);
      return;
    } else {

      let ret = {};
      if (editForm) {
        ret = await requisitarAPI(
          () => api.put("fornecedores/" + match.params.id, values),
          "Falha ao editar Fornecedor"
        );
        setRetorno(ret);

        if (!ret.erro) {
          dispatch(FornecedorActions.editFornecedor(match.params.id, ret.response.data));
        }
      } else {
        ret = await requisitarAPI(
          () => api.post("fornecedores", values),
          "Falha ao cadastar fornecedores"
        );
        setRetorno(ret);

        if (!ret.erro) {
          dispatch(FornecedorActions.addFornecedor(ret.response));
        }
      }

      setLoading(false);

      let idpost = ret.response.data.id;
      if (!ret.erro) {
        history.push("/fornecedores");
      }
    }
  };

  const changeValueTipoFornecedor = (value) => {
    if (value.value === "F") {
      setFormatCPFCNPJ("###.###.###-##");
    } else {
      setFormatCPFCNPJ("##.###.###/####-##");
    }
  };

  return (
    <>
      <MainMenu navigation={history} />

      <div className="titulo-pagina">
        {" "}
        {captionForm}
        
        <Button
          variant="voltar"
          onClick={() => {
            history.push("/fornecedores");
          }}
        >
          <FontAwesomeIcon className="icone" icon={faChevronLeft} /> Voltar para
          Gerenciar Fornecedores
        </Button>
      </div>

      <div className="container-principal">
        <Row>
          <div style={{ width: "100%" }}>
            <Formik
              enableReinitialize
              initialValues={fornecedor}
              onSubmit={handleSubmit}
              validationSchema={schema}
              validateOnChange={false}
              validateOnBlur={false}
            >
              {({ errors }) => (
                <Form>
                  <Row>
                    <Col md={4}>
                      <h5 style={{ color: "#082044" }}>
                        {" "}
                        <FontAwesomeIcon icon={faUsers} /> Geral
                      </h5>
                    </Col>
                  </Row>

                  <Row className="form-group">

                  <Col className="form-group">
                      <label className="obrigatorio">Tipo de cadastro*</label>
                      <Field
                        name="tipo_pessoa"
                        errors={errors}
                        component={SelectComponent}
                        onChangeValue={changeValueTipoFornecedor}
                        options={[
                          { label: "Pessoa Física", value: "F" },
                          { label: "Pessoa Jurídica", value: "J" },
                        ]}
                      />
                    </Col>

                    <Col className="form-group">
                      <label className="obrigatorio">CPF/CNPJ*</label>
                      <Field
                        name="cpfcnpj"
                        component={Number}
                        errors={errors}
                        format={formatCPFCNPJ}
                        mask="_"
                        placeholder="Digite o CPF/CNPJ"
                        className="form-control"
                        type="phone"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={4} className="form-group">
                      <label className="obrigatorio">Nome completo*</label>
                      <FieldValidate
                        name="nome"
                        placeholder="Digite o nome completo*"
                        type="text"
                        errors={errors}
                      />
                    </Col>
                    <Col sm={2} className="form-group">
                      <label>Data de Nascimento</label>
                      <br />
                      <DatePickerField
                        onChangeDate={(date) => calcularIdadeFromInput(date)}
                        placeholderText="Informe a data de nascimento*"
                        name="data_nascimento"
                        className="form-control"
                        customInput={
                          <MaskedInput
                            mask={[
                              /\d/,
                              /\d/,
                              "/",
                              /\d/,
                              /\d/,
                              "/",
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                            ]}
                            keepCharPositions={true}
                            guide={true}
                          />
                        }
                      />
                      <ErrorMessage
                        name={"data_nascimento"}
                        component="div"
                        style={{ fontSize: "16px", color: "red" }}
                      />
                    </Col>
                    <Col className="form-group">
                      <label style={{ color: "#d2d2d2" }}>Idade</label>
                      <Field
                        name="idade"
                        disabled
                        value={idade}
                        className="form-control"
                        type="text"
                      />
                    </Col>
                    <Col style={{ marginTop: "40px" }} className="form-group">
                      <Field
                        style={{ display: "inline" }}
                        name="exige_nfe"
                        type="checkbox"
                        errors={errors}
                      />
                      <label>Exige Nota Fiscal</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="form-group">
                      <label>Gênero</label>
                      <Field
                        name="sexo"
                        component={SelectComponent}
                        options={[
                          { label: "Masculino", value: "M" },
                          { label: "Feminino", value: "F" },
                          { label: "Não informado", value: "" },
                        ]}
                      />
                    </Col>

                    <Col className="form-group">
                      <label>Como encontrou</label>
                      <Field
                        name="indicacao"
                        component={SelectComponent}
                        options={[
                          { label: "Encaminhamento", value: "E" },
                          { label: "Amigo", value: "A" },
                          { label: "Parente", value: "P" },
                          { label: "Indicação", value: "I" },
                          { label: "Redes sociais", value: "R" },
                          { label: "Pesquisa no Google", value: "G" },
                          { label: "Site", value: "S" },
                          { label: "TV ou Rádio", value: "T" },
                          { label: "Outro", value: "O" },
                        ]}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col className="form-group">
                      <label>E-mail</label>
                      <Field
                        name="email"
                        placeholder="Digite o e-mail"
                        className="form-control"
                        type="text"
                      />
                    </Col>

                    <Col className="form-group">
                      <label>Telefone</label>
                      <Field
                        name="telefone"
                        component={Number}
                        format="(##) ####-####"
                        mask="_"
                        placeholder="Digite o telefone"
                        className="form-control"
                        type="phone"
                      />
                    </Col>

                    <Col className="form-group">
                      <label>Celular</label>
                      <Field
                        name="celular"
                        component={Number}
                        format="(##) # ####-####"
                        mask="_"
                        placeholder="Digite o Celular"
                        className="form-control"
                        type="phone"
                      />
                    </Col>

                  </Row>

                  <Row>
                    <Col>
                      <h5 style={{ color: "#082044", marginTop: "40px" }}>
                        <FontAwesomeIcon icon={faMapMarkerAlt} /> Endereço
                      </h5>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="form-group">
                      <label>Logradouro</label>
                      <Field
                        name="logradouro"
                        placeholder="Digite o logradouro"
                        className="form-control"
                        type="text"
                      />
                    </Col>

                    <Col className="form-group">
                      <label>Número</label>
                      <Field
                        name="numero"
                        placeholder="Digite o número"
                        className="form-control"
                        type="text"
                      />
                    </Col>

                    <Col className="form-group">
                      <label>Complemento</label>
                      <Field
                        name="complemento"
                        placeholder="Digite o complemento"
                        className="form-control"
                        type="text"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col className="form-group">
                      <label>Bairro</label>
                      <Field
                        name="bairro"
                        placeholder="Digite o bairro"
                        className="form-control"
                        type="text"
                      />
                    </Col>

                    <Col className="form-group">
                      <label>Cidade</label>
                      <Field
                        name="cidade"
                        placeholder="Digite a cidade"
                        className="form-control"
                        type="text"
                      />
                    </Col>

                    <Col className="form-group">
                      <label>Estado</label>
                      <Field
                        name="estado"
                        placeholder="Digite o estado"
                        className="form-control"
                        type="text"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col className="form-group">
                      <h5 style={{ color: "#082044", marginTop: "40px" }}>
                        <FontAwesomeIcon icon={faBuilding} /> Profissional
                      </h5>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="form-group">
                      <label>Profissão</label>
                      <Field
                        name="profissao"
                        placeholder="Digite a Profissão"
                        className="form-control"
                        type="text"
                      />
                    </Col>

                    <Col className="form-group">
                      <label>Empresa</label>
                      <Field
                        name="empresa"
                        placeholder="Digite a empresa"
                        className="form-control"
                        type="text"
                      />
                    </Col>
                  </Row>

                  <div style={{ width: "100%" }}>
                    <AlertGeral retorno={retorno} />
                    <Spinner loading={loading} />
                  </div>

                  <Row
                    style={{ marginTop: "40px" }}
                    className="form-group text-center"
                  >
                    <Col className="text-center">
                      <Button variant="salvar" type="submit">
                        <FontAwesomeIcon className="icone" icon={faSave} />{" "}
                        Salvar
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </div>
        </Row>
      </div>
    </>
  );
};

export default CadastroFornecedor;
