import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { AlertGeral, requisitarAPI } from "../../components/AlertGeral";
import { DatePickerField } from "../../components/Form/DatePicker.js";
import FieldValidate from "../../components/Form/FieldValidate.js";
import { SelectComponent } from "../../components/Form/Select.js";
import MainMenu from "../../components/MainMenu";
import Spinner from "../../components/Spiner";
import { getPessoasForSelect } from "../../Global/chamadasAPI";
import { diaIntToString, timeToView } from "../../Global/funcoes";
import "../../Global/styleGeral.css";
import api from "../../services/api";
import { Creators as EventosActions } from "../../Store/Controls/agendas/eventosConsultorio";
import { Creators as PessoaActions } from "../../Store/Controls/pessoas/pessoas.js";
import ModalAddDiasPilates from "./modalAddDiasPilates";

import {
  faChevronLeft,
  faPen,
  faPlus,
  faQuestionCircle,
  faSave,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";
// import OverflowGuias from "./overflowGuias";

library.add(faSave, faChevronLeft, faQuestionCircle);

const CadastroAgendaConsultorio = ({ history, match }) => {
  const [captionForm, setCaptionForm] = useState(
    "Novo evento - Agenda Consultório"
  ); // título do formulário
  const [pessoas, setPessoas] = useState([{}]);
  const [pessoa, setPessoa] = useState({
    tipo_pessoa: "F",
    profissional: false,
    paciente: true,
    nome: "",
    data_nascimento: null,
  });
  const [profissionais, setProfissionais] = useState([{}]);
  const [retorno, setRetorno] = useState({});
  const [loading, setLoading] = useState(true); // ativa o spinner no final do documento
  const [editForm, setEditForm] = useState(false); // verifica se tá no modo edição
  const [evento, setEvento] = useState({
    id_profissional: "",
    id_pessoa: "",
    data_inicio: null,
    data_fim: null,
    duracao_aula: 30,
    tag: "",
    numero_guia: "",
    // quatidade_consulta_guia: "",
  }); // valor inicial do formulário
  const [cadastrandoCliente, setCadastrandoCliente] = useState(false);
  const [isRepetirAgenda, setIsRepetirAgenda] = useState(false); // flag para repetir agenda
  const [listaDiasSemana, setListaDiasSemana] = useState([]);
  const [listaProfissionaisSemana, setListaProfissionaisSemana] = useState([]);
  const [listaDiaSel, setListaDiaSel] = useState({
    id: 0,
    dia: null,
    horario: null,
  });
  const [showModalAddDias, setShowModalAddDias] = useState(false);
  const [editListaDias, setEditListadias] = useState(false);
  const [erroListaDiaVazio, setErroListaDiaVazio] = useState(false);
  let erroAPI = false;
  const [countIDDiaSemana, setCountIDDiaSemana] = useState(0);
  const [tags, setTags] = useState([{}]);
  const [procedimentos, setProcedimentos] = useState([{}]);
  const [editForAll, setEditForAll] = useState(false);
  const [isPilates, setIsPilates] = useState(false);
  // 0 - ainda não rodou, 1 - sim, -1 - não

  let onAlterarNumeroGuia = false;
  let guiaOverFlow = 0;

  const eventos = useSelector((state) => state.eventosConsultorio);
  const dispatch = useDispatch();

  const formatTags = (arr = []) => {
    return arr.map((item) => {
      return {
        label: item.description,
        value: item.id,
      };
    });
  };

  const getTags = () => {
    api
      .get("listaTags/consultorio")
      .then(({ data }) => {
        setTags(formatTags(data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  
  const getProcedimentos = () => {
    api
      .get("listaProcedimentos/consultorio")
      .then(({ data }) => {
        setProcedimentos(formatTags(data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getTags();
    getProcedimentos();
  }, []);

  function getURLRedirect() {
    if (match.params.dataRedirect === "0") {
      return "/consultorio/agenda";
    } else {
      // return "/consultorio/quadro/" + match.params.dataRedirect;
      const dataAtual = moment().format("YYYY-MM-DD");
      return "/consultorio/quadro/" + dataAtual;
    }
  }

  async function getPessoas() {
    setPessoas(await getPessoasForSelect("P"));
    setProfissionais(await getPessoasForSelect("PF"));
  }

  const addDiaSemana = () => {
    setListaDiaSel({ dia: null, horario: null });
    setEditListadias(false);
    setShowModalAddDias(true);
  };

  const editDiaSemana = (item) => {
    setListaDiaSel(item);
    setEditListadias(true);
    setShowModalAddDias(true);
  };

  const deleteDiaSemana = (item) => {
    const novaLista = listaDiasSemana.filter((lista) => item.id !== lista.id);
    setListaDiasSemana(novaLista);
  };

  useEffect(() => {
    // obtem uma lista de pessoas
    getPessoas();
  }, []);

  useEffect(() => {
    // ativa o spinner no final do documento
    setLoading(true);

    // define a data de início e fim do evento
    // se houver uma data de início e a data for superior a 0
    // match é o parâmetro recebido pelas props
    if (
      typeof match.params.data_inicio !== "undefined" &&
      match.params.data_inicio !== "0"
    ) {
      evento.data_inicio = new Date(match.params.data_inicio);
      evento.data_fim = new Date(match.params.data_fim);
      // se houver um proficional
      if (typeof match.params.id_profissional !== "undefined") {
        // pega o número de registro no sistema
        evento.id_profissional = parseInt(match.params.id_profissional);
      }
      // possível erro na recursividade por que o setEvento é
      // chamado sempre que o evento é alterado
      setEvento(evento);
    }

    // edição de formulário
    // se houver um id de pessoa
    if (typeof match.params.id !== "undefined") {
      setCaptionForm("Editar evento - Agenda Consultório");
      setEditForm(true);
      let evnt = eventos.find((item) => item.id == match.params.id);

      // possível redirecionamento para a página do getURLRedirect
      if (typeof evnt === "undefined") {
        history.push(getURLRedirect());
        return;
      }

      setEvento(evnt);
    }
    setLoading(false);
    // evento,
    // getURLRedirect,
    // history,
  }, [
    eventos,
    match.params.data_inicio,
    match.params.data_fim,
    match.params.id,
    match.params.id_profissional,
    evento,
    history,
    getURLRedirect,
  ]);

  const schema = Yup.object().shape({
    id_pessoa: Yup.number().required("Informe o Pessoa"),
    id_profissional: Yup.number().required("Informe o Profissional"),
    data_inicio: Yup.date()
      .typeError("Informe a data de início")
      .when("data_fim", (data_fim, schema) =>
        schema.max(data_fim, "Data de inicio superior a data final")
      ),
  });

  const handleSubmitPessoa = async (values) => {
    let ret = {};
    ret = await requisitarAPI(
      () => api.post("pessoas", values),
      "Falha ao cadastar pessoas"
    );
    setRetorno(ret);

    if (!ret.erro) {
      dispatch(PessoaActions.addPessoa(ret.response));
      getPessoas();
      setCadastrandoCliente(false);
    }
  };

  let index = 1;

  const preSubmit = (values) => {
    let data = { ...values };

    if (data.total_aulas + 1 >= 10 && guiaOverFlow === 0 && data.numero_guia !== "") {
      const onGuiaOverFlow = window.confirm("Deseja limitar a 10 guias?");
      if (onGuiaOverFlow) {
        
        guiaOverFlow = 1;
        data = {
          ...data,
          quantidade_consultas: 1,
          numero_aula: 10,
          quant_extra: data.total_aulas - 10,
          total_aulas: 10,
        };
      } else guiaOverFlow = -1;
    }

    if (isPilates && (!values.total_aulas))  {
     window.alert("Pilates deve ter agenda repetida");
    } else {
      handleSubmit(data);  
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    index = 1;

    if (editForAll) {
      values.allguide = true;
    } else {
      values.allguide = false;
    }

    if (isRepetirAgenda && listaDiasSemana.length === 0) {
      setErroListaDiaVazio(true);
      setLoading(false);
      return;
    }

    let ret = {};
    // se for edição
    if (editForm) {
      ret = await requisitarAPI(
        () => api.put("consultorio/agenda/" + match.params.id, values),
        "Falha ao editar agenda"
      );
      setRetorno(ret);

      if (!ret.erro) {
        dispatch(EventosActions.editEventoConsultorio(match.params.id, values));
      }
    } else {
      // se for cadastro
      values.status = "A";
      values.numero_guia
        ? (values.numero_guia = values.numero_guia)
        : (values.numero_guia = undefined);
      ret = await requisitarAPI(
        () => api.post("consultorio/agenda", values),
        "Falha ao cadastar agenda consultório"
      );
      setRetorno(ret);

      if (!ret.erro) {
        dispatch(EventosActions.addEventoConsultorio(ret));
      }
    }

    setLoading(false);

    let data = values.data_inicio;
    values.data_inicio = undefined;
    let dados = values;
    values.numero_aula = 0;
    values.quantidade_consultas = values.total_aulas;
    values.total_aulas = values.total_aulas - 1;
    if (isRepetirAgenda) {
      let indexDoUm = 0;
      for (let i = values.numero_aula + 1; i <= values.total_aulas; i++) {
        data = await foundDayWeek(data, listaDiasSemana);
        dados.data_inicio = undefined;

        dados.id_profissional =
          findProfissional(data) || values.id_profissional;

        indexDoUm++;
        await postEvent(dados, data, i, indexDoUm);
      }
    }

    if (!ret.erro) {
      history.push(getURLRedirect());
    }
  };

  const findProfissional = (data) => {
    let diaSemana = data.getDay();
    let hora = format(data, "HH:mm");
    let profissional = listaProfissionaisSemana.filter((e) => {
      const eHora = format(e.horario, "HH:mm");
      return e.dia === diaSemana && eHora === hora;
    });
    if (profissional.length > 0) {
      return profissional[0].profissional;
    }
    return null;
  };

  const foundDayWeek = (data, listaDias) => {
    if (listaDias.length === 0) return data;
    let mesmoDia = false;
    const todosIguais = listaDias.every(
      (item) => item.dia === listaDias[0].dia
    );
    if (listaDias.length === 1) mesmoDia = false;
    else if (todosIguais) {
      if (index === 0) mesmoDia = false;
      else mesmoDia = true;
    } else if (index > 1 && index <= listaDias.length) {
      if (listaDias[index - 1].dia === listaDias[index - 2].dia)
        mesmoDia = true;
      else mesmoDia = false;
    } else mesmoDia = false;
    if (!mesmoDia) {
      if (data.hasOwnProperty("_i")) data = data._i;
      data.setDate(data.getDate() + 1);
    }

    const intDiaSemana = moment(data).format("d");

    const response = listaDias.filter((item, i) => {
      if (parseInt(intDiaSemana) == item.dia) {
        return item;
      }
    });
    // formata a hora
    const final = response.map((item) => {
      const horario = timeToView(item.horario).split(":");
      const [hora, minutos] = horario;
      const dataNova = moment(data).set({ hour: hora, minute: minutos });

      return dataNova.toDate();
    });

    if (final !== undefined && final.length > 0) {
      if (index === listaDias.length) index = 0;
      else index += 1;
      return final[0];
    } else {
      if (index === listaDias.length) index = 0;
      else index += 1;
    }

    return foundDayWeek(data, listaDias);
  };

  const postEvent = async (dados, data, numero_aula, index) => {
    let ret = {};

    dados.status = "A";
    dados.pessoa = null;
    dados.grupo = null;
    dados.id = null;
    dados.createdAt = null;
    dados.updateAt = null;
    dados.numero_aula = numero_aula;
    dados.quantidade_consultas =
    guiaOverFlow === 1 ? undefined : dados.total_aulas;

    

    ret = await requisitarAPI(
      () =>
        api.post("consultorio/agenda", {
          ...dados,
          data_inicio: data,
          data_fim: moment(data).add(dados.duracao_aula, "m").toDate(),
        }),
      "Falha ao cadastar agenda Pilates"
    );
    setRetorno(ret);
    if (!ret.erro) {
      dispatch(EventosActions.addEventoConsultorio(ret));
    } else {
      erroAPI = true;
      throw ret;
    }
  };

  return (
    <>
      <MainMenu navigation={history} />

      {/* <OverflowGuias
        show={popUpOverflowGuias}
        setShow={setPopUpOverflowGuias}
        onAlterarNumeroGuia={onAlterarNumeroGuia}
        setOnAlterarNumeroGuia={setOnAlterarNumeroGuia}
      /> */}

      <ModalAddDiasPilates
        listaDiaSel={listaDiaSel}
        editListaDias={editListaDias}
        listaDiasSemana={listaDiasSemana}
        setListaDiasSemana={setListaDiasSemana}
        listaProfissionaisSemana={listaProfissionaisSemana}
        setListaProfissionaisSemana={setListaProfissionaisSemana}
        countIDDiaSemana={countIDDiaSemana}
        setCountIDDiaSemana={setCountIDDiaSemana}
        show={showModalAddDias}
        setShow={setShowModalAddDias}
        exibeHoraFim={false}
        profissionais={profissionais}
      />

      <div className="titulo-pagina">
        {captionForm}
        <Button
          variant="voltar"
          onClick={() => {
            history.push(getURLRedirect());
          }}
        >
          <FontAwesomeIcon className="icone" icon="chevron-left" /> Voltar para
          Agenda Consultório
        </Button>
        <a
          href="/ajuda#consultorio"
          alt="Clique para acessar página de ajuda"
          style={{ float: "right" }}
          title="Clique para acessar página de ajuda"
        >
          <FontAwesomeIcon className="icone" icon={faQuestionCircle} />
        </a>
      </div>

      <div className="container-principal">
        {editForm ? (
          <Row>
            <Col
              md={12}
              style={{
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <input
                type="checkbox"
                value={editForAll}
                onChange={(e) => setEditForAll(e.target.checked)}
                id="editForAll"
                style={{
                  margin: 0,
                }}
              />
              <label
                htmlFor="editForAll"
                style={{
                  margin: 0,
                }}
              >
                alterar para todos?
              </label>
            </Col>
          </Row>
        ) : (
          <></>
        )}

        <Formik
          enableReinitialize
          initialValues={evento}
          onSubmit={preSubmit}
          validationSchema={schema}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ errors }) => (
            <Form>
              <div>
                <Row>
                  <Col sm={12} md={3} className="form-group">
                    <label>Data início*</label>
                    <br />
                    <DatePickerField
                      withPortal
                      dateFormat="dd/MM/yyyy HH:mm"
                      showTimeSelect
                      name="data_inicio"
                      placeholder="Digite a data de evolução*"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="data_inicio"
                      component="div"
                      style={{ fontSize: "16px", color: "red" }}
                    />
                  </Col>

                  <Col sm={12} md={3} className="form-group">
                    <label>Data Fim*</label>
                    <br />
                    <DatePickerField
                      withPortal
                      dateFormat="dd/MM/yyyy HH:mm"
                      showTimeSelect
                      name="data_fim"
                      placeholder="Digite a data de evolução*"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="data_fim"
                      component="div"
                      style={{ fontSize: "16px", color: "red" }}
                    />
                  </Col>
                  {isRepetirAgenda && (
                    <>
                      <Col sm={12} md={2} className="form-group">
                        <label>Número da guia</label>
                        <br />
                        <Field
                          type="text"
                          name="numero_guia"
                          placeholder="Digite o número da guia"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="numero_guia"
                          component="div"
                          style={{ fontSize: "16px", color: "red" }}
                        />
                      </Col>
                      <Col sm={12} md={2} className="form-group">
                        <label>Data de vencimento da guia</label>
                        <br />

                        <DatePickerField
                          withPortal
                          dateFormat="dd/MM/yyyy"
                          showTimeSelect
                          name="data_vencimento_guia"
                          placeholder="Digite a data de vencimento da guia"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="data_vencimento_guia"
                          component="div"
                          style={{ fontSize: "16px", color: "red" }}
                        />
                      </Col>
                      {/* <Col sm={12} md={2} className="form-group">
                        <label>Quantidade de consultas</label>
                        <br />
                        <Field
                          type="text"
                          name="quantidade_consulta_guia"
                          placeholder="Digite a quantidade de consultas"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="quantidade_consulta_guia"
                          component="div"
                          style={{ fontSize: "16px", color: "red" }}
                        />
                      </Col> */}
                    </>
                  )}
                </Row>

                <Row>
                  <Col className="form-group">
                    <label className="obrigatorio">Profissional*</label>
                    <FieldValidate
                      name="id_profissional"
                      placeholderText="Nome do Profissional"
                      component={SelectComponent}
                      options={profissionais}
                      errors={errors}
                      noClass
                    />
                  </Col>

                  <Col className="form-group">
                    <label className="obrigatorio"> Pessoa *</label>{" "}
                    {/* <Button
                      variant="info"
                      size="sm"
                      onClick={() => setCadastrandoCliente(!cadastrandoCliente)}
                      style={{
                        marginLeft: "15px",
                        marginTop: "-5px",
                        width: "35px",
                      }}
                    >
                      {" "}
                      <FontAwesomeIcon className="icone" icon={faPlus} />
                    </Button> */}
                    {cadastrandoCliente ? (
                      <Row>
                        <Formik
                          enableReinitialize
                          initialValues={pessoa}
                          onSubmit={handleSubmitPessoa}
                          validateOnChange={false}
                          validateOnBlur={false}
                        >
                          {({ errors }) => (
                            <Form>
                              <label className="obrigatorio">
                                Cadastro rápido de Cliente - Nome completo*
                              </label>
                              <FieldValidate
                                name="nome"
                                placeholder="Digite o nome completo*"
                                type="text"
                                errors={errors}
                              />
                              <Row style={{ marginTop: "3px" }}>
                                <Col className="text-center">
                                  <Button variant="salvar" type="submit">
                                    <FontAwesomeIcon
                                      className="icone"
                                      icon="save"
                                    />{" "}
                                    Cadastrar
                                  </Button>
                                </Col>
                              </Row>
                            </Form>
                          )}
                        </Formik>
                      </Row>
                    ) : (
                      <FieldValidate
                        name="id_pessoa"
                        placeholderText="Nome do Pessoa"
                        component={SelectComponent}
                        options={pessoas}
                        errors={errors}
                        noClass
                      />
                    )}
                  </Col>

                  <Col className="form-group">
                    <label className="obrigatorio"> Convênio *</label>{" "}
                    <FieldValidate
                      name="tag"
                      placeholderText="Tag da consulta"
                      component={SelectComponent}
                      options={tags}
                      errors={errors}
                      noClass
                    />
                  </Col>

                  <Col className="form-group">
                    <label className="obrigatorio"> Procedimento *</label>{" "}
                    <FieldValidate
                      name="id_procedimento"
                      placeholderText="Procedimento da consulta"
                      component={SelectComponent}
                      options={procedimentos}
                      onChangeValue={val => setIsPilates(val.label.toLowerCase() === 'pilates')}
                      errors={errors}
                      noClass
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Field
                      name="repetir_agenda"
                      type="checkbox"
                      onClick={(event) =>
                        setIsRepetirAgenda(event.target.checked)
                      }
                      defaultChecked={isRepetirAgenda}
                      errors={errors}
                    />
                    <label>Repetir Agenda</label>
                  </Col>
                </Row>

                {isRepetirAgenda && (
                  <>
                    <Row className="form-group">
                      <Col className="obrigatorio" sm={12} md={3}>
                        <label>Quantidade de vezes que será repetido</label>
                        <br />
                        <FieldValidate
                          name="total_aulas"
                          placeholder="Quantidade"
                          type="number"
                          errors={errors}
                        />
                      </Col>

                      <Col className="obrigatorio" md={3}>
                        <label>Duração das consultas* (Minutos)</label>
                        <br />
                        <FieldValidate
                          name="duracao_aula"
                          placeholder="Duração das aulas em minutos"
                          defaultValue={30}
                          type="number"
                          errors={errors}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Row>
                          <Col>
                            <h5>Dias da Semana</h5>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={6}>
                            <Card>
                              <Card.Body>
                                <Card.Title>
                                  <Button
                                    style={{ marginLeft: "10px" }}
                                    onClick={() => addDiaSemana()}
                                  >
                                    <FontAwesomeIcon
                                      className="icone"
                                      icon={faPlus}
                                    />{" "}
                                    Adicionar
                                  </Button>
                                  {erroListaDiaVazio && (
                                    <Row style={{ marginTop: "10px" }}>
                                      <Col md="4">
                                        <Alert variant="danger">
                                          Informe um valor
                                        </Alert>
                                      </Col>
                                    </Row>
                                  )}
                                </Card.Title>
                                <Card.Text>
                                  {listaDiasSemana.map((item) => (
                                    <div
                                      key={item.id}
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <Alert
                                        variant="secondary"
                                        style={{
                                          textAlign: "center",
                                          height: "50px",
                                        }}
                                      >
                                        <label
                                          style={{
                                            marginTop: "7px",
                                            color: "green",
                                            fontSize: "16px",
                                          }}
                                        >
                                          <b>
                                            {diaIntToString(item.dia)} -{" "}
                                            {timeToView(item.horario)}
                                            {item.profissional &&
                                              profissionais
                                                .find(
                                                  (e) =>
                                                    e.value ===
                                                    item.profissional
                                                )
                                                .label.substring(0, 15)
                                                .concat("...")}
                                          </b>
                                        </label>
                                      </Alert>

                                      <Button
                                        style={{
                                          marginLeft: "10px",
                                          marginTop: "15px",
                                          width: "50px",
                                          height: "50px",
                                        }}
                                        onClick={() => editDiaSemana(item)}
                                      >
                                        <FontAwesomeIcon
                                          className="icone"
                                          icon={faPen}
                                        />
                                      </Button>

                                      <Button
                                        variant="danger"
                                        style={{
                                          marginLeft: "10px",
                                          marginTop: "15px",
                                          width: "50px",
                                          height: "50px",
                                        }}
                                        onClick={() => deleteDiaSemana(item)}
                                      >
                                        <FontAwesomeIcon
                                          className="icone"
                                          icon={faTrash}
                                        />
                                      </Button>
                                    </div>
                                  ))}
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </>
                )}

                <Row>
                  <Col className="form-group">
                    <label> Observações </label>
                    <Field
                      name="observacao"
                      placeholder="(Opcional)"
                      className="form-control"
                      as="textarea"
                    />
                  </Col>
                </Row>

                <Row
                  style={{ marginTop: "40px" }}
                  className="form-group text-center"
                >
                  <Col className="text-center">
                    <Button variant="salvar" type="submit">
                      <FontAwesomeIcon className="icone" icon="save" /> Salvar
                    </Button>
                  </Col>
                </Row>
              </div>

              <div style={{ marginTop: "100px" }}>
                <AlertGeral retorno={retorno} />
                <Spinner loading={loading} />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default CadastroAgendaConsultorio;
