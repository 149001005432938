import React from "react";

// export function statusContasReceber(rowData, column) {
//   const data_vencimento = new Date(rowData["data_vencimento"]);
//   const data_atual = new Date();
//   const status = rowData["status"];

//   if (status === "P")
//     return <FontAwesomeIcon style={{ color: "green" }} icon={faDollarSign} />;

//   if (data_vencimento <= data_atual)
//     return (
//       <FontAwesomeIcon style={{ color: "red" }} icon={faExclamationCircle} />
//     );
// }

// export function tipoMovimentacao(rowData, column) {
//   let tipo = +rowData["tipo"];

//   if (tipo === 1) {
//     return (
//       <div style={{ display: "flex", alignItems: "center" }}>
//         <FontAwesomeIcon
//           style={{ color: "green", fontSize: 15, marginRight: 8 }}
//           icon={faArrowUp}
//         />
//         <span style={{ color: "green" }}>Entrada</span>
//       </div>
//     );
//   } else {
//     return (
//       <div style={{ display: "flex", alignItems: "center" }}>
//         <FontAwesomeIcon
//           style={{ color: "red", fontSize: 15, marginRight: 8 }}
//           icon={faArrowDown}
//         />
//         <span style={{ color: "red" }}>Saída</span>
//       </div>
//     );
//   }
// }

// export function formatarData(rowData, column) {
//   let data = rowData["createdAt"];

//   return <span>{moment(data).format("DD/MM/YYYY - HH:mm:ss")}</span>;
// }

// export function parcelasContasReceber(rowData, column) {
//   if (rowData.length === 0) {
//     return <span>1/1</span>;
//   }

//   const parcela = rowData["parcelas_sequencia"];
//   const parcela_total = rowData["parcelas_total"];

//   if (parcela_total > 0) return <span>{parcela + "/" + parcela_total}</span>;
//   else return <span>1/1</span>;
// }

// //Pessoas
// export function TipoCadastroPessoa(rowData, column) {
//   let tipoStr = "";
//   if (rowData["paciente"]) {
//     tipoStr = "Paciente";
//   }

//   if (rowData["profissional"]) {
//     if (tipoStr !== "") tipoStr = tipoStr + ", Profissional";
//     else tipoStr = "Profissional";
//   }
//   return <span>{tipoStr}</span>;
// }

// export function situacaoListaEspera(rowData, column) {
//   const situacao = rowData["situacao"];

//   if (situacao === "A")
//     return <FontAwesomeIcon style={{ color: "green" }} icon={faThumbsUp} />;

//   if (situacao === "C")
//     return <FontAwesomeIcon style={{ color: "red" }} icon={faThumbsDown} />;
// }

export function situacaoListaTags(rowData, column) {
  return <span>{rowData["icone"]}</span>;
}
