import { ErrorMessage, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { DatePickerField } from "../../components/Form/DatePicker.js";

import { faFileUpload, faPen, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { AlertGeral, requisitarAPI } from "../../components/AlertGeral";
import FieldValidate from "../../components/Form/FieldValidate.js";
import Spinner from "../../components/Spiner";
import { CardEvolucao } from "../../Forms/Diagnosticos/evolucaoCard";
import api from "../../services/api";
import { Creators as DiagnosticoActions } from "../../Store/Controls/diagnosticos";
import ModalArquivosDiagnosticos from "./modalArquivosDiagnosticos.js";
import ModalFiles from "./modalFiles.js";

var origemArquivos = false;

const FormEvolucao = ({ diagnostico }) => {
  const myRef = useRef();

  const [retorno, setRetorno] = useState({});
  const [loading, setLoading] = useState(false);
  const [evolucao, setEvolucao] = useState({
    data_evolucao: new Date(),
    descricao: "",
    editando: false,
    visualizando: false,
    files: [],
  });
  const [modalArquivosDiagnosticos, setModalArquivosDiagnosticos] =
    useState(false);
  const [evolucaoFiles, setEvolucaoFiles] = useState([]);
  const [modalFiles, setModalFiles] = useState(false);

  const dispatch = useDispatch();

  const schema = Yup.object().shape({
    data_evolucao: Yup.date().typeError("Informe uma Data válida"),
    descricao: Yup.string().required("Informe a Descrição"),
  });

  const handleSubmit = async (values) => {
    values.id_diagnostico = diagnostico.id;
    setLoading(true);

    let ret = {};

    if (evolucao.editando) {
      ret = await requisitarAPI(
        () => api.put("evolucoes/" + evolucao.id, values),
        "Falha ao editar evolução"
      );
      setRetorno(ret);

      if (!ret.erro) {
        dispatch(
          DiagnosticoActions.editEvolucao(diagnostico, ret.response.data)
        );
        setEvolucao({
          data_evolucao: new Date(),
          descricao: "",
          editando: false,
          visualizando: false,
          files: [],
        });
      }
    } else {
      ret = await requisitarAPI(
        () => api.post("evolucoes", values),
        "Falha ao cadastar evolução"
      );
      setRetorno(ret);

      if (!ret.erro) {
        dispatch(
          DiagnosticoActions.addEvolucao(diagnostico, ret.response.data)
        );
        myRef.current.uploadFile(ret.response.data.id);
      }
    }

    getDadosFiles();

    setLoading(false);
  };

  const setOrigemArquivos = (origem = false) => {
    origemArquivos = origem;
  };

  const getOrigemArquivos = () => {
    return origemArquivos;
  };

  const setModalArquivosDiagnosticosProp = () => {
    setModalArquivosDiagnosticos(false);
  };

  const getDadosFiles = async () => {
    let data = [];
    let files = {};

    let evolucoesDiagnosticos = diagnostico.evolucoes;
    evolucoesDiagnosticos.sort((a, b) => (a.id < b.id ? -1 : 1));

    for (let evolucaoDiagnostico of evolucoesDiagnosticos) {
      const ret = await requisitarAPI(
        () => api.get("evolucoes/" + evolucaoDiagnostico.id + "/files"),
        "Falha ao listar."
      );
      files = ret.response.data;

      evolucaoDiagnostico = { evolucaoDiagnostico, ...{ files } };

      data.push({
        evolucaoDiagnostico,
      });
    }

    setEvolucaoFiles(data);
  };

  useEffect(() => {
    if (evolucaoFiles.length === 0 && diagnostico.evolucoes.length > 0) {
      getDadosFiles();
    }
  }, [getDadosFiles]);

  return (
    <Formik
      enableReinitialize
      initialValues={evolucao}
      onSubmit={handleSubmit}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ errors }) => (
        <Form>
          <div>
            <Row id="evolucao" style={{ paddingTop: "30px" }}>
              <Col lg={true}>
                <h2>Registro de Evoluções</h2>
              </Col>
            </Row>
            <Row style={{ paddingTop: "15px" }}>
              <Col lg={true}>
                <label className="obrigatorio">Evolução*</label>
                <FieldValidate
                  name="descricao"
                  placeholder="Descreva a Evolução"
                  className="form-control"
                  component="textarea"
                  type="text"
                  errors={errors}
                />
              </Col>
              <Col lg={true}>
                <label className="obrigatorio">Data da Evolução*</label>
                <br />
                <DatePickerField
                  withPortal
                  dateFormat="dd/MM/yyyy HH:mm"
                  showTimeSelect
                  timeFormat="HH:mm"
                  name="data_evolucao"
                  placeholder="Digite a data de evolução*"
                  className="form-control"
                />
                <ErrorMessage
                  name={"data_evolucao"}
                  component="div"
                  style={{ fontSize: "16px", color: "red" }}
                />
              </Col>
            </Row>
            <Row style={{ paddingTop: "30px" }}>
              <Col lg={true}>
                <Button
                  variant="primary"
                  type="button"
                  onClick={() => {
                    setModalArquivosDiagnosticos(true);
                    setOrigemArquivos(true);
                  }}
                >
                  <FontAwesomeIcon icon={faFileUpload} /> Arquivos
                </Button>
              </Col>
              <ModalArquivosDiagnosticos
                ref={myRef}
                id_evolucao={evolucao.id ? evolucao.id : null}
                show={modalArquivosDiagnosticos}
                files={evolucao.files ? evolucao.files : null}
                origem={getOrigemArquivos()}
                setShow={setModalArquivosDiagnosticosProp}
              />
              <ModalFiles
                id_evolucao={evolucao.id ? evolucao.id : null}
                show={modalFiles}
                files={evolucao.files ? evolucao.files : null}
                setShow={setModalFiles}
              />
            </Row>
            <Row style={{ paddingTop: "30px" }}>
              <Col lg={true}>
                {evolucao.editando ? (
                  <Button variant="editar" type="submit">
                    <FontAwesomeIcon icon={faPen} /> Editar Evolução
                  </Button>
                ) : (
                  <Button variant="salvar" type="submit">
                    <FontAwesomeIcon icon={faSave} /> Cadastrar Evolução
                  </Button>
                )}
              </Col>
            </Row>
            <Row style={{ paddingTop: "30px" }}>
              <AlertGeral retorno={retorno} />
              <Spinner loading={loading} />
            </Row>
          </div>
          <div
            style={{
              paddingTop: "15px",
              backgroundColor: "#fff2f2",
              paddingLeft: "15px",
              borderRadius: "7px",
            }}
          >
            <Row style={{ paddingTop: "15px", paddingLeft: "15px" }}>
              <Col lg={true}>
                <h2>Evoluções registradas</h2>
              </Col>
            </Row>
            <Row style={{ paddingTop: "15px", paddingLeft: "15px" }}>
              <Col lg={true}>
                {evolucaoFiles.map((obj) => (
                  <CardEvolucao
                    key={obj.evolucaoDiagnostico.evolucaoDiagnostico.id}
                    evolucao={obj.evolucaoDiagnostico.evolucaoDiagnostico}
                    files={obj.evolucaoDiagnostico.files}
                    setEvolucao={setEvolucao}
                    setModalFiles={setModalFiles}
                  />
                ))}
              </Col>
            </Row>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FormEvolucao;
