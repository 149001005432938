import React, { useState, useEffect } from "react";
import { Breadcrumb, Button, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Creators as FormasPagamentoActions } from "../../Store/Controls/financeiros/formasPagamento";
import MainMenu from "../../components/MainMenu";
import "../../Global/styleGeral.css";
import Listagem from "../../components/Listagens";
import { colunasFormasPagamento } from "../../components/Listagens/colunas";
import api from "../../services/api";
import { AlertGeral, requisitarAPI } from "../../components/AlertGeral";
import Spinner from "../../components/Spiner";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faChevronLeft,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";

const FormasPagamento = ({ history }) => {
  const [retorno, setRetorno] = useState({});
  const [loading, setLoading] = useState(true);

  const formasPagamento = useSelector((state) => state.formasPagamento);
  const dispatch = useDispatch();

  async function getDados() {
    let ret = await requisitarAPI(
      () => api.get("formasPagamento"),
      "Falha ao obter dados do servidor"
    );
    setRetorno(ret);

    if (!ret.erro) {
      dispatch(FormasPagamentoActions.addFormasPagamento(ret.response.data));
    }

    setLoading(false);
  }

  useEffect(() => {
    getDados();
  }, []);

  return (
    <>
      <div className="titulo-pagina">
        Gerenciar Formas de Pagamento
        <Button
          variant="principal"
          onClick={() => {
            history.push("/formasPagamento/cadastro");
          }}
          alt="Adicionar nova forma de pagamento"
          title="Adicionar nova forma de pagamento"
        >
          {" "}
          <FontAwesomeIcon className="icone" icon={faPlus} />
          Cadastrar Forma de Pagamento
        </Button>
        {/* <Button
          variant="voltar"
          onClick={() => {
            history.push("/financeiro/contasReceber");
          }}
          alt="Voltar para financeiro"
          title="Voltar para financeiro"
        >
          {" "}
          <FontAwesomeIcon className="icone" icon={faChevronLeft} />
          Voltar para Financeiro
        </Button> */}
        <a
          href="/ajuda#financeiro"
          alt="Clique para acessar página de ajuda"
          style={{ float: "right" }}
          title="Clique para acessar página de ajuda"
        >
          <FontAwesomeIcon className="icone" icon={faQuestionCircle} />
        </a>
        <AlertGeral retorno={retorno} />
        <Spinner loading={loading} />
      </div>

      <div className="container-principal">
        <Row>
          <Listagem
            dados={formasPagamento}
            cols={colunasFormasPagamento}
            url_edit="formasPagamento/editar"
            tipo_delete="formasPagamento"
            history={history}
          />
        </Row>
      </div>
    </>
  );
};

export default FormasPagamento;
