/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Button, Row, Col } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { Creators as CaixaActions } from "../../Store/Controls/caixas";
import "../../Global/styleGeral.css";
import { AlertGeral, requisitarAPI } from "../../components/AlertGeral";
import api from "../../services/api";
import MainMenu from "../../components/MainMenu";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faChevronLeft,
  faUsers,
  faMapMarkerAlt,
  faBuilding,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import FieldValidate from "../../components/Form/FieldValidate.js";
import { MoedaFormat } from "../../components/Form/MoedaFormat.js";

import Spinner from "../../components/Spiner";
import { SelectComponent } from "../../components/Form/Select";
import {
  getCaixasForSelect,
  getPessoasForSelect,
} from "../../Global/chamadasAPI";

library.add(faSave, faChevronLeft, faUsers, faMapMarkerAlt, faBuilding);

const CadastroMovimento = ({ history, match }) => {
  const [editForm, setEditForm] = useState(false);
  const [captionForm, setCaptionForm] = useState("Cadastro de movimentações");
  const [retorno, setRetorno] = useState({});
  const [loading, setLoading] = useState(true);
  const [movimento, setMovimento] = useState({});
  const [caixas, setCaixas] = useState([{}]);
  const [pessoas, setPessoas] = useState([{}]);

  const dispatch = useDispatch();

  useEffect(() => {
    async function getCaixasFromSelect() {
      setCaixas(await getCaixasForSelect());
    }
    getCaixasFromSelect();

    async function getPessoasFromSelects() {
      setPessoas(await getPessoasForSelect());
    }
    getPessoasFromSelects();

    // if (typeof match.params.id !== "undefined") {
    //   setCaptionForm("Edição de movimentações");
    //   setEditForm(true);
    //   let caixaV = caixas.find((item) => item.id == match.params.id);
    //   if (typeof caixaV === "undefined") {
    //     history.push("/financeiro?tab=movimentacoes");
    //     return;
    //   }

    //   setCaixa(caixaV);
    // }
    setLoading(false);
  }, [history, match.params.id, match.params.id_pessoa]);

  const schema = Yup.object().shape({
    nome: Yup.string().required("Informe o nome"),
    tipo: Yup.number().required("Informe o tipo"),
    valor: Yup.number().required("Informe o valor"),
    id_pessoa: Yup.number().required("Informe a Pessoa"),
    id_caixa: Yup.number().required("Informe o Caixa"),
  });

  const handleSubmit = async (values) => {
    setLoading(true);
    let ret = {};

    if (!editForm) {
      ret = await requisitarAPI(
        () => api.post("movimentacoes", values),
        "Falha criar uma movimentação"
      );
    }

    setLoading(false);

    // if (!editForm) {

    //   history.push("/financeiro?tab=movimentacoes");
    // } else {
    //   ret = await requisitarAPI(
    //     () => api.put("caixas/" + match.params.id, values),
    //     "Falha ao editar o caixa"
    //   );
    //   setRetorno(ret);
    // }

    // setLoading(false);

    if (!ret.erro) {
      history.push("/financeiro/movimentacoes");
    }
  };

  return (
    <>
      <MainMenu navigation={history} />

      <div className="titulo-pagina">
        {captionForm}
        <Button
          variant="voltar"
          onClick={() => {
            history.push("/financeiro/movimentacoes");
          }}
        >
          <FontAwesomeIcon className="icone" icon="chevron-left" /> Voltar para
          movimentações
        </Button>
        <a
          href="/ajuda#financeiro"
          alt="Clique para acessar página de ajuda"
          style={{ float: "right" }}
          title="Clique para acessar página de ajuda"
        >
          <FontAwesomeIcon className="icone" icon={faQuestionCircle} />
        </a>
      </div>

      <div className="container-principal">
        <Row>
          <div className="container-principal" style={{ width: "100%" }}>
            <Formik
              enableReinitialize
              initialValues={movimento}
              onSubmit={handleSubmit}
              validationSchema={schema}
              validateOnChange={false}
              validateOnBlur={false}
            >
              {({ errors }) => (
                <Form>
                  <Row>
                    <Col>
                      <label className="obrigatorio">Nome*</label>
                      <FieldValidate
                        name="nome"
                        placeholder="Digite o nome da movimentação*"
                        type="text"
                        errors={errors}
                      />
                    </Col>
                  </Row>

                  <Row style={{ marginTop: "18px" }}>
                    <Col>
                      <label className="obrigatorio">Valor*</label>
                      <FieldValidate
                        component={MoedaFormat}
                        name="valor"
                        placeholder="Digite o valor a ser movimentado"
                        errors={errors}
                        disabled={editForm}
                      />
                    </Col>
                    <Col>
                      <label className="obrigatorio">
                        Tipo da movimentação*
                      </label>
                      <Field
                        name="tipo"
                        component={SelectComponent}
                        // onChangeValue={changeValueTipoPessoa}
                        errors={errors}
                        options={[
                          { label: "Entrada", value: "1" },
                          { label: "Saída", value: "0" },
                        ]}
                      />
                    </Col>
                  </Row>

                  <Row style={{ marginTop: "18px" }}>
                    <Col>
                      <label className="obrigatorio">Pessoa*</label>
                      <FieldValidate
                        name="id_pessoa"
                        placeholderText="Selecione a Pessoa"
                        component={SelectComponent}
                        options={pessoas}
                        errors={errors}
                        noClass
                      />
                    </Col>
                    <Col>
                      <label className="obrigatorio">Caixa a movimentar*</label>
                      <FieldValidate
                        name="id_caixa"
                        placeholderText="Selecione o caixa"
                        component={SelectComponent}
                        options={caixas}
                        errors={errors}
                        noClass
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <AlertGeral retorno={retorno} />
                      <Spinner loading={loading} />
                    </Col>
                  </Row>
                  <Row
                    style={{ marginTop: "40px" }}
                    className="form-group text-center"
                  >
                    <Col className="text-center">
                      <Button variant="salvar" type="submit">
                        <FontAwesomeIcon className="icone" icon="save" /> Salvar
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </div>
        </Row>
      </div>
    </>
  );
};

export default CadastroMovimento;
