import { createActions, createReducer } from "reduxsauce";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  addProcedimentos: ["procedimentos"],
  addProcedimento: ["procedimento"],
  editProcedimento: ["id", "procedimento"],
  removeProcedimento: ["id"],
});

/**
 * Implementação das manipulações do State
 */
const INITIAL_STATE = [];

const addProcedimentos = (state = INITIAL_STATE, action) => action.procedimentos;

const add = (state = INITIAL_STATE, action) => [...state, action.procedimento];

const edit = (state = INITIAL_STATE, action) =>
  state.map((item) => {
    if (item.id === action.id) {
      return action.procedimento;
    } else {
      return item;
    }
  });

const remove = (state = INITIAL_STATE, action) => {
  return state.filter((todo) => todo.id !== action.id);
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.ADD_PROCEDIMENTOS]: addProcedimentos,
  [Types.ADD_PROCEDIMENTO]: add,
  [Types.EDIT_PROCEDIMENTO]: edit,
  [Types.REMOVE_PROCEDIMENTO]: remove,
});
