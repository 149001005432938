import { createActions, createReducer } from "reduxsauce";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  addFornecedores: ["fornecedores"],
  addFornecedor: ["fornecedor"],
  editFornecedor: ["id","fornecedor"],
  removeFornecedor: ["id"]
});

/**
 * Implementação das manipulações do State
 */
const INITIAL_STATE = [];

const addFornecedores = (state = INITIAL_STATE, action) => action.fornecedores;


const add = (state = INITIAL_STATE, action) => [
  ...state, action.fornecedor
]  
  
const edit = (state = INITIAL_STATE, action) => (
  state.map(item => {
    if (item.id == action.id) {
      return action.fornecedor;
    } else {
     return item;
    }
  })
);

const remove = (state = INITIAL_STATE, action) =>
  state.filter(todo => todo.id !== action.id);

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.ADD_FORNECEDORES]: addFornecedores,
  [Types.ADD_FORNECEDOR]: add,
  [Types.EDIT_FORNECEDOR]: edit,
  [Types.REMOVE_FORNECEDOR]: remove
});
