/* eslint-disable react-hooks/exhaustive-deps */
import "moment/locale/pt";
import "react-big-calendar/lib/css/react-big-calendar.css";

import moment from "moment";
import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import translate from "../../assets/translate";
import { AlertGeral, requisitarAPI } from "../../components/AlertGeral";
import Spinner from "../../components/Spiner";
import { getConfig, getPessoasForSelect } from "../../Global/chamadasAPI";
import { dateToField } from "../../Global/funcoes";
import "../../Global/styleGeral.css";
import ModalGuiasfinalizada from "../../pages/Agendas/modalGuiasFinalizadas";
import ModalViewAgenda from "../../pages/Agendas/modalViewAgenda.js";
import api from "../../services/api";
import { Creators as EventosConsultorioActions } from "../../Store/Controls/agendas/eventosConsultorio";

const localizer = momentLocalizer(moment);
const dataAtual = new Date();
let url = "";

const Calendario = ({ tipo, history }) => {
  const [retornoDiaDiagnostico, setRetornoDiaDiagnostico] = useState([]);
  const isAgendaConsultorio = tipo === "consultorio";
  const [showModalView, setShowModalView] = useState(false);
  const [showModalAulasConcluidas, setShowModalAulasConcluidas] =
    useState(false);
  const [dadosClickEvent, setDadosClickEvent] = useState("");
  const [onGuiasFinalizadas, setOnGuiasFinalizadas] = useState(false);
  const [loading, setLoading] = useState(true);
  const [retorno, setRetorno] = useState({});
  const [idGuia, setIdGuia] = useState({});
  const [config, setConfig] = useState({
    tempoagenda: "45",
    modelo_financeiro: "A",
    editando: false,
  });
  const dispatch = useDispatch();
  const [ProfissionaisSelect, setProfissionaisSelect] = useState([]);
  const [profissionalFiltrado, setProfissionalFiltrado] = useState(null);

  const usuarioLogado = useSelector((state) => state.usuarioLogado);
  const eventosConsultorio = useSelector((state) => state.eventosConsultorio);

  async function getProfissionais() {
    let profs = await getPessoasForSelect("PF");
    profs.push({ label: "TODOS", value: "T" });
    setProfissionaisSelect(profs);
  }

  async function getDados() {
    let ret = {};
    const status = ["A", "P", "R"];

    url = "consultorio";
    getProfissionais();

    if (profissionalFiltrado !== null && profissionalFiltrado.value !== "T") {
      ret = await requisitarAPI(
        () =>
          api.get(
            `${url}/agenda/${status}/?profissional=${profissionalFiltrado.value}`
          ),
        "Falha ao obter dados do servidor"
      );
    } else {
      ret = await requisitarAPI(
        () => api.get(`${url}/agenda/${status}`),
        "Falha ao obter dados do servidor"
      );
    }

    ret.response.data = ret.response.data.calendar;
    setRetorno(ret);

    if (!ret.erro) {
      ret.response.data.map((evento) => {
        evento.data_inicio = dateToField(evento.data_inicio);
        evento.data_fim = dateToField(evento.data_fim);

        return evento;
      });

      dispatch(
        EventosConsultorioActions.addEventosConsultorio(ret.response.data)
      );
    }
    setLoading(false);
  }

  async function getConfiguracoes() {
    const config = await getConfig();
    setConfig(config);
  }

  const CardEvento = ({ event }) => (
    <div>
      <label
        style={{
          color: "#fff",
          textAlign: "left",
          fontSize: "14px",
          fontWeight: "Normal",
          fontFamily: "Open Sans",
        }}
      >
        {" "}
        <b>{event.pessoa.nome} </b>
      </label>
    </div>
  );

  const eventStyleGetter = (event) => {
    let backgroundColor = "#7f8c8d";

    if (event.status === "P") {
      backgroundColor = "#0B8043";

      if (typeof event.pessoa.diagnosticos !== "undefined") {
        event.pessoa.diagnosticos.map((diag) => {
          if (diag.evolucoes.length !== 0) {
            const dataEvo = moment(
              diag.evolucoes[diag.evolucoes.length - 1].createdAt
            );
            const dataHorario = moment(event.data_inicio);

            if (dataEvo.isSameOrAfter(dataHorario)) {
              backgroundColor = "#0000ff";
            }
          }
        });
      }
    } else if (event.status === "R") backgroundColor = "#ffb303";

    var style = {
      backgroundColor: backgroundColor,
      borderRadius: "5px",
      opacity: 0.9,
      height: "100%",
      color: "#fff",
      width: "100%",
      border: "0px",
      display: "block",
      textAlign: "left",
      fontSize: "16px",
      paddingTop: "3px",
      paddingBottom: "3px",
    };

    return {
      style: style,
    };
  };

  const handleClickEvento = (props) => {
    setDadosClickEvent(props);
    setShowModalView(true);
  };

  const handleNovoEvento = ({ start, end }) => {
    if (
      isAgendaConsultorio &&
      usuarioLogado.perfil.permite_gerenciar_agenda_consultas
    ) {
      history.push(
        `/${url}/agenda/cadastro/${new Date(start)}/${new Date(end)}/0/0`
      );
    }
  };

  const eventos = () => {
    return eventosConsultorio;
  };

  useEffect(() => {
    getDados();
    getConfiguracoes();
  }, [profissionalFiltrado]);

  return (
    <>
      <div className="container">
        <AlertGeral retorno={retorno} />
        <Spinner loading={loading} />
      </div>

      <div className="container-calendario">
        <ModalGuiasfinalizada
          show={onGuiasFinalizadas}
          setShow={setOnGuiasFinalizadas}
          guia={idGuia}
        />

        <ModalViewAgenda
          tipo={tipo}
          history={history}
          dados={dadosClickEvent}
          show={showModalView}
          setShow={setShowModalView}
          setShowModalAulasConcluidas={setShowModalAulasConcluidas}
          dataQuadro={0}
          setGuiaFinalizada={setOnGuiasFinalizadas}
          setIdGuia={setIdGuia}
        />

        <Row>
          <Table responsive={true}>
            <Row style={{ marginTop: "5px", marginLeft: "0px" }}>
              <Col md={4}>
                <Select
                  name="id_profissional"
                  value={profissionalFiltrado}
                  placeholder="Fiiltrar Profissional"
                  options={ProfissionaisSelect}
                  onChange={(option) => {
                    setProfissionalFiltrado(option);
                  }}
                />
              </Col>
            </Row>

            <Calendar
              localizer={localizer}
              defaultDate={dataAtual}
              min={
                new Date(
                  dataAtual.getFullYear(),
                  dataAtual.getMonth(),
                  dataAtual.getDate(),
                  6
                )
              }
              max={
                new Date(
                  dataAtual.getFullYear(),
                  dataAtual.getMonth(),
                  dataAtual.getDate(),
                  23
                )
              }
              defaultView="week"
              style={{ height: "100%", padding: "10px", fontSize: "14px" }}
              messages={translate}
              selectable
              onSelectSlot={handleNovoEvento}
              onSelectEvent={(event) => handleClickEvento(event)}
              startAccessor="data_inicio"
              endAccessor="data_fim"
              // Pedro -> Comentei pois estava dando problema para acessar a agenda.
              //   step={config.tempoagenda}
              timeslots={1}
              components={{ event: CardEvento }}
              eventPropGetter={eventStyleGetter}
              events={eventos()}
            />
          </Table>
        </Row>
      </div>
    </>
  );
};

export default Calendario;
