import React from "react";

import {
  Page,
  Text,
  View,
  Document,
  Image,
  pdf,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { Button, Modal } from "react-bootstrap";

// Register font
Font.register({
  family: "Roboto",
  src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf",
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    display: "flex",
    height: "80vh",
  },
  textPrimary: {
    fontSize: 16,
    textAlign: "center",
    fontFamily: "Roboto",
    fontWeight: "bold",
    padding: 10,
  },
  header: {
    display: "flex",
    padding: 10,
    marginBottom: 10,
    flexDirection: "row",
  },
  headerTextContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: 10,
  },
  headerText: {
    fontSize: 12,
    marginBottom: 5,
  },
  text: {
    marginBottom: 8,
  },
  sectionTitle: {
    fontSize: 14,
    fontFamily: "Roboto",
    fontWeight: "bold",
    marginBottom: 12,
  },
  section: {
    padding: 10,
    margin: 5,
    flexGrow: 1,
    border: "1px solid #DDDDDD",
    display: "flex",
    flexDirection: "column",
    fontSize: 12,
  },
  section1Content: {
    display: "flex",
    flexDirection: "column",
  },
  lineTitle: {
    fontSize: 13,
    fontFamily: "Roboto",
    fontWeight: "bold",
  },
  section4Content: {},
  section4ContentBottomText: {
    marginTop: "auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  textSiginature: {
    width: "50%",
  },
  textItem: {
    fontSize: 10,
    marginTop: 5
  }
});

export default function ModalDeImpressao({ toggleModal, setToggleModal }) {
  const handleClose = () => {
    setToggleModal({
      ...toggleModal,
      isOpen: false,
    });
  };

  let data = new Date();
  let dataFormatada =
    data.getDate() + "/" + (data.getMonth() + 1) + "/" + data.getFullYear();

  const MyDoc = () => (
    <Document style={{ height: "100%" }}>
      <Page size="A4" style={styles.page}>
        <Text style={styles.textPrimary}>TERMOS DE CONSENTIMENTO</Text>
        <View style={styles.header}>
          <Image
            src="/img/logo.jpeg"
            alt="logo da empresa"
            style={{ width: 90, height: 80 }}
          />
          <View style={styles.headerTextContent}>
            <Text style={styles.headerText}>
              Alecrim Estética e terapia Capilar
            </Text>
            <Text style={styles.headerText}>40.057.854/0001-29</Text>
          </View>
        </View>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Dados do Cliente</Text>
          <View style={styles.section1Content}>
            <Text style={styles.text}>
              <Text style={styles.lineTitle}>Nome: </Text>
              {toggleModal.values.pessoaSelected.nome}
            </Text>
            <Text style={styles.text}>
              <Text style={styles.lineTitle}>Endereço: </Text>
              {toggleModal.values.pessoaSelected.logradouro},
              {toggleModal.values.pessoaSelected.numero} -{" "}
              {toggleModal.values.pessoaSelected.estado}
            </Text>
            <Text style={styles.text}>
              <Text style={styles.lineTitle}>Bairro: </Text>
              {toggleModal.values.pessoaSelected.Bairro}
            </Text>
          </View>
        </View>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Anamnese</Text>
          <Text style={{fontSize:"10px"}}>{toggleModal.values.values.queixa_principal}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Observação</Text>
          <Text style={{fontSize:"10px"}}>{toggleModal.values.values.obs_report}</Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Regras Gerais</Text>
          <Text style={styles.textItem}> 
            * O plano de tratamento não pode ser transferido. Caso a(o) cliente opte pela 
                desistência do tratamento após sete dias do início do mesmo, não será devolvido o valor 
                do primeiro mês pago. 
          </Text>
          <Text style={styles.textItem}>  
            * O não comparecimento nas sessões sem aviso com pelo menos 24 horas de 
                antecedência será descontado como sessão realizada, pois não terei tempo hábil para 
                substituir o horário com outra pessoa.  
          </Text>
          <Text style={styles.textItem}>
            * A tolerância de atrasos será de no máximo 15 minutos. Após este tempo, a aplicação 
              do tratamento na sessão em questão poderá ter o seu tempo reduzido afetando 
              diretamente os resultados desejados. 
          </Text>
          <Text style={styles.textItem}>
            * O tratamento realizado inclui procedimentos específicos para a recuperação e 
                manutenção da cliente, fica a cliente ciente das obrigações e orientações passadas pelo 
                profissional, bem como os cuidados diários para uso em casa, podendo afetar diretamente 
                o resultado final se não for cumprido.
          </Text>
          <Text style={styles.textItem}>
            * A cliente fica ciente que terá que seguir as orientações, ficando assim o profissional 
              livre de quaisquer danos decorrentes ao mau uso dos produtos ou o não cumprimento das 
              orientações. Os cuidados tomados em casa representam 50% dos resultados de qualquer 
              tratamento estético. 
          </Text>
          <Text style={styles.textItem}>
            * Fica o(a) cliente ciente de que o não esclarecimento e a omissão de antecedentes de 
              qualquer tipo no ato do preenchimento da ficha de anamnese, deixam o profissional isento 
              de qualquer responsabilidade.
          </Text>

        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Termos de Consentimento</Text>

          <View style={styles.section4ContentText}>
            <Text>DECLARO QUE AS INFORMAÇÕES ACIMA PRESTADAS SÃO VERDADEIRAS, E ASSUMO A INTEIRA RESPONSABILIDADE PELAS MESMAS.</Text>
          </View>

          <View style={styles.section4ContentBottomText}>
            <Text >
              <label style={styles.sectionTitle}>Data: </label>
              <label> {dataFormatada} </label>
            </Text>
            <Text style={styles.textSiginature}>
            <label style={styles.sectionTitle}>Assinatura: </label>
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );

  const hanPrint = async () => {
    const doc = <MyDoc />;
    const asPdf = pdf(); // {} is important, throws without an argument
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, "document.pdf");
    setToggleModal({
      ...toggleModal,
      isOpen: false,
    });
  };

  return (
    <Modal size="xl" centered show={toggleModal.isOpen} onHide={handleClose}>
      <Modal.Body>
        <MyDoc />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={hanPrint}>
          Download PDF
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
