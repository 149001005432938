import { format } from "date-fns";
import React from "react";

const fecharGuia = (props) => {
  const { Fechado } = props;
  return <>{Fechado}</>;
};

const formatData = (props) => {
  const { data_fechamento } = props;
  const data = format(new Date(data_fechamento), "dd/MM/yyyy");
  return <>{data_fechamento ? data : ""}</>;
};

export const colunasListaGuia = [
  {
    field: "status",
    header: "Status",
    style: { textAlign: "Center" },
    sortable: true,
  },
  {
    field: "numero_guia",
    header: "Número Guia",
    style: { textAlign: "Center" },
    sortable: true,
  },
  {
    field: "paciente",
    header: "Paciente",
    style: { textAlign: "Center" },
    sortable: true,
  },
  {
    field: "data_vencimento_guia",
    header: "Data Vencimento",
    style: { textAlign: "Center" },
    sortable: true,
  },
  {
    field: "quantidade_consultas_realizadas",
    header: "Consultas Realizadas",
    style: { textAlign: "Center" },
    sortable: true,
  },
  {
    field: "quantidade_consultas",
    header: "Quantidade Consultas",
    style: { textAlign: "Center" },
    sortable: true,
  },
  {
    field: "data_fechamento",
    header: "Data de fechamento",
    style: { textAlign: "Center" },
    body: formatData,
    sortable: true,
  },
  {
    field: "fechado",
    header: "Fechar Guia",
    style: { textAlign: "Center" },
    sortable: true,
    body: fecharGuia,
  },
];
