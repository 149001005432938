import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronLeft, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { AlertGeral, requisitarAPI } from "../../components/AlertGeral";
import { DatePickerField } from "../../components/Form/DatePicker.js";
import FieldValidate from "../../components/Form/FieldValidate.js";
import { SelectComponent } from "../../components/Form/Select.js";
import MainMenu from "../../components/MainMenu";
import Spinner from "../../components/Spiner";
import { dateTimeToView } from "../../Global/funcoes";
import "../../Global/styleGeral.css";
import api from "../../services/api";
import { Creators as EventosConsultorioActions } from "../../Store/Controls/agendas/eventosConsultorio";
library.add(faSave, faChevronLeft);

const CancelarAgendamento = ({ history, match }) => {
  const [retorno, setRetorno] = useState({});
  const [loading, setLoading] = useState(true);
  const [initialValues, setinitialValues] = useState({
    acao: "",
    data_inicio: "",
    data_fim: "",
  });
  const [evento, setEvento] = useState(null);
  const [reagendar, setReagendar] = useState(false);
  const [excluir, setExcluir] = useState(false);
  const [excluirIntervalo, setExcluirIntervalo] = useState(false);

  const tipo = match.params.tipo;
  const isConsultorio = tipo === "consultorio";

  const eventosConsultorio = useSelector((state) => state.eventosConsultorio);
  const dispatch = useDispatch();

  let opcoes = [{}];

  const getURLRedirect = useCallback(() => {
    if (match.params.dataRedirect === "0") {
      return `/${tipo}/agenda`;
    } else {
      return "/consultorio/quadro/" + match.params.dataRedirect;
    }
  }, []);

  useEffect(() => {
    setLoading(true);

    if (typeof match.params.id !== undefined) {
      let evnt = {};
      evnt = eventosConsultorio.find((item) => item.id == match.params.id);

      if (typeof evnt === "undefined") {
        history.push(getURLRedirect());
        return;
      }

      setEvento(evnt);

      initialValues.data_inicio = evnt.data_inicio;
      initialValues.data_fim = evnt.data_inicio;

      setinitialValues(initialValues);
    }

    opcoes.push({ label: "Excluir Evento", value: "E" });

    opcoes.push({ label: "Reagendar Evento", value: "R" });

    opcoes.push({ label: "Excluir todas consultas", value: "T" });
    setLoading(false);
    // opcoes,
  }, [
    eventosConsultorio,
    getURLRedirect,
    history,
    initialValues,
    isConsultorio,
    match.params.id,
    opcoes,
    tipo,
  ]);

  const schema = Yup.object().shape(
    {
      acao: Yup.string().required("Informe o que deseja fazer com o evento"),
      data_inicio: Yup.date()
        .nullable()
        .when("acao", (acao, schema) =>
          acao === "R" ? schema.required("Informe a data inicial") : schema
        ),
    },
    ["acao", "acao"]
  );

  // submete o formulário para envio
  const handleSubmit = async (values) => {
    // animação de loading
    setLoading(true);

    // variável de escopo para armazenar o retorno da API
    let ret = {};
    // A data é válida?
    if (values.data_inicio > values.data_fim) {
      retorno.erro = true;
      retorno.titleErro = "Erro de validação";
      retorno.descriptErro = "Data inicial superior a data final";
    }

    // A ação é igual a excluir?
    if (values.acao === "E") {
      evento.status = "C";
      evento.motivo_cancelamento = values.motivo_cancelamento;
      evento.allguide = false;

      const ret = await requisitarAPI(
        () => api.put(`${tipo}/agenda/${match.params.id}`, evento),
        "Falha ao excluir agenda"
      );
      setRetorno(ret);

      if (!ret.erro) {
        dispatch(
          EventosConsultorioActions.removeEventoConsultorio(
            match.params.id,
            values
          )
        );
      }
    }
    // A ação é igual a reagendar?
    // não sei o motivo pelo qual era o "T"
    else if (values.acao === "T") {
      if (evento.data_inicio) {
        evento.data_inicio = values.data_inicio;
        evento.data_fim = values.data_fim;
      }
      evento.motivo_cancelamento = values.motivo_cancelamento;
      const data = {
        diaSemana: evento.data_inicio.getDay(),
        horaDia: evento.data_inicio.getHours(),
        minutoDia: evento.data_inicio.getMinutes(),
        ...evento,
      };

      if (new Date(evento.data_inicio) === new Date(evento.data_fim)) {
        data.data_fim = undefined;
      }

      await requisitarAPI(
        () => api.post(`${tipo}/agendadelete/${match.params.id}`, data),
        "Falha ao excluir agenda"
      );
      dispatch(
        EventosConsultorioActions.removeEventoConsultorio(
          match.params.id,
          values
        )
      );
    }
    // caso não seja nenhuma das anteriores, é porque é uma açõe de reagendar
    else {
      evento.data_inicio = values.data_inicio;
      evento.data_fim = values.data_fim;
      evento.status = "R";
      evento.allguide = false;

      

      const ret = await requisitarAPI(
        () => api.put(`${tipo}/agenda/${match.params.id}`, evento),
        "Falha ao editar agenda"
      );
      setRetorno(ret);

      if (!ret.erro) {
        dispatch(
          EventosConsultorioActions.editEventoConsultorio(
            match.params.id,
            evento
          )
        );
      }
    }

    setLoading(false);

    if (!ret.erro) {
      history.push(getURLRedirect());
    }
  };

  const onChangeValue = (val) => {
    if (val.value === "R") {
      setReagendar(true);
      setExcluir(false);
      setExcluirIntervalo(false);
    } else if (val.value === "E") {
      setReagendar(false);
      setExcluir(true);
      setExcluirIntervalo(false);
    } else if (val.value === "T") {
      setReagendar(false);
      setExcluir(false);
      setExcluirIntervalo(true);
    }
  };

  return (
    <>
      <MainMenu navigation={history} />

      <div className="titulo-pagina">
        Cancelamento Agendamento
        <Button
          variant="voltar"
          onClick={() => {
            history.push(getURLRedirect());
          }}
        >
          <FontAwesomeIcon className="icone" icon="chevron-left" /> Voltar para
          Agenda Consultório
        </Button>
      </div>

      <div className="container-principal">
        {evento && (
          <Card bg="light" border="primary">
            <Card.Body>
              <Card.Title
                className="d-flex justify-content-left"
                style={{ color: "#082044" }}
              >
                <h4>
                  <b>{evento.pessoa.nome}</b>
                </h4>
              </Card.Title>
              <Card.Subtitle
                className="mb-2 text-muted"
                style={{ marginTop: "10px", marginLeft: "10px" }}
              >
                <Row>
                  {" "}
                  <b>Data inicial:</b>{" "}
                  <span style={{ marginLeft: "10px" }}>
                    {dateTimeToView(evento.data_inicio)}
                  </span>{" "}
                </Row>
                <Row style={{ marginTop: "10px" }}>
                  {" "}
                  <b>Data Final:</b>{" "}
                  <span style={{ marginLeft: "15px" }}>
                    {dateTimeToView(evento.data_fim)}
                  </span>{" "}
                </Row>
              </Card.Subtitle>
            </Card.Body>
          </Card>
        )}
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={schema}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ errors }) => (
            <Form>
              <Row>
                <Col md={4} className="form-group">
                  <label className="obrigatorio">
                    Informe o que deseja fazer com o evento *
                  </label>
                  <FieldValidate
                    name="acao"
                    onChangeValue={onChangeValue}
                    component={SelectComponent}
                    options={opcoes}
                    errors={errors}
                    noClass
                  />
                </Col>

                {excluir && !reagendar && (
                  <Col className="form-group">
                    <label> Motivo da Exclusão </label>
                    <Field
                      name="motivo_cancelamento"
                      placeholder="(Opcional)"
                      className="form-control"
                      as="textarea"
                    />
                  </Col>
                )}

                {reagendar && !excluir && (
                  <>
                    <Col sm={12} md={3} className="form-group">
                      <label>Data início*</label>
                      <br />
                      <DatePickerField
                        withPortal
                        dateFormat="dd/MM/yyyy HH:mm"
                        showTimeSelect
                        name="data_inicio"
                        placeholder="Digite a data de evolução*"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="data_inicio"
                        component="div"
                        style={{ fontSize: "16px", color: "red" }}
                      />
                    </Col>

                    <Col sm={12} md={3} className="form-group">
                      <label>Data Fim*</label>
                      <br />
                      <DatePickerField
                        withPortal
                        dateFormat="dd/MM/yyyy HH:mm"
                        showTimeSelect
                        name="data_fim"
                        placeholder="Digite a data de evolução*"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="data_fim"
                        component="div"
                        style={{ fontSize: "16px", color: "red" }}
                      />
                    </Col>
                  </>
                )}

                {excluirIntervalo && !reagendar && !excluir && (
                  <>
                    <Col sm={12} md={3} className="form-group">
                      <label>Data início*</label>
                      <br />
                      <DatePickerField
                        withPortal
                        dateFormat="dd/MM/yyyy HH:mm"
                        showTimeSelect
                        name="data_inicio"
                        placeholder="Digite a data de evolução*"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="data_inicio"
                        component="div"
                        style={{ fontSize: "16px", color: "red" }}
                      />
                    </Col>

                    <Col sm={12} md={3} className="form-group">
                      <label>Data Fim*</label>
                      <br />
                      <DatePickerField
                        withPortal
                        dateFormat="dd/MM/yyyy HH:mm"
                        showTimeSelect
                        name="data_fim"
                        placeholder="Digite a data de evolução*"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="data_fim"
                        component="div"
                        style={{ fontSize: "16px", color: "red" }}
                      />
                    </Col>
                  </>
                )}
              </Row>

              <div style={{ width: "80%", marginLeft: "25px" }}>
                <AlertGeral retorno={retorno} />
                <Spinner loading={loading} />
              </div>

              <Row
                style={{ marginTop: "40px" }}
                className="form-group text-center"
              >
                <Col className="text-center">
                  <Button variant="salvar" type="submit">
                    <FontAwesomeIcon className="icone" icon="save" /> Executar
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default CancelarAgendamento;
