/* eslint-disable react-hooks/exhaustive-deps */
// Dependencies
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { AlertGeral, requisitarAPI } from "../../components/AlertGeral";
import Spinner from "../../components/Spiner";
import { getPessoas } from "../../Global/chamadasAPI";
import { dateToField, timeToView } from "../../Global/funcoes";
import api from "../../services/api";
import { Creators as EventosConsultorioActions } from "../../Store/Controls/agendas/eventosConsultorio";
import ModalGuiasVencendo from "../Agenda/modalGuiasVencendo";

//Adicionado Menu
import moment from "moment";
import MainMenu from "../../components/MainMenu/";
import ModalQuadroSel from "../../components/ModalQuadroSel/";

// styles
import { Container } from "./index.styles";

SwiperCore.use([Navigation]);

const Quadro = ({ history, match }) => {
  const dispatch = useDispatch();
  const eventosConsultorio = useSelector((state) => state.eventosConsultorio);

  const [data, setData] = useState();
  const [guias, setGuias] = useState([]);
  const [vencendo, setVencendo] = useState([]);
  const [onGuiaVencendo, setOnGuiaVencendo] = useState(false);
  const [pessoas, setPessoas] = useState([{}]);
  const [pessoasJornada, setPessoasJornada] = useState([{}]);
  // const [eventos, setEventos] = useState(([{}]));
  const [loading, setLoading] = useState(true);
  const [retorno, setRetorno] = useState({});
  const [horarios, setHorarios] = useState([]);
  const [showModalQuadro, setShowModalQuadro] = useState(false);
  const [eventosModal, setEventosModal] = useState([{}]);
  const [dataCalendario, setDataCalendario] = useState(
    moment().format("YYYY-MM-DD")
  );

  const diaSemana = [
    "Domingo",
    "Segunda-Feira",
    "Terça-Feira",
    "Quarta-Feira",
    "Quinta-Feira",
    "Sexta-Feira",
    "Sábado",
  ];
  async function getDadosPessoas() {
    setPessoas(await getPessoas("PF"));
  }

  async function getAgenda(dataP) {
    const status = ["A", "P", "R"];

    let ret = await requisitarAPI(
      () =>
        api.get(
          `consultorio/agenda/${status}/?profissional=0&data_inicio=${dataP}`
        ),
      "Falha ao obter o resumo do servidor"
    );

    if (ret.response.data.vencendo.length > 0) {
      setVencendo(ret.response.data.vencendo);
      setOnGuiaVencendo(true);
    }

    setGuias(ret.response.data.guias);
    ret.response.data = ret.response.data.calendar;
    if (!ret.erro) {
      // setEventos(ret.response.data);
      ret.response.data.map((evento) => {
        evento.data_inicio = dateToField(evento.data_inicio);
        evento.data_fim = dateToField(evento.data_fim);

        return evento;
      });

      dispatch(
        EventosConsultorioActions.addEventosConsultorio(ret.response.data)
      );
    }
    setRetorno(ret);
  }

  function montaHorarios(dataParam) {
    let continua = true;

    let datainicio = moment(dataParam).toDate();
    datainicio.setHours(6);
    datainicio.setMinutes(30);
    datainicio.setSeconds(0);

    let datafim = moment(dataParam).toDate();
    datafim.setHours(19);
    datafim.setMinutes(0);
    datafim.setSeconds(0);
    datafim = moment(datafim).add(30, "minutes").format();

    let arrayhora = [];
    while (continua) {
      datainicio = moment(datainicio).add(30, "minutes").format();
      arrayhora.push(datainicio);

      if (datainicio > datafim) {
        continua = false;
      }
    }

    setHorarios(arrayhora);
  }

  async function getJornadas() {
    const ret = await requisitarAPI(
      () => api.get("jornadaTrabalho"),
      "Falha ao editar grupo"
    );
    if (!ret.erro) {
      setPessoasJornada(ret.response.data);
    }
  }

  async function getAgendaeDelay() {
    await getAgenda(match.params.dataParam);
  }

  useEffect(() => {
    dispatch(EventosConsultorioActions.addEventosConsultorio([]));
    getDadosPessoas();
    getJornadas();
    getAgendaeDelay();
    setData(match.params.dataParam);
    montaHorarios(match.params.dataParam);
    setLoading(false);
  }, []);

  function onCalcularData(date, dias) {
    return moment(date).add(dias, "days").toDate();
  }

  const getJornadaPessoa = (data, listaDias) => {
    let retornoBoolean = false;

    if (typeof listaDias === "undefined" || listaDias.length === 0) {
      return retornoBoolean;
    }

    const intDiaSemana = moment(data).format("d");

    for (const item of listaDias) {
      if (intDiaSemana == item.dia) {
        let [hora, minutos] = timeToView(item.horario).split(":");

        let data_inicio = moment(data).toDate();
        data_inicio.setHours(hora);
        data_inicio.setMinutes(minutos - 1);
        data_inicio.setSeconds(0);

        [hora, minutos] = timeToView(item.horario_fim).split(":");

        let data_fim = moment(data).toDate();
        data_fim.setHours(hora);
        data_fim.setMinutes(minutos);
        data_fim.setSeconds(0);

        data = moment(data);
        retornoBoolean = data.isBetween(data_inicio, data_fim);

        if (retornoBoolean === true) {
          return retornoBoolean;
        }
      }
    }

    return retornoBoolean;
  };

  function HorariosQuadro({ horario, pessoa }) {
    let achou = false;
    let horariofim = moment(horario).add(30, "minutes").format("HH:mm");
    let classe = "semHorario";

    let horarioiniciofrmt = moment(horario).format("HH:mm");
    var arrayHrInicio = horarioiniciofrmt.split(":");
    var arrayHrFim = horariofim.split(":");

    let start = moment(horario).toDate();
    start.setHours(arrayHrInicio[0]);
    start.setMinutes(arrayHrInicio[1]);
    start.setSeconds(0);

    let end = moment(horario).toDate();
    end.setHours(arrayHrFim[0]);
    end.setMinutes(arrayHrFim[1]);
    end.setSeconds(0);

    let retorno = (
      <a
        href={`/consultorio/agenda/cadastro/${start}/${end}/${pessoa.id}/${data}`}
      >
        <p className={classe}>
          {moment(horario).format("HH:mm") + " - " + horariofim}
        </p>
        <span></span>
      </a>
    );

    if (!getJornadaPessoa(horario, pessoa.jornadaTrabalho)) {
      retorno = (
        <>
          <p className="bloqueado">
            <b>
              {moment(horario).format("HH:mm") + " - " + horariofim}
              <br />
            </b>
            <span className="nomePaciente">BLOQUEADO</span>
          </p>
        </>
      );
    } else {
      var listEventos = [];

      eventosConsultorio.map((evento) => {
        if (
          evento.id_profissional === pessoa.id &&
          evento.status !== "C" &&
          (moment(end).format("HH:mm") ===
            moment(evento.data_fim).format("HH:mm") ||
            moment(horario).format("HH:mm") ===
              moment(evento.data_inicio).format("HH:mm"))
        ) {
          listEventos.push(evento);
        }
      });

      if (listEventos.length !== 0) {
        retorno = (
          <>
            <div
              onClick={() => {
                setEventosModal(listEventos);
                setShowModalQuadro(true);
              }}
              className="comHorario"
            >
              <b>{moment(horario).format("HH:mm") + " - " + horariofim}</b>

              {listEventos.map((evento) => {
                let color = "white";

                if (evento.status === "P") {
                  color = "#A1FA6F";
                } else if (evento.status === "R") {
                  color = "#F5FB77";
                }

                if (typeof evento.pessoa.diagnosticos !== "undefined") {
                  evento.pessoa.diagnosticos.map((diag) => {
                    if (diag.evolucoes.length !== 0) {
                      const dataEvo = moment(
                        diag.evolucoes[diag.evolucoes.length - 1].createdAt
                      );
                      const dataHorario = moment(horario);
                      if (dataEvo.isSameOrAfter(dataHorario)) {
                        color = "#6887F8";
                      }
                    }
                  });
                }

                return (
                  <>
                    <span style={{ color: color }}>
                      {evento.pessoa.nome} - {evento.repeticoes || ""}
                      <br />
                    </span>
                    {evento.listaTags && <span>{evento.listaTags.icone}</span>}
                  </>
                );
              })}
            </div>
          </>
        );
      }
    }

    return retorno;
  }

  const moverSemana = (dias) => {
    const novaData = onCalcularData(data, dias);
    setData(novaData);
    getAgenda(novaData);
    montaHorarios(novaData);
  };

  function prevButton() {
    const novaData = onCalcularData(data, -1);
    setData(novaData);
    getAgenda(novaData);
    montaHorarios(novaData);
  }

  function nextButton() {
    const novaData = onCalcularData(data, 1);
    setData(novaData);
    getAgenda(novaData);
    montaHorarios(novaData);
  }

  return (
    <div>
      <ModalQuadroSel
        eventosM={eventosModal}
        show={showModalQuadro}
        setShow={setShowModalQuadro}
        history={history}
      />

      <ModalGuiasVencendo
        arrVencendo={vencendo}
        setShow={setOnGuiaVencendo}
        show={onGuiaVencendo}
      />

      <MainMenu navigation={history} />

      <Container>
        <Swiper slidesPerView={1} modules={[Navigation]}>
          <div className="swiper-button-prev" onClick={prevButton} />
          <div className="swiper-button-next" onClick={nextButton} />

          <SwiperSlide>
            <h2 style={{ alignSelf: "center" }}>Quadro de horários</h2>

            <p style={{ textAlign: "center" }}>
              <span style={{ backgroundColor: "#7f8c8d" }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>{" "}
              &nbsp; Disponível &nbsp;&nbsp;
              <span style={{ backgroundColor: "#5c5757" }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>{" "}
              &nbsp; Marcado &nbsp;&nbsp;
              <span style={{ backgroundColor: "#0b8043" }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>{" "}
              &nbsp; Confirmado &nbsp;&nbsp;
              <span style={{ backgroundColor: "yellow" }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>{" "}
              &nbsp; Reagendado &nbsp;
              <span style={{ backgroundColor: "#6c51c3" }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>{" "}
              &nbsp; Feito Evolução &nbsp;
              <span style={{ backgroundColor: "#082044" }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>{" "}
              &nbsp; Profissional &nbsp;
            </p>

            <div className="container">
              <AlertGeral retorno={retorno} />
              <Spinner loading={loading} />
            </div>

            <p>
              {diaSemana[moment(data).format("d")]}{" "}
              {moment(data).format("D [de] MMMM")}{" "}
              <h3>{moment(data).format("DD/MM")}</h3>
            </p>

            {/*
             * input com um label para um input de data
             */}

            <div className="controle-semana">
              <label htmlFor="data">Ir para</label>
              <input
                type="date"
                id="data-navigation"
                name="data"
                value={dataCalendario}
                onChange={(e) => {
                  const novaData = moment(e.target.value);
                  setDataCalendario(e.target.value);
                  setData(novaData);
                  getAgenda(novaData);
                  montaHorarios(novaData);
                }}
              />
            </div>

            {/* <div className="controle-semana">
              <span
                onClick={() => {
                  moverSemana(-7);
                }}
              >
                {diaSemana[moment(data).format("d")]}
              </span>{" "}
              anterior ou próxima{" "}
              <span
                onClick={() => {
                  moverSemana(7);
                }}
              >
                {diaSemana[moment(data).format("d")]}
              </span>
            </div> */}

            <div className="container">
              {pessoas.map((pessoa) => (
                <div className="content">
                  <div className="fixedElement">
                    <h4>{pessoa.nome}</h4>
                  </div>
                  <div className="nomeProfissionais">
                    <h4>{pessoa.nome}</h4>
                  </div>
                  {horarios.map((horario) => (
                    <HorariosQuadro horario={horario} pessoa={pessoa} />
                  ))}
                </div>
              ))}
            </div>
          </SwiperSlide>
        </Swiper>
      </Container>
    </div>
  );
};

export default Quadro;
