import React, { useEffect, useState } from "react";
import { Button, Tab, Tabs, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Creators as CaixasActions } from "../../Store/Controls/caixas";
import { colunasCaixa } from "../../components/Listagens/colunas";
import { requisitarAPI } from "../../components/AlertGeral";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import MainMenu from "../../components/MainMenu";
import Listagem from "../../components/Listagens";
import "../../Global/styleGeral.css";
import Spinner from "../../components/Spiner";
import api from "../../services/api";

const CaixasFinanceiro = ({ history }) => {
  const [loading, setLoading] = useState(false);

  const caixas = useSelector((state) => state.caixas);
  const dispatch = useDispatch();

  async function getDados() {
    // Caixa
    async function findCaixas() {
      let ret = await requisitarAPI(
        () => api.get("caixas"),
        "Falha ao obter os caixas do servidor"
      );

      if (!ret.erro) {
        dispatch(CaixasActions.addCaixas(ret.response.data));
      }
    }
    await findCaixas();

    setTimeout(() => setLoading(false), 800);
  }

  useEffect(() => {
    getDados();
  }, []);

  if (!loading) {
    return (
      <>
        <MainMenu navigation={history} />

        <div className="titulo-pagina">
          <Tabs
            activeKey={"caixas"}
            onSelect={(k) => {
              history.push(`/financeiro/${k}`);
            }}
            className="mb-3"
          >
            <Tab eventKey="resumo" title="Resumo"></Tab>
            <Tab eventKey="movimentacoes" title="Movimentações"></Tab>
            <Tab eventKey="contas-a-receber" title="Contas a Receber"></Tab>
            <Tab eventKey="contas-a-pagar" title="Contas a Pagar"></Tab>
            <Tab eventKey="caixas" title="Caixas">
              Caixas
              <Button
                variant="principal"
                onClick={() => {
                  history.push("/financeiro/caixas/cadastro");
                }}
                alt="Adicione um novo caixa"
                title="Adicione um novo caixa"
              >
                <FontAwesomeIcon className="icone" icon={faPlus} /> Incluir um
                novo Caixa
              </Button>
              <div
                style={{ margin: 0, marginTop: 20 }}
                className="container-principal"
              >
                <Row>
                  <Listagem
                    dados={caixas}
                    cols={colunasCaixa}
                    url_edit="financeiro/caixas/editar"
                    tipo_delete="caixas"
                    history={history}
                  />
                </Row>
              </div>
            </Tab>
            <Tab
              eventKey="formas-de-pagamento"
              title="Formas de Pagamento"
            ></Tab>
            <Tab eventKey="comissoes" title="Comissões"></Tab>
          </Tabs>
        </div>
      </>
    );
  } else {
    return (
      <div
        style={{
          width: "220px",
          height: "30px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        <h4
          style={{
            color: "#123abc",
            fontSize: "18px",
            fontWeight: "bold",
            textTransform: "uppercase",
            margin: "10px 0px",
          }}
        >
          Carregando..
        </h4>
        <Spinner height={20} animation="grow" variant="primary" />
      </div>
    );
  }
};

export default CaixasFinanceiro;
