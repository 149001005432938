/* eslint-disable react-hooks/exhaustive-deps */
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBuilding,
  faChevronLeft,
  faMapMarkerAlt,
  faQuestionCircle,
  faSave,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { AlertGeral, requisitarAPI } from "../../components/AlertGeral";
import { DatePickerField } from "../../components/Form/DatePicker.js";
import FieldValidate from "../../components/Form/FieldValidate.js";
import { MoedaFormat } from "../../components/Form/MoedaFormat.js";
import { SelectComponent } from "../../components/Form/Select.js";
import MainMenu from "../../components/MainMenu";
import Spinner from "../../components/Spiner";
import {
  getCaixasForSelect,
  getFormaPagamentoForSelect,
  getPessoasForSelect,
  gravarFinanceiro,
} from "../../Global/chamadasAPI";
import { dateToField } from "../../Global/funcoes";
import "../../Global/styleGeral.css";
import api from "../../services/api";
import { Creators as ContasReceberActions } from "../../Store/Controls/financeiros/contasReceber";

library.add(faSave, faChevronLeft, faUsers, faMapMarkerAlt, faBuilding);

const CadastroContasReceber = ({ history, match }) => {
  const [editForm, setEditForm] = useState(false);
  const [contaReceber, setContaReceber] = useState({
    id_pessoa: "",
    id_profissional: "",
    id_caixa: "",
    descricao: "",
    data_geracao: new Date(),
    id_conta: "",
    data_vencimento: new Date(),
    valor: "",

    parcelas_total: 0,
    parcelas_intervalo: 0,
  });
  const [pessoas, setPessoas] = useState([{}]);
  const [profissionais, setProfissionais] = useState([{}]);
  const [procedimentos, setProcedimentos] = useState([{}]);
  const [pessoaSel, setPessoaSel] = useState([{}]);
  const [captionForm, setCaptionForm] = useState("Cadastro de Contas Receber");
  const [retorno, setRetorno] = useState({});
  const [loading, setLoading] = useState(true);
  const [geracaoMensalidade, setGeracaoMensalidade] = useState(false);
  const [isBaixaAutomatica, setIsBaixaAutomatica] = useState(false);
  const [isEmitirNf, setIsEmitirNf] = useState(false);
  const [formasPagamento, setFormasPagamento] = useState([{}]);
  const [caixas, setCaixas] = useState([{}]);

  const contasReceber = useSelector((state) => state.contasReceber);
  const dispatch = useDispatch();

  async function getDadosSelects() {
    setPessoas(await getPessoasForSelect("P"));
  }

  async function getProfissionais() {
    setProfissionais(await getPessoasForSelect("PF"));
  }

  const formatTags = (arr = []) => {
    return arr.map((item) => {
      return {
        label: item.description,
        value: `${item.id}@${item.commission}@${item.description}`,
      };
    });
  };

  const getProcedimentos = () => {
    api
      .get("listaProcedimentos/consultorio")
      .then(({ data }) => {
        setProcedimentos(formatTags(data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  async function getPessoa() {
    let ret = await requisitarAPI(
      () => api.get("pessoas/" + match.params.id_pessoa),
      "Falha ao obter dados do servidor"
    );
    setRetorno(ret);
    if (!ret.erro) {
      setPessoaSel(ret.response.data);
    }
  }

  useEffect(() => {

    async function getDadosSelectsFormaPagamento() {
      setFormasPagamento(await getFormaPagamentoForSelect());
    }

    async function getDadosSelectsCaixas() {
      setCaixas(await getCaixasForSelect());
    }

    getDadosSelectsFormaPagamento();

    getDadosSelectsCaixas();

    getDadosSelects();

    getProfissionais();

    getProcedimentos();

    if (typeof match.params.id_pessoa !== "undefined") {
      getPessoa();

      setGeracaoMensalidade(true);

      contaReceber.id_pessoa = match.params.id_pessoa;
      contaReceber.descricao = "Mensalidade";
      contaReceber.tot_mensalidades = 12;
      setContaReceber(contaReceber);
    }

    if (typeof match.params.id !== "undefined") {
      setCaptionForm("Edição de Contas a Receber");
      setEditForm(true);

      let contaReceber = contasReceber.find(item => item.id == match.params.id);
  
      if (typeof contaReceber === "undefined") {
        history.push("/financeiro/contas-a-receber");
        return;
      }

      contaReceber.data_geracao = dateToField(contaReceber.data_geracao);
      contaReceber.data_vencimento = dateToField(contaReceber.data_vencimento);

      setContaReceber(contaReceber);
    }

    setLoading(false);
  }, [
    contaReceber,
    contasReceber,
    history,
    match.params.id,
    match.params.id_pessoa,
  ]);

  const schema = Yup.object().shape(
    {
      id_pessoa: Yup.number().required("Informe a Pessoa"),
      id_profissional: Yup.number().required("Informe o Profissional"),
      id_caixa: Yup.number().required("Informe o Caixa"),
      descricao: Yup.string().required("Informe a Descricão"),
      data_geracao: Yup.date()
        .typeError("Informe uma data válida")
        .max(new Date(), "Data de geração superior a data atual"),
      data_vencimento: Yup.date().typeError("Informe uma data válida"),
      valor: Yup.number().required("Informe o valor"),
      parcelas_total: Yup.number()
        .when("parcelas_intervalo", (parcelas_intervalo, schema) =>
          parcelas_intervalo > 0 && !editForm
            ? schema.required("Informe o total de parcelas")
            : schema
        )
        .typeError("informe um número válido"),
      parcelas_intervalo: Yup.number()
        .when("parcelas_total", (parcelas_total, schema) =>
          parcelas_total > 0 && !editForm
            ? schema.required("Informe o intervalo de dias")
            : schema
        )
        .typeError("informe um número válido"),
    },
    ["parcelas_total", "parcelas_intervalo"]
  );

  const handleSubmit = async (values) => {
    setLoading(true);

    if (geracaoMensalidade) {
      values.parcelas_total = values.tot_mensalidades;
    }

    if (values.procedimento) {
      values.id_procedimento = values.procedimento.split('@')[0]
      if (values.procedimento.split('@')[2].toLowerCase() === 'pilates') {
        values.parcelas_total = 12
        values.parcelas_intervalo = 30
      }
    } 


    let ret = {};
    if (editForm) {
      ret = await requisitarAPI(
        () => api.put("contasReceber/" + match.params.id, values),
        "Falha ao editar Conta a Receber"
      );
      setRetorno(ret);

      if (!ret.erro) {
        dispatch(
          ContasReceberActions.editContaReceber(
            match.params.id,
            ret.response.data
          )
        );
      }
    } else {
      await gravarFinanceiro(values, setRetorno);

      if (!ret.erro) {
        //dispatch(ContasReceberActions.addContaReceber(ret.response));
      }

      values.parcelas_sequencia = 1;

      if (values.parcelas_total > 0) {
        for (let i = 0; i < values.parcelas_total - 1; i++) {
          values.parcelas_sequencia++;

          if (geracaoMensalidade) {
            values.data_vencimento = moment(values.data_vencimento).add(
              1,
              "month"
            );
          } else {
            values.data_vencimento = moment(values.data_vencimento).add(
              values.parcelas_intervalo,
              "days"
            );
          }

          ret = await requisitarAPI(
            () => api.post("contasReceber", values),
            "Falha ao cadastar Conta a Receber"
          );
          setRetorno(ret);

          if (!ret.erro) {
            //dispatch(ContasReceberActions.addContaReceber(ret.response));
          }
        }
      }
    }

    setLoading(false);

    if (!ret.erro) {
      history.push("/financeiro/contas-a-receber");
    }
  };

  return (
    <>
      <MainMenu navigation={history} />

      <div className="titulo-pagina">
        {geracaoMensalidade && typeof pessoaSel.nome !== "undefined"
          ? "Geração Mensalidade - " + pessoaSel.nome
          : captionForm}
        <Button
          variant="voltar"
          onClick={() => {
            history.push("/financeiro/contas-a-receber");
          }}
        >
          <FontAwesomeIcon className="icone" icon="chevron-left" /> Voltar para
          Gerenciar Contas a Receber
        </Button>
        <a
          href="/ajuda#financeiro"
          alt="Clique para acessar página de ajuda"
          style={{ float: "right" }}
          title="Clique para acessar página de ajuda"
        >
          <FontAwesomeIcon className="icone" icon={faQuestionCircle} />
        </a>
      </div>

      <div className="container-principal">
        <Row>
          <div className="container-principal" style={{ width: "100%" }}>
            <Formik
              enableReinitialize
              initialValues={contaReceber}
              onSubmit={handleSubmit}
              validationSchema={schema}
              validateOnChange={false}
              validateOnBlur={false}
            >
              {({ errors }) => (
                <Form>
                  {!geracaoMensalidade && (
                    <Row>
                      <div
                        className="col form-group"
                        style={{ marginTop: "1em", marginBottom: "1em" }}
                      >
                        <label className="obrigatorio">Pessoa*</label>
                        <FieldValidate
                          name="id_pessoa"
                          placeholderText="Nome do Pessoa"
                          component={SelectComponent}
                          options={pessoas}
                          errors={errors}
                          noClass
                        />
                      </div>
                      <div
                        className="col form-group"
                        style={{ marginTop: "1em", marginBottom: "1em" }}
                      >
                        <label className="obrigatorio">Profissional*</label>
                        <FieldValidate
                          name="id_profissional"
                          placeholderText="Nome do Profissional"
                          component={SelectComponent}
                          options={profissionais}
                          errors={errors}
                          noClass
                        />
                      </div>

                      <div
                        className="col form-group"
                        style={{ marginTop: "1em", marginBottom: "1em" }}
                      >
                        <label className="obrigatorio">Caixa*</label>
                        <FieldValidate
                          name="id_caixa"
                          placeholderText="Nome do caixa"
                          component={SelectComponent}
                          options={caixas}
                          errors={errors}
                          noClass
                        />
                      </div>
                    </Row>
                  )}

                  <Row>
                    <div
                        className="form-group col"
                        style={{ marginTop: "1em", marginBottom: "1em" }}
                      >
                        
                      <label className="obrigatorio"> Procedimento *</label>{" "}
                      <FieldValidate
                        name="procedimento"
                        placeholderText="Procedimento da consulta"
                        component={SelectComponent}
                        options={procedimentos}
                        errors={errors}
                        noClass
                      />
                    </div>
                    <div
                      className="form-group col"
                      style={{ marginTop: "1em", marginBottom: "1em" }}
                    >
                      <label className="obrigatorio">Descrição*</label>
                      <FieldValidate
                        name="descricao"
                        placeholder="Digite a descrição da baixa*"
                        type="text"
                        errors={errors}
                      />
                    </div>

                    <div
                      className="form-group col"
                      style={{ marginTop: "1em", marginBottom: "1em" }}
                    >
                      <label className="obrigatorio">Data de Geração*</label>
                      <br />
                      <DatePickerField
                        withPortal
                        placeholderText="Informe a data de geração*"
                        name="data_geracao"
                        className="form-control"
                      />
                      <ErrorMessage
                        name={"data_geracao"}
                        component="div"
                        style={{ fontSize: "16px", color: "red" }}
                      />
                    </div>

                    {geracaoMensalidade && (
                      <div
                        className="form-group col-3"
                        style={{ marginTop: "1em", marginBottom: "1em" }}
                      >
                        <label>Quantidade de mensalidades</label>
                        <FieldValidate
                          name="tot_mensalidades"
                          placeholder="Quantidade de Mensalidades"
                          type="number"
                          errors={errors}
                        />
                      </div>
                    )}
                  </Row>

                  <Row>
                    <div
                      className="form-group col"
                      style={{ marginTop: "1em", marginBottom: "1em" }}
                    >
                      <label className="obrigatorio">Valor*</label>
                      <FieldValidate
                        component={MoedaFormat}
                        name="valor"
                        placeholder="Digite o Valor"
                        errors={errors}
                      />
                    </div>

                    <div
                      className="form-group col"
                      style={{ marginTop: "1em", marginBottom: "1em" }}
                    >
                      <label className="obrigatorio">Data de Vencimento*</label>
                      <br />
                      <DatePickerField
                        withPortal
                        placeholderText="Informe a data de Vencimento*"
                        name="data_vencimento"
                        className="form-control"
                      />
                      <ErrorMessage
                        name={"data_vencimento"}
                        component="div"
                        style={{ fontSize: "16px", color: "red" }}
                      />
                    </div>
                  </Row>

                  <Row>
                    {!editForm && !geracaoMensalidade && (
                      <>
                        <div
                          className="form-group col"
                          style={{ marginTop: "1em", marginBottom: "1em" }}
                        >
                          <label>Parcelas</label>
                          <FieldValidate
                            name="parcelas_total"
                            placeholder="Digite a quantidade de Parcelas"
                            errors={errors}
                          />
                        </div>

                        <div
                          className="form-group col"
                          style={{ marginTop: "1em", marginBottom: "1em" }}
                        >
                          <label>Intervalo de Dias</label>
                          <FieldValidate
                            name="parcelas_intervalo"
                            placeholder="Digite o Intervalo de Dias"
                            errors={errors}
                          />
                        </div>
                      </>
                    )}
                  </Row>

                  <Row style={{ marginTop: "5px" }}>
                    <Col
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        AlignItems: "center",
                      }}
                    >
                      <input
                        name="baixar_duplicata"
                        type="checkbox"
                        onClick={(event) =>
                          setIsBaixaAutomatica(event.target.checked)
                        }
                        defaultChecked={isBaixaAutomatica}
                        style={{ marginRight: "0px" }}
                      />
                      <label style={{ color: "green", marginTop: "10px" }}>
                        {" "}
                        Baixar duplicata automáticamente (Pago)
                      </label>
                      <br />
                    </Col>
                    <Col
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        AlignItems: "center",
                      }}
                    >
                      <input
                        name="emited_nf"
                        type="checkbox"
                        onClick={(event) => setIsEmitirNf(event.target.checked)}
                        defaultChecked={isEmitirNf}
                        style={{ marginRight: "0px" }}
                      />
                      <label style={{ color: "green", marginTop: "10px" }}>
                        {" "}
                        Emitir nota fiscal?
                      </label>
                      <br />
                    </Col>
                  </Row>

                  {isBaixaAutomatica && (
                    <Row style={{ marginTop: "5px" }}>
                      <Col style={{ marginTop: "5px" }}>
                        <label className="obrigatorio">
                          Forma de Pagamento
                        </label>
                        <FieldValidate
                          name="pagamento_id_forma_pagamento"
                          placeholder="Forma de pagamento"
                          component={SelectComponent}
                          options={formasPagamento}
                          errors={errors}
                          noClass
                        />
                      </Col>
                    </Row>
                  )}

                  {isEmitirNf && (
                    <Row style={{ marginTop: "5px" }}>
                    <Col style={{ marginTop: "5px" }}>
                      <label className="obrigatorio">Informe um CPF para a nota fiscal!</label>
                      <FieldValidate
                        name="nf_cpf"
                        placeholder="CPF"
                        errors={errors}
                      />
                    </Col>
                  </Row>
                  )}
                  
                  <Row>
                    <Col>
                      <AlertGeral retorno={retorno} />
                      <Spinner loading={loading} />
                    </Col>
                  </Row>
                  {(contaReceber.status !== "P" && editForm) || !editForm ? (
                    <Row
                      style={{ marginTop: "40px" }}
                      className="form-group text-center"
                    >
                      <Col className="text-center">
                        <Button variant="salvar" type="submit">
                          <FontAwesomeIcon className="icone" icon="save" />{" "}
                          Salvar
                        </Button>
                      </Col>
                    </Row>
                  ) : (
                    <Alert variant="danger">
                      Conta já possui Pagamento, não será possivel editar.
                    </Alert>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </Row>
      </div>
    </>
  );
};

export default CadastroContasReceber;
