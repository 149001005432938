import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useImperativeHandle, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { requisitarAPI } from "../../components/AlertGeral";
import Spinner from "../../components/Spiner";
import api from "../../services/api";

const ModalArquivosDiagnosticos = React.forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedLabels, setSelectedLabels] = useState([]);
  let [indexGrid, setIndexGrid] = useState(0);

  const filesRemove = [];

  let fileData = [];
  if (props.origem && props.files) {
    for (const i in props.files) {
      const file = props.files[i];
      const urlFile = process.env.REACT_APP_AWS_S3_URL + "/" + file.nome;
      const descricao = file.descricao;
      const id = file.id;

      fileData.push({
        key: id,
        urlFile,
        descricao,
      });
    }
  }

  useImperativeHandle(ref, (id_evolucao) => ({
    uploadFile(id_evolucao) {
      uploadFile(id_evolucao);
    },
  }));

  const uploadFile = async (id_evolucao) => {
    if (selectedFiles.length === 0) {
      return false;
    }

    const formData = new FormData();

    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("files[]", selectedFiles[i]);
      formData.append("labels[]", selectedLabels[i]);
    }

    await requisitarAPI(
      () => api.post("evolucoes/" + id_evolucao + "/files", formData),
      "Falha ao cadastar arquivos"
    );
  };

  const onFileChange = (event) => {
    const indexAttribute = event.target
      .closest(".container-grid")
      .getAttribute("index");

    if (!selectedFiles[indexAttribute]) {
      selectedFiles.push(event.target.files[0]);
    } else {
      selectedFiles[indexAttribute] = event.target.files[0];
    }

    setSelectedFiles(selectedFiles);
  };

  const onTextChange = (event) => {
    const indexAttribute = event.target
      .closest(".container-grid")
      .getAttribute("index");

    if (event.target.value) {
      if (!selectedLabels[indexAttribute]) {
        selectedLabels.push(event.target.value);
      } else {
        selectedLabels[indexAttribute] = event.target.value;
      }

      setSelectedLabels(selectedLabels);
    }
  };

  const handleModal = async () => {
    const gridParent = document.querySelector("#container-grid-parent");
    const files = gridParent.querySelectorAll("input[type=file]");
    const descriptions = gridParent.querySelectorAll("input[type=text]");

    const errorsMessageFile = gridParent.querySelectorAll(".error-input-file");
    const errorsMessageText = gridParent.querySelectorAll(".error-input-text");

    errorsMessageFile.forEach((field) => {
      field.classList.remove("show");
    });

    errorsMessageText.forEach((field) => {
      field.classList.remove("show");
    });

    let error = false;

    files.forEach((file) => {
      if (!file.value) {
        file.nextElementSibling.classList.add("show");
        error = true;
      }
    });

    descriptions.forEach((description) => {
      if (!description.value) {
        description.nextElementSibling.classList.add("show");
        error = true;
      }
    });

    if (!props.id_evolucao) {
      if (error) {
        return false;
      }

      props.setShow(false);

      return false;
    }

    setLoading(true);

    const formData = new FormData();

    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("files[]", selectedFiles[i]);
      formData.append("labels[]", selectedLabels[i]);
    }

    let ret = "";

    if (selectedFiles.length > 0 && selectedLabels.length > 0) {
      ret = await requisitarAPI(
        () => api.post("evolucoes/" + props.id_evolucao + "/files", formData),
        "Falha ao cadastar arquivos"
      );

      if (!ret.erro) {
        props.setShow(false);
      }
    }

    if (filesRemove.length > 0) {
      ret = await requisitarAPI(
        () =>
          api.post(
            "evolucoes/" + props.id_evolucao + "/files/remove",
            filesRemove
          ),
        "Falha ao remover arquivos"
      );

      if (!ret.erro) {
        props.setShow(false);
      }
    }

    if (!ret.erro) {
      setSelectedFiles([]);
      setSelectedLabels([]);

      props.setShow(false);
    }

    setLoading(false);
  };

  const addGrid = () => {
    const constainerGridParent = document.querySelector(
      "#container-grid-parent"
    );

    const file =
      constainerGridParent.lastChild.querySelector("input[type=file]").value;
    const description =
      constainerGridParent.lastChild.querySelector("input[type=text]").value;

    constainerGridParent.lastChild
      .querySelector(".error-input-file")
      .classList.remove("show");
    constainerGridParent.lastChild
      .querySelector(".error-input-text")
      .classList.remove("show");

    let error = false;

    if (!file) {
      constainerGridParent.lastChild
        .querySelector(".error-input-file")
        .classList.add("show");
      error = true;
    }

    if (!description) {
      constainerGridParent.lastChild
        .querySelector(".error-input-text")
        .classList.add("show");
      error = true;
    }

    if (error) {
      return false;
    }

    indexGrid++;

    setIndexGrid(indexGrid);

    const node = constainerGridParent.lastChild;
    const clone = node.cloneNode(true);
    constainerGridParent.appendChild(clone);

    constainerGridParent.lastChild.querySelector("input[type=text]").value = "";
    constainerGridParent.lastChild.querySelector("input[type=file]").value = "";
    constainerGridParent
      .querySelector(".container-grid:last-child")
      .setAttribute("index", indexGrid);

    const btnAdd =
      constainerGridParent.lastChild.querySelector(".btn-grid-plus");
    const btnRemove =
      constainerGridParent.lastChild.querySelector(".btn-grid-remove");
    const inputFile =
      constainerGridParent.lastChild.querySelector("input[type=file]");
    const inputText =
      constainerGridParent.lastChild.querySelector("input[type=text]");

    btnAdd.addEventListener("click", function () {
      addGrid();
    });

    btnRemove.addEventListener("click", function () {
      removeGrid(btnRemove);
    });

    inputFile.addEventListener("change", function (e) {
      onFileChange(e);
    });

    inputText.addEventListener("blur", function (e) {
      onTextChange(e);
    });
  };

  const removeGrid = (elem) => {
    const total = document.querySelectorAll(".container-grid").length;

    if (total > 1) {
      const indexAttribute = elem
        .closest(".container-grid")
        .getAttribute("index");

      selectedLabels.splice(indexAttribute, 1);
      setSelectedLabels(selectedLabels);

      selectedFiles.splice(indexAttribute, 1);
      setSelectedFiles(selectedFiles);

      elem.closest(".container-grid").remove();
    } else {
      selectedLabels.splice(1, 1);
      setSelectedLabels(selectedLabels);

      selectedFiles.splice(1, 1);
      setSelectedFiles(selectedFiles);
    }
  };

  const removeFile = (elem) => {
    const idAttribute = elem.closest(".container-files").getAttribute("id");

    filesRemove.push(idAttribute);

    elem.closest(".container-files").remove();
  };

  const hideModal = () => {
    props.setShow(false);
  };

  return (
    <Modal
      forwardedref={ref}
      size="lg"
      show={props.show}
      onHide={() => hideModal()}
      animation={true}
    >
      <Col>
        <Spinner loading={loading} />
      </Col>

      <Modal.Header closeButton>
        <Modal.Title> Upload </Modal.Title>
      </Modal.Header>
      <Modal.Body id="container-grid-parent">
        <Row className="form-group container-grid" index="0">
          <Col>
            <label className="obrigatorio">Arquivo*</label>
            <input
              type="file"
              onChange={onFileChange}
              className="form-control"
            />
            <div className="error-input error-input-file">
              Informe o arquivo
            </div>
          </Col>
          <Col>
            <label className="obrigatorio">Descrição*</label>
            <input type="text" onBlur={onTextChange} className="form-control" />
            <div className="error-input error-input-text">
              Informe a descrição
            </div>
          </Col>
          <Col id="btn-minus">
            <Button
              variant="danger btn-grid-remove"
              type="button"
              onClick={(event) => {
                removeGrid(event.target);
              }}
            >
              <FontAwesomeIcon icon={faMinusCircle} />
            </Button>
          </Col>
          <Col id="btn-plus">
            <Button
              variant="success btn-grid-plus"
              type="button"
              onClick={(event) => {
                addGrid(event.target);
              }}
            >
              <FontAwesomeIcon icon={faPlusCircle} />
            </Button>
          </Col>
        </Row>
        {fileData.map((obj) => (
          <Row
            className="form-group container-files"
            id={obj.key}
            key={obj.key}
          >
            <Col>
              <img src={obj.urlFile} />
            </Col>
            <Col>
              <label>{obj.descricao}</label>
            </Col>
            <Col id="btn-minus">
              <Button
                variant="danger btn-grid-remove"
                type="button"
                onClick={(event) => {
                  removeFile(event.target);
                }}
              >
                <FontAwesomeIcon icon={faMinusCircle} />
              </Button>
            </Col>
          </Row>
        ))}
      </Modal.Body>

      <Modal.Footer>
        <Col style={{ display: "flex" }}>
          <Button
            variant="salvar"
            onClick={handleModal}
            style={{ width: "100%" }}
            type="button"
          >
            Salvar
          </Button>
        </Col>
      </Modal.Footer>
    </Modal>
  );
});

export default ModalArquivosDiagnosticos;
