import { situacaoListaTags } from "./bodysEspecificos";

export const colunasListaTagsConsultorio = [
  {
    field: "icone",
    header: "Icone",
    body: situacaoListaTags,
    style: { width: "120px", textAlign: "Center" },
    sortable: true,
  },
  {
    field: "description",
    header: "Descrição",
    style: { textAlign: "Center" },
    sortable: true,
  },
];
