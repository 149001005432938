import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronLeft, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { AlertGeral, requisitarAPI } from "../../components/AlertGeral";
import FieldValidate from "../../components/Form/FieldValidate.js";
import { SelectComponent } from "../../components/Form/Select.js";
import MainMenu from "../../components/MainMenu";
import "../../Global/styleGeral.css";
import api from "../../services/api";
import { Creators as ListaProcedimentos } from "../../Store/Controls/listaProcedimentos";
// import base64 from "base64-img";

library.add(faSave, faChevronLeft);

const CadastroListaProcedimentosConsultorio = ({ history, match }) => {
  const [emojis, setEmojis] = useState([{}]);
  const [listaTags, setListaTags] = useState({ commission: 0, description: "", icone: "" });

  const [editForm, setEditForm] = useState(false);
  const [captionForm, setCaptionForm] = useState("Cadastro - Lista de Procedimentos");
  const [retorno, setRetorno] = useState({});
  const [loading, setLoading] = useState(true);
  const [situacao, setSituacao] = useState("");

  const listasProcedimentos = useSelector((state) => state.listaProcedimentos);
  const dispatch = useDispatch();

  useState(() => {
    setEmojis([
      {
        value: "❤️",
        label: "❤️",
      },
      {
        value: "✝️",
        label: "✝️",
      },
      {
        value: "☪️",
        label: "☪️",
      },
      {
        value: "☢️",
        label: "☢️",
      },
      {
        value: "☣️",
        label: "☣️",
      },
      {
        value: "🅰️",
        label: "🅰️",
      },
      {
        value: "🅱️",
        label: "🅱️",
      },
      {
        value: "🅾️",
        label: "🅾️",
      },
      {
        value: "🆎",
        label: "🆎",
      },
      {
        value: "🆘",
        label: "🆘",
      },
      {
        value: "❌",
        label: "❌",
      },
      {
        value: "⭕️",
        label: "⭕️",
      },
      {
        value: "🔰",
        label: "🔰",
      },
      {
        value: "✅",
        label: "✅",
      },
      {
        value: "🚺",
        label: "🚺",
      },
      {
        value: "🚼",
        label: "🚼",
      },
      {
        value: "🚹",
        label: "🚹",
      },
      {
        value: "⚧",
        label: "⚧",
      },
      {
        value: "🔄",
        label: "🔄",
      },
      {
        value: "↩️",
        label: "↩️",
      },
      {
        value: "↪️",
        label: "↪️",
      },
      {
        value: "🕓",
        label: "🕓",
      },
      {
        value: "😃",
        label: "😃",
      },
      {
        value: "🤧",
        label: "🤧",
      },
      {
        value: "😷",
        label: "😷",
      },
      {
        value: "🤒",
        label: "🤒",
      },
      {
        value: "🤕",
        label: "🤕",
      },
      {
        value: "👶",
        label: "👶",
      },
      {
        value: "👧",
        label: "👧",
      },
      {
        value: "👦",
        label: "👦",
      },
      {
        value: "👨",
        label: "👨",
      },
      {
        value: "👩",
        label: "👩",
      },
      {
        value: "👴",
        label: "👴",
      },
      {
        value: "👵",
        label: "👵",
      },
      {
        value: "👨‍⚕️",
        label: "👨‍⚕️",
      },
      {
        value: "👩‍⚕️",
        label: "👩‍⚕️",
      },
      {
        value: "🤰",
        label: "🤰",
      },
      {
        value: "🤱",
        label: "🤱",
      },
    ]);
  }, []);

  const onLoad = () => {
    // undefined é tipo de dado, não é uma string
    if (typeof match.params.id !== "undefined") {
      setCaptionForm("Edição da tag");
      setEditForm(true);
      let lista = listasProcedimentos.find((item) => item.id == match.params.id);

      
      if (typeof lista === "undefined") {
        history.push("/listaProcedimentos/" + match.params.tipo);
        return;
      }

      setListaTags(lista);
      setSituacao(lista.situacao);
    }

    setLoading(false);
  };

  // edição de dados
  useEffect(() => {
    onLoad();
  }, [match.params.id]);

  // validação do formulário
  const schema = Yup.object().shape({
    description: Yup.string().required("Informe o Nome"),
    icone: Yup.string().required("Informe o Ícone"),
    commission: Yup.string().required("Informe a comissão")
  });

  // função para salvar ou editar
  const handleSubmit = async (values) => {
    setLoading(true);
    values.tipo = match.params.tipo;
    values.situacao = situacao;
    let ret = {};
    // se for edição, envia o id para alteração
    if (editForm) {
      ret = await requisitarAPI(
        () => api.put("listaProcedimentos/" + match.params.id, values),
        "Falha ao editar Lista de Tags"
      );
      setRetorno(ret);

      if (!ret.erro) {
        dispatch(
          ListaProcedimentos.editProcedimento(match.params.id, ret.response.data)
        );
      }
    } else {
      ret = await requisitarAPI(
        () => api.post("listaProcedimentos/", values),
        "Falha ao cadastar lista de procedimentos"
      );
      setRetorno(ret);

      if (!ret.erro) {
        dispatch(ListaProcedimentos.addProcedimento(ret.response.data));
      }
    }

    setLoading(false);

    if (!ret.erro) {
      history.push("/listaProcedimentos/" + values.tipo);
    }
  };

  return (
    <>
      <MainMenu navigation={history} />

      {/* comentar essa parte */}
      <div className="titulo-pagina">
        {" "}
        {captionForm}
        <Button
          variant="voltar"
          onClick={() => {
            history.push(`/listaProcedimentos/${match.params.tipo}`);
          }}
        >
          <FontAwesomeIcon className="icone" icon="chevron-left" /> Voltar para
          Lista de Procedimentos
        </Button>
      </div>

      <div className="container-principal">
        <AlertGeral retorno={retorno} />
        <br />
        <Formik
          onSubmit={handleSubmit}
          enableReinitialize
          initialValues={listaTags}
          validationSchema={schema}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ errors }) => (
            <Form>
              <br />
              <br />
              <Row>
                <Col>
                  <label className="obrigatorio">Icone*</label>
                  <FieldValidate
                    name="icone"
                    placeholderText="Informe o Ícone"
                    component={SelectComponent}
                    options={emojis}
                    errors={errors}
                    noClass
                  />
                </Col>
              </Row>
              <br />
              <br />
              <Row>
                <Col>
                  <label className="obrigatorio">Descrição*</label>
                  <FieldValidate
                    name="description"
                    placeholder="Digite o description"
                    className="form-control"
                    errors={errors}
                  />
                </Col>
              </Row>
              <br />
              <br />
              <Row>
                <Col>
                  <label className="obrigatorio">Comissão (%)*</label>
                  <FieldValidate
                    name="commission"
                    placeholder="Digite a comissão"
                    className="form-control"
                    errors={errors}
                  />
                </Col>
              </Row>
              <br />
              <br />

              <Row
                style={{ marginTop: "20px" }}
                className="form-group text-center"
              >
                <Col className="text-center">
                  <Button variant="salvar" type="submit">
                    <FontAwesomeIcon className="icone" icon="save" /> Salvar
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default CadastroListaProcedimentosConsultorio;
