import React, { useEffect, useState } from "react";
import { Button, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { AlertGeral, requisitarAPI } from "../../components/AlertGeral";
import Listagem from "../../components/Listagens";
import { colunasFornecedor } from "../../components/Listagens/colunas";
import MainMenu from "../../components/MainMenu";
import Spinner from "../../components/Spiner";
import "../../Global/styleGeral.css";
import api from "../../services/api";
import { Creators as FornecedorActions } from "../../Store/Controls/fornecedores/fornecedores";

import { faPlus, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Fornecedores = ({ history }) => {
  const [retorno, setRetorno] = useState({});
  const [loading, setLoading] = useState(true);

  const fornecedores = useSelector((state) => state.fornecedores);
  const dispatch = useDispatch();

  async function getDados() {
    let ret = await requisitarAPI(
      () =>
        api.get("fornecedores"),
      "Falha ao obter dados do servidor"
    );
    setRetorno(ret);

    if (!ret.erro) {
      dispatch(FornecedorActions.addFornecedores(ret.response.data));
    }

    setLoading(false);
  }

  useEffect(() => {
    getDados();
  }, []);

  return (
    <>
      <MainMenu navigation={history} />

      <div className="titulo-pagina">
        Fornecedores
        <Button
          variant="principal"
          onClick={() => {
            history.push("/fornecedores/cadastro");
          }}
          alt="Adicione um novo fornecedor"
          title="Adicione um novo fornecedor"
        >
          {" "}
          <FontAwesomeIcon className="icone" icon={faPlus} /> Novo Fornecedor
        </Button>
    
        <AlertGeral retorno={retorno} />
        <Spinner loading={loading} />
      </div>

      <div className="container-principal">
        <Row>
          <Listagem
            dados={fornecedores}
            cols={colunasFornecedor}
            url_edit="fornecedores/editar"
            tipo_delete="fornecedores"
            history={history}
          />
        </Row>
      </div>
    </>
  );
};

export default Fornecedores;
